import { Auth } from 'aws-amplify';

class AuthService {
  async signIn(username, password){
    return await Auth.signIn(username, password)
      .then(user => {
        // console.log(user);
        return user;
      })
      .catch(err => console.log(err));
  }

  async signOut(){
    return await Auth.signOut()
      .then(data => {return data;})
      .catch(err => console.log(err));
  }

  async isLogin(){
    return await Auth.currentSession()
      .then((session) => {
        // console.log(session);
        return true;
      })
      .catch((err) => {
        console.log(err);
        return false;
      });
  }

  async forgotPassword(username){
    return await Auth.forgotPassword(username)
      .then((data) => {
        console.log(data);
        return data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  async forgotPasswordSubmit(username, code, new_password){
    return await Auth.forgotPasswordSubmit(username, code, new_password)
      .then(() => {
        return "succeed";
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  // async updateUserAttributes(attribute){
  //   return await Auth.currentAuthenticatedUser()
  //     .then(user => {
  //       return Auth.updateUserAttributes(user, attribute)
  //         .then((data) => {
  //           console.log(data);
  //           return data;
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //           return err;
  //         });
  //     })
  //     .catch(err => console.log(err));
  // }

  async updateNickname(nickname){
    return await Auth.currentAuthenticatedUser()
      .then(user => {
        return Auth.updateUserAttributes(user, {nickname: nickname})
          .then((data) => {
            console.log(data);
            return data;
          })
          .catch((err) => {
            console.log(err);
            return err;
          });
      })
      .catch(err => console.log(err));
  }

  async updateEmail(email){
    return await Auth.currentAuthenticatedUser()
      .then(user => {
        return Auth.updateUserAttributes(user, {email: email})
          .then((data) => {
            console.log(data);
            return data;
          })
          .catch((err) => {
            console.log(err);
            return err;
          });
      })
      .catch(err => console.log(err));
  }

  async resentVerifyEmail(){
    return await Auth.currentAuthenticatedUser()
      .then(user => {
        return Auth.verifyUserAttribute(user, 'email')
          .then((data) => {
            console.log(data);
            return data;
          })
          .catch((err) => {
            console.log(err);
            return err;
          });
      })
      .catch(err => console.log(err));
  }

  async verifyEmail(code){
    return await Auth.currentAuthenticatedUser()
      .then(user => {
        return Auth.verifyUserAttributeSubmit(user, 'email' , code)
          .then((data) => {
            console.log(data);
            return data;
          })
          .catch((err) => {
            console.log(err);
            return err;
          });
      })
      .catch(err => console.log(err));
  }

  async changePassword(oldPassword, newPassword){
    return await Auth.currentAuthenticatedUser()
      .then(user => {
        return Auth.changePassword(user, oldPassword, newPassword)
          .then(succsess => {
            console.log(succsess)
            return true
          })
          .catch(err => {
            console.log(err)
            return err
          });
      })
      .catch(err => console.log(err));
  }

  async completeNewPassword(user, password, attribute){
    console.log(user)
    return Auth.completeNewPassword(user, password, attribute)
      .then(user => {
        // console.log(user);
        return user;
      })
      .catch(err => {
        console.log(err);
        return err;
      });
  }

  async getNickname(){
    return await Auth.currentAuthenticatedUser()
      .then(user => {
        return Auth.userAttributes(user)
          .then(attributes => {
            let nickname = "unknown"
            attributes.forEach((item) => {
              if(item.Name === "nickname"){
                nickname = item.Value
              }
            })
            return nickname;
          })
          .catch(err => console.log(err));
      })
      .catch(err => console.log(err));

  }

  async getEmail(){
    return await Auth.currentAuthenticatedUser()
      .then(user => {
        return Auth.userAttributes(user)
          .then(attributes => {
            let email = "unknown"
            attributes.forEach((item) => {
              if(item.Name === "email"){
                email = item.Value
              }
            })
            return email;
          })
          .catch(err => console.log(err));
      })
      .catch(err => console.log(err));

  }

  async getCurrentCredentials(){
    return await Auth.currentCredentials()
      .then(credentials => {
        return credentials;
      })
      .catch(err => {
        console.log(err);
        return err;
      });
  }
}

export default AuthService
