import React, { useState, useEffect, useRef, Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


import { useSnackbar } from 'notistack';
import { API } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';
import Receipt from './componentForPrint/Receipt';

import { style } from '../services/Settings'

const useStyles = style

export default function ReceiptManage(props) {

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [storedActiveReservations, setStoredActiveReservations] = useState([]);
  const [storedTenantInfo, setStoredTenantInfo] = useState(null);
  const [storedReservationsToPrint, setStoredReservationsToPrint] = useState([]);
  const [isPrintAll, setIsPrintAll] = useState(false);


  const GUEST_TYPE = {
    adult: "大人",
    child: "子供",
    toddler: "幼児",
  }

  const BED = {
    true: 'ベッド有',
    false: 'ベッド無'
  }

  const DINNER = {
    true: '夕食有',
    false: '夕食無'
  }

  const BREAKFAST = {
    true: '朝食有',
    false: '朝食無'
  }


  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: async () => {
      // レシートナンバーを送信

      await Promise.all(storedReservationsToPrint.map( async reservation => {
        let apiName = 'MyAPIGatewayAPI'
        let path = '/reservations/' + reservation.reservationId

        let myInit = {
          body: {
            receiptSnapshot: reservation
          }
        }

        await API.put(apiName, path, myInit).then(response => {
          console.log(response);

        }).catch(error => {
          console.log(error);
          enqueueSnackbar('エラーが発生しました。', {variant : 'error'})
        })

        return null
      }))

      //領収書ナンバーを更新
      let storedActiveReservationsAfter = JSON.parse(JSON.stringify(storedActiveReservations)).map(reservation => {
        reservation.storedFormNumber = uuidv4()
        return reservation
      })

      setStoredActiveReservations(storedActiveReservationsAfter)

    }
  });



  // テナント情報の取得
  useEffect(() => {
    let unmounted = false;

    if(!unmounted) {
      // ページのトップにフォーカス
      window.scrollTo(0, 0)

      // テナント情報の取得
      let apiName = 'MyAPIGatewayAPI'
      let path = '/tenant'

      API.get(apiName, path).then(response => {
        console.log(response);

        setStoredTenantInfo(response.tenant)

      }).catch(error => {
        console.log(error);
        enqueueSnackbar('エラーが発生しました。', {variant : 'error'})
      })
    }

    //クリーンアップ関数を返す
    return ()=>{ unmounted = true; };

  },[enqueueSnackbar])


  // テナント情報と予約情報の取得
  useEffect(() => {
    let unmounted = false;

    if(!unmounted) {
      // チェックイン対象の予約の取得
      let apiName = 'MyAPIGatewayAPI'
      let path = '/reservations'

      let myInit = {
        queryStringParameters: {
            status: 'active_checked_in',
        },
      };

      API.get(apiName, path, myInit).then(response => {
        console.log(response);

        let activeReservations = JSON.parse(JSON.stringify(response.reservations))

        activeReservations.sort((a,b) => {
          if(a.lastNameKana > b.lastNameKana) {
            return 1
          }else {
            return -1
          }
        })

        let activeReservationsToSet = activeReservations.map(reservation => {
          let reservationAfter = JSON.parse(JSON.stringify(reservation))
          reservationAfter.inputIsToPrint = false
          reservationAfter.inputMethodOfPayment = 'cash'
          reservationAfter.inputFormType = (storedTenantInfo && storedTenantInfo.defaultFormType) ? storedTenantInfo.defaultFormType : 'receipt'
          reservationAfter.inputReceiptAddress = reservationAfter.lastName + ' ' + reservationAfter.firstName + ' 様'
          reservationAfter.inputHonorificTitle = 'no'
          reservationAfter.inputReceiptNote = ''
          reservationAfter.inputIssueDate = getDateRange(reservation.overnights).end
          reservationAfter.storedFormNumber = uuidv4()

          return reservationAfter
        })

        setStoredActiveReservations(activeReservationsToSet)

      }).catch(error => {
        console.log(error);
        enqueueSnackbar('エラーが発生しました。', {variant : 'error'})
      })
    }

    //クリーンアップ関数を返す
    return ()=>{ unmounted = true; };


  },[storedTenantInfo, enqueueSnackbar])



  useEffect(() => {
    let unmounted = false;

    //アンマウントされていなければステートを更新
    if(!unmounted) {
      let isPrintAllAfter = true

      storedActiveReservations.map(reservation => {
        if(!reservation.inputIsToPrint){
          isPrintAllAfter = false
        }
        return null
      })

      setIsPrintAll(isPrintAllAfter)


      let storedReservationsToPrintAfter = []

      storedActiveReservations.map(reservation => {
        if(reservation.inputIsToPrint){
          storedReservationsToPrintAfter.push(reservation)
        }
        return false
      })

      setStoredReservationsToPrint(storedReservationsToPrintAfter)
    };

    //クリーンアップ関数を返す
    return ()=>{ unmounted = true; };
  },[storedActiveReservations]);



  const handleIsPrintAll = (isPrintAllValue) => {
    if(isPrintAllValue){

      let storedActiveReservationsAfter = storedActiveReservations.map(reservation => {
        reservation.inputIsToPrint = true
        return reservation
      })

      setStoredActiveReservations(storedActiveReservationsAfter)

    }else{

      let storedActiveReservationsAfter = storedActiveReservations.map(reservation => {
        reservation.inputIsToPrint = false
        return reservation
      })

      setStoredActiveReservations(storedActiveReservationsAfter)

    }
  }


  const handleChangeStoredActiveReservations = (reservation, key, value) => {
    let index = 0
    let storedActiveReservationsAfter = JSON.parse(JSON.stringify(storedActiveReservations))
    storedActiveReservations.map((storedActiveReservation, i) =>{
      if(reservation.reservationId === storedActiveReservation.reservationId){
        index = i
      }
      return null
    })

    storedActiveReservationsAfter[index][key] = value

    setStoredActiveReservations(storedActiveReservationsAfter)
  }



  const handlePrintBefore = () => {

    if(storedReservationsToPrint.length > 0){
      handlePrint()
    }else{
      enqueueSnackbar('印刷する領収書が選択されていません', {variant : 'warning'})
    }
  }



  const getDateRange = (overnights) => {

    let dates = overnights.map(overnight => {
      return overnight.date
    })

    dates.sort((a, b) => moment(a).diff(moment(b)));

    return {
      start: moment(dates[0]).format('YYYY-MM-DD'),
      end: moment(dates[dates.length -1]).add(1, 'days').format('YYYY-MM-DD')
    }
  }



  const getReservationTotal = (reservation) => {
    let plansTotal = 0

    if(reservation.methodOfPaymentPlans === 'jalan'){
      return plansTotal
    }

    reservation.roomTypePlans.map(roomTypePlan => {
      roomTypePlan.storedPlans.map(storedPlan => {
        plansTotal += storedPlan.amount * storedPlan.unitPrice
        return null
      })
      plansTotal = plansTotal * Number(roomTypePlan.dateNum)
      return null
    })


    if(
      reservation.charges &&
      reservation.charges.additionalCharges &&
      reservation.charges.additionalCharges.additionalCharge &&
      reservation.charges.additionalCharges.additionalCharge.price !== 0
    ){
      plansTotal += reservation.charges.additionalCharges.additionalCharge.price
    }

    if(
      reservation.charges &&
      reservation.charges.discounts &&
      reservation.charges.discounts.discount &&
      reservation.charges.discounts.discount.price !== 0
    ){
      plansTotal -= reservation.charges.discounts.discount.price
    }

    if(
      reservation.charges &&
      reservation.charges.discounts &&
      reservation.charges.discounts.jalanPoint &&
      reservation.charges.discounts.jalanPoint.price !== 0
    ){
      plansTotal -= reservation.charges.discounts.jalanPoint.price
    }

    if(
      reservation.charges &&
      reservation.charges.discounts &&
      reservation.charges.discounts.jalanCoupon &&
      reservation.charges.discounts.jalanCoupon.price !== 0
    ){
      plansTotal -= reservation.charges.discounts.jalanCoupon.price
    }

    if(
      reservation.charges &&
      reservation.charges.discounts &&
      reservation.charges.discounts.jalanSubsidy &&
      reservation.charges.discounts.jalanSubsidy.price !== 0
    ){
      plansTotal -= reservation.charges.discounts.jalanSubsidy.price
    }

    return plansTotal
  }




  const getReservationTotalJalanPaid = (reservation) => {

    let reservationAfter = JSON.parse(JSON.stringify(reservation))

    reservationAfter.methodOfPaymentPlans = ''

    let plansTotalJalanPaid = getReservationTotal(reservationAfter)

    return plansTotalJalanPaid
  }


  const getReservationTotalInnerTax = (reservation) => {
    if(reservation.methodOfPaymentPlans === 'jalan'){
      return 0
    }

    let taxRate = reservation.charges.taxRate

    return Math.floor(getReservationTotal(reservation) * taxRate / (1 + taxRate))
  }


  const getOrdersTotal = (reservation) => {
    let ordersTotal = 0

    reservation.orders.map(order => {
      ordersTotal += order.unitPrice * order.amount
      return null
    })

    return ordersTotal
  }



  const getOrdersTotalInnerTax = (reservation) => {
    let ordersTotalInnerTax = 0

    let taxRates = []

    reservation.orders.map(order => {
      taxRates.push(order.taxRate)
      return null
    })

    taxRates = Array.from(new Set(taxRates))

    taxRates.map(taxRate => {
      let totalThisRate = 0
      reservation.orders.map(order => {
        if(taxRate === order.taxRate){
          totalThisRate += order.unitPrice * order.amount
        }
        return null
      })
      ordersTotalInnerTax += Math.floor(totalThisRate * taxRate / (1 + taxRate))
      return null
    })

    return ordersTotalInnerTax
  }



  const getIsChargesExist = (reservation) => {

    if(
      reservation.charges &&
      reservation.charges.additionalCharges &&
      reservation.charges.additionalCharges.additionalCharge &&
      reservation.charges.additionalCharges.additionalCharge.price !== 0
    ){
      return true
    }

    if(
      reservation.charges &&
      reservation.charges.discounts &&
      reservation.charges.discounts.discount &&
      reservation.charges.discounts.discount.price !== 0
    ){
      return true
    }

    if(
      reservation.charges &&
      reservation.charges.discounts &&
      reservation.charges.discounts.jalanPoint &&
      reservation.charges.discounts.jalanPoint.price !== 0
    ){
      return true
    }

    if(
      reservation.charges &&
      reservation.charges.discounts &&
      reservation.charges.discounts.jalanCoupon &&
      reservation.charges.discounts.jalanCoupon.price !== 0
    ){
      return true
    }

    if(
      reservation.charges &&
      reservation.charges.discounts &&
      reservation.charges.discounts.jalanSubsidy &&
      reservation.charges.discounts.jalanSubsidy.price !== 0
    ){
      return true
    }

    return false
  }





  return (
    <Fragment>
      <Grid container spacing={2} className={classes.container}>
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom>
            領収書/ご利用明細発行
          </Typography>
        </Grid>

        <Grid item xs={12}　style={{display: storedActiveReservations.length > 0 ? 'none' : ''}}>
          <Typography
            variant="h5"
            gutterBottom
            style={{marginTop: 20, marginLeft: 30}}
          >
            予約がありません
          </Typography>
        </Grid>

        <Grid item xs={12}　style={{display: storedActiveReservations.length > 0 ? '' : 'none'}}>

          <Grid container spacing={2} className={classes.paper} style={{paddingLeft: 0}}>

            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isPrintAll}
                    onChange={(e) => handleIsPrintAll(e.target.checked)}
                    name="isPrintAll"
                    color="primary"
                  />
                }
                label="すべてを選択"
              />
            </Grid>

            <Grid item xs={6}/>

            <Grid item xs={3}>
              <Button onClick={handlePrintBefore} variant="contained" color="primary" className={classes.button}>
                選択した帳票を発行
              </Button>
            </Grid>

          </Grid>

          {storedActiveReservations.map((reservation, i) => {
            return (
              <Accordion className={classes.acordion} key={reservation.reservationId}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                >
                  <Grid container spacing={2} className={classes.container}>

                    <Grid item xs={2}>
                      <FormControlLabel
                        onClick={(event) => event.stopPropagation()}
                        onFocus={(event) => event.stopPropagation()}
                        control={
                          <Checkbox
                            checked={reservation.inputIsToPrint}
                            onChange={(e) => handleChangeStoredActiveReservations(reservation, 'inputIsToPrint', e.target.checked)}
                            name="isJalanPayed"
                            color="primary"
                          />
                        }
                        label="印刷"
                      />
                    </Grid>

                    <Grid item xs={4} style={{marginTop: 4}}>
                      <Typography variant="h5" gutterBottom>
                        {reservation.lastName} {reservation.firstName} 様
                      </Typography>
                    </Grid>

                    <Grid item xs={6} style={{marginTop: 4}}>
                      <Grid container spacing={0} className={classes.container}>
                        <Grid item xs={5}>
                          <Typography variant="body2">
                            チェックイン
                          </Typography>
                          <Typography variant="body1" style={{marginLeft:5}}>
                            {moment(getDateRange(reservation.overnights).start).format('YYYY年M月D日')}
                          </Typography>
                        </Grid>

                        <Grid item xs={5}>
                          <Typography variant="body2">
                            チェックアウト
                          </Typography>
                          <Typography variant="body1" style={{marginLeft:5}}>
                            {moment(getDateRange(reservation.overnights).end).format('YYYY年M月D日')}
                          </Typography>
                        </Grid>

                        <Grid item xs={2}/>

                      </Grid>
                    </Grid>
                  </Grid>
                </AccordionSummary>

                <AccordionDetails>
                  <Grid container spacing={2} className={classes.container}>
                    <Table size='small' className={classes.lightBorderTable}>
                      <TableHead>
                        <TableRow className={classes.darkColorRow}>
                          <TableCell align="center">ご利用明細</TableCell>
                          <TableCell align="center" style={{width:'15%'}}>単価</TableCell>
                          <TableCell align="center" style={{width:'10%'}}>数量</TableCell>
                          <TableCell align="center" style={{width:'10%'}}>税率</TableCell>
                          <TableCell align="center" style={{width:'15%'}}>小計</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>

                        {reservation.roomTypePlans.map(roomTypePlan => {
                            return (
                              <Fragment key={roomTypePlan.storedRoomTypePlanId}>
                                {roomTypePlan.storedPlans.map(storedPlan => {
                                    return (
                                      <TableRow hover key={storedPlan.storedPlanId}>
                                        <TableCell align="left" style={{whiteSpace: 'normal', wordWrap: 'break-word'}}>
                                          {!storedPlan.planNameForGuest && <div>{roomTypePlan.roomTypeName} { GUEST_TYPE[storedPlan.guestType]} {BED[storedPlan.bed ? 'true' : 'false']} {DINNER[storedPlan.dinner ? 'true' : 'false']} {BREAKFAST[storedPlan.breakfast ? 'true' : 'false']} ご宿泊料</div>}
                                          {storedPlan.planNameForGuest && storedPlan.planNameForGuest + ' ご宿泊料'}
                                        </TableCell>
                                        <TableCell align="right">¥ {storedPlan.unitPrice.toLocaleString()}</TableCell>
                                        <TableCell align="right">{storedPlan.amount}</TableCell>
                                        <TableCell align="right">{storedPlan.taxRate * 100} %</TableCell>
                                        <TableCell align="right">{(storedPlan.unitPrice * storedPlan.amount).toLocaleString()}</TableCell>
                                      </TableRow>
                                    )
                                })}
                              </Fragment>
                            )
                        })}


                        {
                          getIsChargesExist(reservation) &&
                          (
                            <TableRow>
                              <TableCell align="center" colSpan={5}></TableCell>
                            </TableRow>
                          )
                        }


                        {
                          reservation.charges &&
                          reservation.charges.additionalCharges &&
                          reservation.charges.additionalCharges.additionalCharge &&
                          reservation.charges.additionalCharges.additionalCharge.price !== 0 &&
                          (
                            <TableRow hover>
                              <TableCell align="left" style={{whiteSpace: 'normal', wordWrap: 'break-word'}}>{reservation.charges.additionalCharges.additionalCharge.name}</TableCell>
                              <TableCell align="right"></TableCell>
                              <TableCell align="right"></TableCell>
                              <TableCell align="right"></TableCell>
                              <TableCell align="right">¥ {reservation.charges.additionalCharges.additionalCharge.price.toLocaleString()}</TableCell>
                            </TableRow>
                          )
                        }


                        {
                          reservation.charges &&
                          reservation.charges.discounts &&
                          reservation.charges.discounts.discount &&
                          reservation.charges.discounts.discount.price !== 0 &&
                          (
                            <TableRow hover>
                              <TableCell align="left" style={{whiteSpace: 'normal', wordWrap: 'break-word'}}>{reservation.charges.discounts.discount.name}</TableCell>
                              <TableCell align="right"></TableCell>
                              <TableCell align="right"></TableCell>
                              <TableCell align="right"></TableCell>
                              <TableCell align="right">- ¥ {reservation.charges.discounts.discount.price.toLocaleString()}</TableCell>
                            </TableRow>
                          )
                        }


                        {
                          reservation.charges &&
                          reservation.charges.discounts &&
                          reservation.charges.discounts.jalanPoint &&
                          reservation.charges.discounts.jalanPoint.price !== 0 &&
                          (
                            <TableRow hover>
                              <TableCell align="left" style={{whiteSpace: 'normal', wordWrap: 'break-word'}}>{reservation.charges.discounts.jalanPoint.name}</TableCell>
                              <TableCell align="right"></TableCell>
                              <TableCell align="right"></TableCell>
                              <TableCell align="right"></TableCell>
                              <TableCell align="right">- ¥ {reservation.charges.discounts.jalanPoint.price.toLocaleString()}</TableCell>
                            </TableRow>
                          )
                        }

                        {
                          reservation.charges &&
                          reservation.charges.discounts &&
                          reservation.charges.discounts.jalanCoupon &&
                          reservation.charges.discounts.jalanCoupon.price !== 0 &&
                          (
                            <TableRow hover>
                              <TableCell align="left" style={{whiteSpace: 'normal', wordWrap: 'break-word'}}>{reservation.charges.discounts.jalanCoupon.name}</TableCell>
                              <TableCell align="right"></TableCell>
                              <TableCell align="right"></TableCell>
                              <TableCell align="right"></TableCell>
                              <TableCell align="right">- ¥ {reservation.charges.discounts.jalanCoupon.price.toLocaleString()}</TableCell>
                            </TableRow>
                          )
                        }

                        {
                          reservation.charges &&
                          reservation.charges.discounts &&
                          reservation.charges.discounts.jalanSubsidy &&
                          reservation.charges.discounts.jalanSubsidy.price !== 0 &&
                          (
                            <TableRow hover>
                              <TableCell align="left" style={{whiteSpace: 'normal', wordWrap: 'break-word'}}>{reservation.charges.discounts.jalanSubsidy.name}</TableCell>
                              <TableCell align="right"></TableCell>
                              <TableCell align="right"></TableCell>
                              <TableCell align="right"></TableCell>
                              <TableCell align="right">- ¥ {reservation.charges.discounts.jalanSubsidy.price.toLocaleString()}</TableCell>
                            </TableRow>
                          )
                        }


                        {
                          reservation.methodOfPaymentPlans === 'jalan' &&
                          (
                            <Fragment>
                              <TableRow>
                                <TableCell align="center" colSpan={5}></TableCell>
                              </TableRow>

                              <TableRow hover>
                                <TableCell align="left" style={{whiteSpace: 'normal', wordWrap: 'break-word'}}>じゃらんお支払済み金額</TableCell>
                                <TableCell align="right"></TableCell>
                                <TableCell align="right"></TableCell>
                                <TableCell align="right"></TableCell>
                                <TableCell align="right">- ¥ {getReservationTotalJalanPaid(reservation).toLocaleString()}</TableCell>
                              </TableRow>
                            </Fragment>
                          )
                        }


                        <TableRow>
                          <TableCell align="center" colSpan={5}></TableCell>
                        </TableRow>


                        <TableRow className={classes.lightColorRow}>
                          <TableCell align="left" style={{whiteSpace: 'normal', wordWrap: 'break-word'}}>宿泊料金小計</TableCell>
                          <TableCell align="right"></TableCell>
                          <TableCell align="right"></TableCell>
                          <TableCell align="right"></TableCell>
                          <TableCell align="right">¥ {getReservationTotal(reservation).toLocaleString()}</TableCell>
                        </TableRow>
                        <TableRow className={classes.lightColorRow}>
                          <TableCell align="left" style={{whiteSpace: 'normal', wordWrap: 'break-word'}}>( 内消費税等</TableCell>
                          <TableCell align="right"></TableCell>
                          <TableCell align="right"></TableCell>
                          <TableCell align="right"></TableCell>
                          <TableCell align="right">¥ {getReservationTotalInnerTax(reservation).toLocaleString()} )</TableCell>
                        </TableRow>


                        <TableRow style={{display: reservation.orders.length > 0 ? '' : 'none'}}>
                          <TableCell align="center" colSpan={5}></TableCell>
                        </TableRow>


                        {reservation.orders.map(order => {
                            return (
                              <TableRow hover key={order.itemId}>
                                <TableCell align="left" style={{whiteSpace: 'normal', wordWrap: 'break-word'}}>{order.name}</TableCell>
                                <TableCell align="right">¥ {order.unitPrice.toLocaleString()}</TableCell>
                                <TableCell align="right">{order.amount}</TableCell>
                                <TableCell align="right">{order.taxRate * 100} %</TableCell>
                                <TableCell align="right">¥ {(order.unitPrice * order.amount).toLocaleString()}</TableCell>
                              </TableRow>
                            )
                        })}

                        <TableRow style={{display: reservation.orders.length > 0 ? '' : 'none'}}>
                          <TableCell align="center" colSpan={5}></TableCell>
                        </TableRow>

                        <TableRow className={classes.lightColorRow} style={{display: reservation.orders.length > 0 ? '' : 'none'}}>
                          <TableCell align="left" style={{whiteSpace: 'normal', wordWrap: 'break-word'}}>ご飲食料金小計</TableCell>
                          <TableCell align="right"></TableCell>
                          <TableCell align="right"></TableCell>
                          <TableCell align="right"></TableCell>
                          <TableCell align="right">¥ {getOrdersTotal(reservation).toLocaleString()}</TableCell>
                        </TableRow>
                        <TableRow className={classes.lightColorRow} style={{display: reservation.orders.length > 0 ? '' : 'none'}}>
                          <TableCell align="left" style={{whiteSpace: 'normal', wordWrap: 'break-word'}}>( 内消費税等</TableCell>
                          <TableCell align="right"></TableCell>
                          <TableCell align="right"></TableCell>
                          <TableCell align="right"></TableCell>
                          <TableCell align="right">¥ {getOrdersTotalInnerTax(reservation).toLocaleString()} )</TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell align="center" colSpan={5}></TableCell>
                        </TableRow>



                        <TableRow className={classes.darkColorRow}>
                          <TableCell align="left" style={{whiteSpace: 'normal', wordWrap: 'break-word'}}>合計</TableCell>
                          <TableCell align="right"></TableCell>
                          <TableCell align="right"></TableCell>
                          <TableCell align="right"></TableCell>
                          <TableCell align="right">¥ {(getReservationTotal(reservation) + getOrdersTotal(reservation)).toLocaleString()}</TableCell>
                        </TableRow>
                        <TableRow className={classes.lightColorRow}>
                          <TableCell align="left" style={{whiteSpace: 'normal', wordWrap: 'break-word'}}>(消費税等小計</TableCell>
                          <TableCell align="right"></TableCell>
                          <TableCell align="right"></TableCell>
                          <TableCell align="right"></TableCell>
                          <TableCell align="right">¥ {(getReservationTotalInnerTax(reservation) + getOrdersTotalInnerTax(reservation)).toLocaleString()})</TableCell>
                        </TableRow>


                      </TableBody>
                    </Table>


                    <Grid item xs={12} style={{marginTop: 24}}>
                      <FormControl required={true}>
                        <FormLabel>種別選択</FormLabel>
                        <RadioGroup
                          aria-label="position"
                          name="position"
                          defaultValue="direct"
                          value={reservation.inputFormType}
                          onChange={(e,v) => handleChangeStoredActiveReservations(reservation, 'inputFormType', v)}
                        >
                          <FormControlLabel
                            value="receipt"
                            control={<Radio color="primary" size='small' />}
                            label="領収書 兼 ご利用明細書"
                          />
                          <FormControlLabel
                            value="detailedStatement"
                            control={<Radio color="primary" size='small' />}
                            label="ご利用明細書"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} style={{marginTop: 8, display: reservation.inputFormType === 'receipt' ? '' : 'none'}}>
                      <FormControl required={true}>
                        <FormLabel>お支払い方法</FormLabel>
                        <RadioGroup
                          aria-label="position"
                          name="position"
                          defaultValue="direct"
                          value={reservation.inputMethodOfPayment}
                          onChange={(e,v) => handleChangeStoredActiveReservations(reservation, 'inputMethodOfPayment', v)}
                        >
                          <FormControlLabel
                            value="cash"
                            control={<Radio color="primary" size='small' />}
                            label="現金"
                          />
                          <FormControlLabel
                            value="card"
                            control={<Radio color="primary" size='small' />}
                            label="クレジットカード利用"
                          />
                          <FormControlLabel
                            value="eMoney"
                            control={<Radio color="primary" size='small' />}
                            label="電子マネー利用"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>

                    <Grid item xs={10} style={{display: reservation.inputFormType === 'receipt' ? '' : 'none'}}>
                      <TextField
                        label="宛名"
                        helperText="敬称を含めて記載してください。敬称のみ印刷するときは、宛名をクリアしてください。"
                        className={classes.textField}
                        value={reservation.inputReceiptAddress}
                        onChange={(e) => handleChangeStoredActiveReservations(reservation, 'inputReceiptAddress', e.target.value)}
                        margin="none"
                      />
                    </Grid>

                    {(() => {
                      if(reservation.inputReceiptAddress !== ''){
                        return (
                          <Grid item xs={2} style={{display: reservation.inputFormType === 'receipt' ? '' : 'none'}}>
                            <Button
                              onClick={() => handleChangeStoredActiveReservations(reservation, 'inputReceiptAddress', '')}
                              variant="contained"
                              color="default"
                              style={{marginTop: 8}}
                            >
                              クリア
                            </Button>
                          </Grid>
                        )
                      }
                    })()}

                    {(() => {
                      if(reservation.inputReceiptAddress === ''){
                        return (
                          <Grid item xs={2} style={{display: reservation.inputFormType === 'receipt' ? '' : 'none'}}>
                            <Button
                              onClick={() => handleChangeStoredActiveReservations(
                                reservation, 'inputReceiptAddress',
                                reservation.lastName + ' ' + reservation.firstName + ' 様'
                              )}
                              variant="contained"
                              color="default"
                              style={{marginTop: 8}}
                            >
                              戻す
                            </Button>
                          </Grid>
                        )
                      }
                    })()}


                    <Grid
                      item xs={12}
                      style={{
                        marginTop: 8,
                        display: (reservation.inputFormType === 'receipt' && reservation.inputReceiptAddress === '') ? '' : 'none'
                      }}
                    >
                      <FormControl required={true}>
                        <FormLabel>敬称のみ印刷</FormLabel>
                        <RadioGroup
                          aria-label="position"
                          name="position"
                          defaultValue="direct"
                          value={reservation.inputHonorificTitle}
                          onChange={(e,v) => handleChangeStoredActiveReservations(reservation, 'inputHonorificTitle', v)}
                        >
                          <FormControlLabel
                            value="no"
                            control={<Radio color="primary" size='small' />}
                            label="なし"
                          />
                          <FormControlLabel
                            value="sama"
                            control={<Radio color="primary" size='small' />}
                            label="様"
                          />
                          <FormControlLabel
                            value="onchu"
                            control={<Radio color="primary" size='small' />}
                            label="御中"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>

                    <Grid item xs={10} style={{display: reservation.inputFormType === 'receipt' ? '' : 'none'}}>
                      <TextField
                        label="領収書備考"
                        helperText="入力内容が、但し書きの下に追加されます"
                        className={classes.textField}
                        value={reservation.inputReceiptNote}
                        onChange={(e) => handleChangeStoredActiveReservations(reservation, 'inputReceiptNote', e.target.value)}
                        margin="none"
                      />
                    </Grid>
                    <Grid item xs={2} style={{display: reservation.inputFormType === 'receipt' ? '' : 'none'}} />

                    <Grid item xs={4}>
                      <TextField
                        className={classes.textField}
                        name="issueDate"
                        label="発行日"
                        value={reservation.inputIssueDate}
                        margin="normal"
                        onChange={(e) => handleChangeStoredActiveReservations(reservation, 'inputIssueDate', e.target.value)}
                        type="date"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                  </Grid>
                </AccordionDetails>
              </Accordion>
            )
          })}

          <Grid container spacing={2} className={classes.paper} style={{paddingLeft: 0}}>

            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isPrintAll}
                    onChange={(e) => handleIsPrintAll(e.target.checked)}
                    name="isPrintAll"
                    color="primary"
                  />
                }
                label="すべてを選択"
              />
            </Grid>

            <Grid item xs={6}/>

            <Grid item xs={3}>
              <Button onClick={handlePrintBefore} variant="contained" color="primary" className={classes.button}>
                選択した領収書を発行
              </Button>
            </Grid>

          </Grid>


        </Grid>
      </Grid>

      <div style={{display: "none"}}>
        <Receipt ref={componentRef} reservations={storedReservationsToPrint} tenantInfo={storedTenantInfo}/>
      </div>
    </Fragment>
  );
}
