import React, { useState, useEffect, Fragment } from 'react';
import { useHistory } from "react-router";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar'

import { useSnackbar } from 'notistack';
import { API } from 'aws-amplify';
import moment from 'moment';
import 'moment/locale/ja'
import { v4 as uuidv4 } from 'uuid';
import Color from 'color';

import { style } from '../services/Settings'

const useStyles = style



export default function Calendar(props) {

  const classes = useStyles();
  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();

  const [roomPlanMasters, setRoomPlanMasters] = useState([]);

  const [storedYearMonth, setStoredYearMonth] = useState('2000-01');

  const [storedEvents, setStoredEvents] = useState([]);

  const [isUseFullButton, setIsUseFullButton] = useState(false);


  // テナント情報の取得
  useEffect(() => {

    // ページのトップにフォーカス
    window.scrollTo(0, 0)


    // 部屋タイプの取得
    let apiName = 'MyAPIGatewayAPI'; // replace this with your api name.
    let path = '/tenant'; //replace this with the path you have configured on your API

    let roomPlanMasters = []

    API.get(apiName, path).then(response => {
      console.log(response);

      if(response && response.tenant && response.tenant.isUseFullButton){
        setIsUseFullButton(response.tenant.isUseFullButton)
      }

      roomPlanMasters = response.tenant.roomPlanMasters
      setRoomPlanMasters(roomPlanMasters)
    }).catch(error => {
      console.log(error);
      enqueueSnackbar('エラーが発生しました。', {variant : 'error'})
    })

  },[enqueueSnackbar])



  // 初期の月を設定
  useEffect(() => {

    let unmounted = false;

    //アンマウントされていなければステートを更新
    if(!unmounted) {
      setStoredYearMonth(moment().format('YYYY-MM'))
    };

    //クリーンアップ関数を返す
    return ()=>{ unmounted = true; };

  },[roomPlanMasters])



  // イベントを更新
  useEffect(() => {

    let unmounted = false;

    //アンマウントされていなければステートを更新
    if(!unmounted) {


      async function updateEvent() {
        let datesForRoomTypeAllocations = []

        // roomTyepeAllocationの取得
        let apiName = 'MyAPIGatewayAPI';
        let path = '/allocations'; //replace this with the path you have configured on your API
        const myInit = {
          queryStringParameters: {
            yearMonth: storedYearMonth
          },
        };

        try{
          const response = await API.get(apiName, path, myInit)
          console.log(response);
          datesForRoomTypeAllocations = response.datesForRoomTypeAllocations
        }catch(error){
          console.log(error);
          enqueueSnackbar('エラーが発生しました。', {variant : 'error'})
        }

        let start = moment(storedYearMonth).startOf('month')
        let end = moment(storedYearMonth).endOf('month')


        //Eventsを作成
        let events = []

        for(let date = start; start.isSameOrBefore(end); date.add(1, 'days')) {
          roomPlanMasters.map((roomPlanMaster,i) => {
            if(moment(date).isSameOrAfter(moment(roomPlanMaster.startDate)) && (roomPlanMaster.endDate === null || moment(date).isSameOrBefore(moment(roomPlanMaster.endDate)))){

              events.push({
                id: uuidv4(),
                roomTypeId: roomPlanMaster.roomTypeId,
                roomTypeName: roomPlanMaster.roomTypeName,
                stocks: roomPlanMaster.rooms.length,
                empty: roomPlanMaster.rooms.length,
                reserved: 0,
                color: Color('#ffdede').rotate(60 * i).hex(),
                allDay: true,
                start: date.toDate(),
                end: date.toDate(),
              })
            }
            return null
          })

          events.push({
            id: uuidv4(),
            isUseFullButtonEvent: true,
            isFull: false,
            start: date.toDate(),
            end: date.toDate(),
          })

        }

        let eventsAfter = JSON.parse(JSON.stringify(events))

        events.map((event, i) => {
          datesForRoomTypeAllocations.map(dateForRoomTypeAllocations => {

            if(moment(event.start).format('YYYY-MM-DD') === dateForRoomTypeAllocations.date){
              dateForRoomTypeAllocations.roomTypeAllocations.map(roomTypeAllocation => {
                if(roomTypeAllocation.roomTypeId === event.roomTypeId){
                  eventsAfter[i].stocks = roomTypeAllocation.stocks
                  eventsAfter[i].empty = roomTypeAllocation.stocks -  roomTypeAllocation.overnights.length
                  eventsAfter[i].reserved = roomTypeAllocation.overnights.length
                }
                return null
              })

              if('isFull' in dateForRoomTypeAllocations && event.isUseFullButtonEvent){
                eventsAfter[i].isFull = dateForRoomTypeAllocations.isFull
              }

              return null
            }
            return null
          })
          return null
        })

        setStoredEvents(eventsAfter)
      }

      if(roomPlanMasters.length > 0){
        updateEvent()
      }
    };

    //クリーンアップ関数を返す
    return ()=>{ unmounted = true; };

  },[storedYearMonth, roomPlanMasters, enqueueSnackbar])





  function CustomToolbar(toolbar) {

    const classes = useStyles();

    const goToBack = () => { toolbar.onNavigate('PREV'); };
    const goToNext = () => { toolbar.onNavigate('NEXT'); };
    const goToCurrent = () => { toolbar.onNavigate('TODAY'); };

    const date = moment(toolbar.date);

    return (
      <Grid container spacing={2} className={classes.container} style={{margin: 18, width: '97%'}} justify="center" alignItems="center">

        <Grid item xs={2}/>

        <Grid item xs={2}>
          <Button className={classes.button} style={{marginTop: 0}} variant="contained" color="default" onClick={goToBack}>前月</Button>
        </Grid>

        <Grid item xs={4} style={{textAlign:'center', padding: 0}}>
          <Typography className='rbc-month-title' variant="h5" onClick={goToCurrent}>{date.format('YYYY年')} {date.format('M月')}</Typography>
        </Grid>

        <Grid item xs={2}>
          <Button className={classes.button} style={{marginTop: 0}} variant="contained" color="default" onClick={goToNext}>翌月</Button>
        </Grid>

        <Grid item xs={2}/>

      </Grid>
    );
  };

  function Event({ event }) {

    if(event.isUseFullButtonEvent){

      return (
        <div style={{marginTop:0, textAlign: 'center'}}>
          <ToggleButton
            value="check"
            selected={event.isFull}
            classes={{
              root: classes.toggleButtonRoot,
              selected: classes.toggleButtonSelected,
            }}
          >
            満室表示
          </ToggleButton>
        </div>
      )

    }else{

      let color = Color(event.color).darken(0.35)
      let borderWidth = 0

      if(event.empty !== 0 && event.stock !== 0){
        borderWidth = 160 * event.empty / event.stocks
      }

      return (
        <div style={{marginTop:6, color: '#505050'}}>
          <Typography variant="body2" style={{margin: 0, padding:0, paddingLeft: 4}}>
            {event.roomTypeName}
          </Typography>
          <Typography variant="body1" style={{margin: 0, textAlign: 'right', paddingRight:8}}>
            <span style={{fontSize: '0.8em'}}>在庫: {event.stocks} 予約: {event.reserved} 空:</span> <strong>{event.empty}</strong>
          </Typography>
          <div style={{width: borderWidth, borderBottom: '5px solid ' + color, float: 'right'}}></div>
        </div>
      )

    }

  }

  function DateHeader({ date, label }) {

    let color = '#656565'

    let satColor = '#587fff'
    let sunColor = '#ff5659'

    if(
      moment(date).isBetween(moment(storedYearMonth).startOf('month'), moment(storedYearMonth).endOf('month')) &&
      moment(date).format('ddd') === '土'
    ){
      color = satColor
    }

    if(
      moment(date).isBetween(moment(storedYearMonth).startOf('month'), moment(storedYearMonth).endOf('month')) &&
      moment(date).format('ddd') === '日'
    ){
      color = sunColor
    }


    return (
      <div style={{color: color}} onClick={() => handleClickDate(moment(date).format('YYYY-MM-DD'))}>

        <Typography
          variant="h5"
          style={{fontWeight: moment(date).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') ? 'bold' : ''}}
          className={classes.calendarDay}
        >
        <span style={{fontSize: '0.7em', marginRight: '10px'}}>
          {moment(date).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')? '-Today-' : ''}
        </span>
        {label}
        </Typography>

        {/* <h4>昭和の日</h4> */}
      </div>
    );
  }




  const onNavigate = (date, view, action) => {
    if(moment(date).format('YYYY-MM') !== storedYearMonth){
      setStoredEvents([])
      setStoredYearMonth(moment(date).format('YYYY-MM'))
    }
  }


  const eventPropGetter = (event) => {

    let emptyDivideStocks = 0

    if(event.stocks !== 0 && event.empty !== 0){
      emptyDivideStocks = event.empty / event.stocks
    }

    let backgroundColor = 'rgba(0,0,0,0)'

    if(event.color){
      backgroundColor = Color(event.color).desaturate(1 - emptyDivideStocks).fade(1 - emptyDivideStocks)
    }


    return {
      className: "",
      style: {
        maxWidth: 160,
        marginTop: 4,
        padding: 0,
        outline: 'none',
        backgroundColor: backgroundColor,
        color: '#000000de',
        borderRadius: 0
      }
    };
  }


  const onSelectEvent = (event) => {

    if(event.isUseFullButtonEvent){

      let apiName = 'MyAPIGatewayAPI'; // replace this with your api name.
      let path = '/allocations'; //replace this with the path you have configured on your API
      let myInit = {
        body: {
          date: moment(event.start).format('YYYY-MM-DD'),
          isFull: !(event.isFull)
        }
      }

      API.put(apiName, path, myInit).then(response => {

        console.log(response)

        let eventsAfter = JSON.parse(JSON.stringify(storedEvents))

        storedEvents.map((sotredEvent, i) => {
          if(moment(sotredEvent.start).format('YYYY-MM-DD') === moment(event.start).format('YYYY-MM-DD')){

            eventsAfter[i].isFull = !(event.isFull)

            return null
          }
          return null
        })

        setStoredEvents(eventsAfter)

        enqueueSnackbar(response.message, {variant : 'success'});

      }).catch(error => {
        console.log(error)

        if(error.message){
          enqueueSnackbar(error.message, {variant : 'error'})
          return
        }

        enqueueSnackbar('エラーが発生しました。', {variant : 'error'})
      });

    }else{
      if(event.stocks !== 0 && event.empty !== 0){
        history.push("/main/reservation?roomTypeId=" + event.roomTypeId + '&startDate=' + moment(event.start).format('YYYY-MM-DD'));
      }else{
        enqueueSnackbar('空きがありません', {variant : 'warning'})
      }
    }

  }


  const getMaxUniqueRoomTypesNum = () => {
    let maxRoomTypes = storedEvents.map(event => {
      return event.roomTypeId
    })
    const maxUniqueRoomTypes = Array.from(new Set(maxRoomTypes))
    return maxUniqueRoomTypes.length
  }

  const handleClickDate = (date) => {
    history.push('/main/room-allocation?date=' + date);
  }


  const formats = {
    dateFormat: 'D',
    monthHeaderFormat : 'YYYY年 M月'
  }

  const localizer = momentLocalizer(moment)


  return (
    <Fragment>
      <link rel="stylesheet" href="../css/Calendar.css" />
      <Grid container spacing={2} className={classes.container}>
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom>
            予約カレンダー
          </Typography>
        </Grid>
        <Grid item xs={12}
           style={{
            height: 390 + (60 * (getMaxUniqueRoomTypesNum() - (isUseFullButton ? 1 : 0)) * 6) + (isUseFullButton ? 32 * 6 : 0),
            display: storedEvents.length > 0 ? 'flex' : 'none',
          }}
        >
          <BigCalendar
            localizer={localizer}
            events={storedEvents}
            formats={formats}
            startAccessor="start"
            endAccessor="end"
            components={{
              toolbar: CustomToolbar,
              event: Event,
              month: {
                dateHeader: DateHeader
              }
            }}
            eventPropGetter={
              (event) => {
                return eventPropGetter(event)
              }
            }
            dayPropGetter={
              (date) => {
                return {
                  className: "",
                  style: {
                    color: 'lightGray'
                  }
                };
              }
            }
            onNavigate={(date, view, action) => {
              onNavigate(date, view, action)
            }}
            onSelectEvent={(event) => {
              onSelectEvent(event)
            }}
          />
        </Grid>

      </Grid>

    </Fragment>
  );
}
