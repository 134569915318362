import React from 'react';
import ReactDOM from 'react-dom';
import App from './base/App';
import { SnackbarProvider } from 'notistack';
import { theme } from './base/MyTheme'
import { ThemeProvider } from '@material-ui/core/styles'
import Amplify from 'aws-amplify';

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
  },
  API: {
    endpoints: [
      {
        name: "MyAPIGatewayAPI",
        endpoint: process.env.REACT_APP_API_ENDPOINT,
        region: process.env.REACT_APP_REGION,
      }
    ]
  },
  Storage: {
     bucket: process.env.REACT_APP_BUKET_NAME,
     region: process.env.REACT_APP_REGION,
     identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID
  }
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <SnackbarProvider
      maxSnack={5}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      <App />
    </SnackbarProvider>
  </ThemeProvider>,
  document.getElementById('root')
);
