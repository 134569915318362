import React, { useState, useEffect, Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import SvgIcon from 'react-icons-kit';
import { pencilSquareO } from 'react-icons-kit/fa/'
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutline';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';



import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import { useSnackbar } from 'notistack';
import { API } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
// import moment from 'moment';

import { style, taxRate, reducedTaxRate } from '../services/Settings'

const useStyles = style

export default function OrderManage(props) {

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [orderMenus, setOrderMenus] = useState([]);

  const [inputName, setInputName] = useState('');
  const [inputUnitPrice, setInputUnitPrice] = useState('');
  const [inputAmount, setInputAmount] = useState('1');
  const [inputTaxRate, setInputTaxRate] = useState(taxRate);

  const [storedActiveReservations, setStoredActiveReservations] = useState([]);
  const [storedAddOrdersReservation, setStoredAddOrdersReservation] = useState(null);
  const [storedAddOrdersReservationOrders, setStoredAddOrdersReservationOrders] = useState([]);

  const TAX_RATE = {
    taxRate: "10%",
    reducedTaxRate: "8%",
  }


  // テナント情報と予約情報の取得
  useEffect(() => {

    // ページのトップにフォーカス
    window.scrollTo(0, 0)

    // オーダーメニューを取得
    let apiName = 'MyAPIGatewayAPI'
    let path1 = '/tenant'

    API.get(apiName, path1).then(response => {
      console.log(response);

      setOrderMenus(response.tenant.orderMenus)

    }).catch(error => {
      console.log(error);
      enqueueSnackbar('エラーが発生しました。', {variant : 'error'})
    })

    // チェックイン対象の予約の取得
    let path2 = '/reservations'

    let myInit = {
      queryStringParameters: {
          status: 'active_checked_in',
      },
    };

    API.get(apiName, path2, myInit).then(response => {
      console.log(response);

      let activeReservations = JSON.parse(JSON.stringify(response.reservations))

      activeReservations.sort((a,b) => {
        if(a.lastNameKana > b.lastNameKana) {
          return 1
        }else {
          return -1
        }
      })

      setStoredActiveReservations(activeReservations)

    }).catch(error => {
      console.log(error);
      enqueueSnackbar('エラーが発生しました。', {variant : 'error'})
    })

  },[enqueueSnackbar])



  // ダイアログを開いたとき
  useEffect(() => {
    let unmounted = false;
    //アンマウントされていなければステートを更新
    if(!unmounted) {
      if(storedAddOrdersReservation){
        let apiName = 'MyAPIGatewayAPI'
        let path = '/reservations/' + storedAddOrdersReservation.reservationId

        API.get(apiName, path).then(response => {
          console.log(response);

          let orders = response.reservation.orders

          setStoredAddOrdersReservationOrders(orders)

          setInputName('')
          setInputUnitPrice('')
          setInputAmount('1')
          setInputTaxRate(taxRate)

        }).catch(error => {
          console.log(error);
          enqueueSnackbar('エラーが発生しました。', {variant : 'error'})
        })
      }
    };
    //クリーンアップ関数を返す
    return ()=>{ unmounted = true; };
  },[storedAddOrdersReservation, enqueueSnackbar])



  const addOrder = (categoryId, itemId) => {
    let orderAfter = []

    orderAfter = JSON.parse(JSON.stringify(storedAddOrdersReservationOrders))

    let orderItem = null

    orderMenus.map(orderMenu => {
      if(orderMenu.categoryId === categoryId){
        orderMenu.items.map(item => {
          if(item.itemId === itemId){
            orderItem = item
          }
          return null
        })
      }
      return null
    })

    let isOrderNotInclude = true

    let index = 0

    orderAfter.map((orderItemAfter, i) => {
      if(orderItemAfter.itemId === orderItem.itemId){
        isOrderNotInclude = false
        index = i
      }
      return null
    })

    if(isOrderNotInclude){
      orderAfter.push({
        itemId: orderItem.itemId,
        name: orderItem.name,
        unitPrice: orderItem.unitPrice,
        taxRate: orderItem.taxRate,
        amount: 1
      })
    }else{
      orderAfter[index].amount += 1
    }

    setStoredAddOrdersReservationOrders(orderAfter)
  }



  const handleChangeAmount = (itemId , amount) => {
    let orderAfter = JSON.parse(JSON.stringify(storedAddOrdersReservationOrders))

    let index = 0

    orderAfter.map((orderItemAfter, i) => {
      if(orderItemAfter.itemId === itemId){
        index = i
      }
      return null
    })

    orderAfter[index].amount += amount

    if(orderAfter[index].amount === 0){
      orderAfter.splice(index, 1)
    }

    setStoredAddOrdersReservationOrders(orderAfter)

  }



  const calculateOrders = () => {
    let total = 0
    storedAddOrdersReservationOrders.map(order => {
      total += (order.unitPrice * order.amount)
      return null
    })

    return total
  }



  const saveOrders = () => {
    let apiName = 'MyAPIGatewayAPI'
    let path = '/reservations/' + storedAddOrdersReservation.reservationId

    let myInit = {
      body: {
        orders: storedAddOrdersReservationOrders
      }
    }

    API.put(apiName, path, myInit).then(response => {
      console.log(response);

      setStoredAddOrdersReservation(null)
      setStoredAddOrdersReservationOrders([])

    }).catch(error => {
      console.log(error);
      enqueueSnackbar('エラーが発生しました。', {variant : 'error'})
    })
  }


  const addManualOder = () => {

    let orderAfter = []

    orderAfter = JSON.parse(JSON.stringify(storedAddOrdersReservationOrders))

    orderAfter.push({
      itemId: 'omi-' + uuidv4(),
      name: inputName,
      unitPrice: inputUnitPrice,
      taxRate: Number(inputTaxRate),
      amount: Number(inputAmount)
    })

    setStoredAddOrdersReservationOrders(orderAfter)
  }



  return (
    <Fragment>
      <Grid container spacing={2} className={classes.container}>
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom>
            オーダー入力
          </Typography>
        </Grid>

        <Grid item xs={12}　style={{display: storedActiveReservations.length > 0 ? 'none' : ''}}>
          <Typography
            variant="h5"
            gutterBottom
            style={{marginTop: 20, marginLeft: 30}}
          >
            予約がありません
          </Typography>
        </Grid>

        <Grid item xs={12}　style={{display: storedActiveReservations.length > 0 ? '' : 'none'}}>
          <Paper className={classes.paper} elevation={1}>
            <Grid container spacing={2} className={classes.container}>
              <Grid item xs={12}>
                <Typography variant="h5" gutterBottom>
                  チェックイン済みのご予約
                </Typography>
              </Grid>

              <Table size='small'>
                <TableHead>
                  <TableRow className={classes.lightColorRow}>
                    <TableCell align="center">お客様情報</TableCell>
                    <TableCell align="center"></TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>

                  {storedActiveReservations.map((reservation, i) => {
                    return (
                      <TableRow hover key={reservation.reservationId}>

                        <TableCell align="left" style={{paddingLeft: 50}}>
                          <Typography gutterBottom variant="caption">
                            {reservation.lastNameKana} {reservation.firstNameKana}
                          </Typography>
                          <Typography variant="h6" style={{marginTop: '-5px'}}>
                            {reservation.lastName} {reservation.firstName} 様
                          </Typography>
                          <br/>
                          <Typography variant="subtitle2">
                            {reservation.addressPrefectures} {reservation.addressCity}
                          </Typography>
                          <Typography variant="subtitle2">
                            {reservation.addressOther}
                          </Typography>
                        </TableCell>

                        <TableCell align="center" size='small' style={{padding:16}}>
                          <Button onClick={() => setStoredAddOrdersReservation(reservation)} variant="contained" color="default" className={classes.tableButtonNarrow}>
                            <Table className={classes.buttonTable}>
                              <TableBody>
                                <TableRow style={{padding:0, margin:0}}>
                                  <TableCell style={{padding:0, margin:0}}>
                                    <SvgIcon style={{padding:0, margin:'0 8px 0 12px'}} size={18} icon={pencilSquareO}/>
                                  </TableCell>
                                  <TableCell style={{padding:'2px 8px 0 0', margin: 0}}>
                                    入力
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </Button>
                        </TableCell>

                      </TableRow>
                    )
                  })}

                </TableBody>
              </Table>


            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <Dialog
        open={storedAddOrdersReservation ? true : false}
        keepMounted
        onClose={() => setStoredAddOrdersReservation(null)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
      >
        <DialogTitle id="scroll-dialog-title">オーダー入力</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} className={classes.container}>
            <Grid item xs={12}>
              {orderMenus.map(n => {
                  return(
                    <Fragment key={n.categoryId}>
                      <Typography  key={n.categoryName} variant="subtitle1" style={{marginTop:20}}>
                        {n.categoryName}
                      </Typography>
                      {n.items.map(m => {
                        return (
                          <Button key={m.itemId} onClick={() => addOrder(n.categoryId, m.itemId)} size="small" variant="contained"  color="default" className={classes.menuButton}>
                            {m.shortName}
                          </Button>
                        )
                      })}
                    </Fragment>
                  )
                })}
              </Grid>


              <Grid item xs={12}>
                <ValidatorForm
                  onSubmit={() => addManualOder()}
                  onError={
                    errors => {
                      console.log(errors);
                      enqueueSnackbar('入力エラーがあります。確認してください。', {variant : 'warning'});
                    }
                  }
                  className={classes.validatorForm}
                >
                  <Grid container spacing={2} className={classes.container}>


                    <Grid item xs={3}>
                      <TextValidator
                        className={classes.textField}
                        name="name"
                        label="品名"
                        value={inputName}
                        margin="normal"
                        onChange={(e) => setInputName(e.target.value)}
                        inputProps={{
                          step: 1,
                        }}
                        required={true}
                        validators={['required']}
                        errorMessages={['必須項目を入力してください']}
                      />
                    </Grid>

                    <Grid item xs={2}>
                      <TextValidator
                        className={classes.textField}
                        name="unitPrice"
                        label="単価"
                        value={inputUnitPrice}
                        margin="normal"
                        onChange={(e) => setInputUnitPrice(e.target.value)}
                        type="number"
                        inputProps={{
                          step: 1,
                        }}
                        required={true}
                        validators={['required', 'isNumber', 'minNumber:1']}
                        errorMessages={['必須項目を入力してください', '整数を入力してください', '1以上の数を入力してください']}
                      />
                    </Grid>

                    <Grid item xs={1}>
                      <FormControl required={true} style={{marginTop: 16}}>
                        <InputLabel>税率</InputLabel>
                        <Select
                          value={inputTaxRate}
                          onChange={(e) => setInputTaxRate(e.target.value)}
                          style={{minWidth: 50}}
                        >
                          <MenuItem value={taxRate}>{TAX_RATE.taxRate}</MenuItem>
                          <MenuItem value={reducedTaxRate}>{TAX_RATE.reducedTaxRate}</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={2}>
                      <TextValidator
                        className={classes.textField}
                        name="amount"
                        label="数量"
                        value={inputAmount}
                        margin="normal"
                        onChange={(e) => setInputAmount(e.target.value)}
                        type="number"
                        inputProps={{
                          step: 1,
                        }}
                        required={true}
                        validators={['required', 'isNumber', 'minNumber:1']}
                        errorMessages={['必須項目を入力してください', '整数を入力してください', '1以上の数を入力してください']}
                      />
                    </Grid>


                    <Grid item xs={1}>
                      <Button type="submit" variant="contained" color="default" className={classes.button} style={{marginTop:24}}>
                        追加
                      </Button>
                    </Grid>

                  </Grid>
                </ValidatorForm>
              </Grid>



              <Grid item xs={12}>
                <Table size='small' className={classes.lightBorderTable}>
                  <TableHead style={{backgroundColor:"#f3f3f3"}}>
                    <TableRow>
                      <TableCell className={classes.longTableCell} align="center">品名</TableCell>
                      <TableCell align="center">単価 (税込)</TableCell>
                      <TableCell align="center" padding="none">消費税率</TableCell>
                      <TableCell align="center" style={{width:'20%'}} padding="none" colSpan={2}>数量</TableCell>
                      <TableCell align="center" style={{width:'15%'}}>小計</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {storedAddOrdersReservationOrders.map(n => {
                      return(
                        <TableRow hover key={n.itemId}>
                          <TableCell align="center">{n.name}</TableCell>
                          <TableCell align="center">￥ {Number(n.unitPrice).toLocaleString()}</TableCell>
                          <TableCell align="center" padding="none">{n.taxRate * 100} %</TableCell>
                          <TableCell align="center" padding="none" style={{padding: '0 0 0 3%'}}>{n.amount}</TableCell>
                          <TableCell align="center" padding="none">
                            <IconButton onClick={() => handleChangeAmount(n.itemId, 1)} size="small" color="default" aria-label="Add" className={classes.margin}>
                              <AddIcon />
                            </IconButton>
                            <IconButton onClick={() => handleChangeAmount(n.itemId, -1)} size="small" color="default" aria-label="Remove" className={classes.margin}>
                              <RemoveIcon />
                            </IconButton>
                          </TableCell>
                          <TableCell align="center">￥ {(Number(n.unitPrice) * Number(n.amount)).toLocaleString()}</TableCell>
                        </TableRow>
                      )
                    })}
                    <TableRow className={classes.darkColorRow}>
                      <TableCell align="center">オーダー小計 (税込)</TableCell>
                      <TableCell align="center"></TableCell>
                      <TableCell align="center" padding="none"></TableCell>
                      <TableCell align="center" padding="none"></TableCell>
                      <TableCell align="center" padding="none"></TableCell>
                      <TableCell align="center">¥ {calculateOrders().toLocaleString()}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>

              <Grid item xs={9}/>
              <Grid item xs={3} style={{marginBottom: 20}}>
                <Button onClick={() => saveOrders()} variant="contained" color="primary" className={classes.button}>
                  保存
                </Button>
              </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

    </Fragment>
  );
}
