import React, { useState, useEffect, Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import { fileText, checkCircle } from 'react-icons-kit/fa/'
import SvgIcon from 'react-icons-kit';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';



import { useSnackbar } from 'notistack';
import { API } from 'aws-amplify';
import moment from 'moment';

import { style } from '../services/Settings'

const useStyles = style

export default function CheckoutManage(props) {

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [storedActiveReservations, setStoredActiveReservations] = useState([]);
  const [storedCheckoutReservation, setStoredCheckoutReservation] = useState(null);
  const [inputCustomerNote, setInputCustomerNote] = useState('');

  // テナント情報と予約情報の取得
  useEffect(() => {

    // ページのトップにフォーカス
    window.scrollTo(0, 0)

    // チェックイン対象の予約の取得
    let apiName = 'MyAPIGatewayAPI'
    let path = '/reservations'

    let myInit = {
      queryStringParameters: {
          status: 'active_checked_in',
      },
    };

    API.get(apiName, path, myInit).then(response => {
      console.log(response);

      let activeReservations = JSON.parse(JSON.stringify(response.reservations))

      activeReservations.sort((a,b) => {
        if(a.lastNameKana > b.lastNameKana) {
          return 1
        }else {
          return -1
        }
      })

      setStoredActiveReservations(activeReservations)

    }).catch(error => {
      console.log(error);
      enqueueSnackbar('エラーが発生しました。', {variant : 'error'})
    })

  },[enqueueSnackbar])



  const getDateRange = (overnights) => {

    if(overnights.length === 1){
      return {
        start: moment(overnights[0].date).format('YYYY年M月D日'),
        end: null
      }
    }

    let dates = overnights.map(overnight => {
      return overnight.date
    })

    dates.sort((a, b) => moment(a).diff(moment(b)));

    return {
      start: moment(dates[0]).format('YYYY年M月D日'),
      end: moment(dates[dates.length -1]).format('YYYY年M月D日')
    }
  }


  const checkout = () => {

    let apiName = 'MyAPIGatewayAPI'; // replace this with your api name.
    let path = '/customers/' + storedCheckoutReservation.customerId; //replace this with the path you have configured on your API

    let myInit = {
      body: {
        customerNote: inputCustomerNote
      }
    }

    API.put(apiName, path, myInit).then(response => {

      console.log(response)
      enqueueSnackbar(response.message, {variant : 'success'});

      // チェックアウト処理
      let apiName = 'MyAPIGatewayAPI'; // replace this with your api name.
      let path = '/reservations/' + storedCheckoutReservation.reservationId; //replace this with the path you have configured on your API

      let myInit = {
        body: {
          status: 'inactive_checked_out'
        }
      }

      // 一旦クライアント側のステータスを変更
      let storedActiveReservationsAfter = JSON.parse(JSON.stringify(storedActiveReservations))

      // APIエラー時のバックアップ用
      let storedActiveReservationsBefore = JSON.parse(JSON.stringify(storedActiveReservations))

      storedActiveReservations.map((reservation, i) => {
        if(reservation.reservationId === storedCheckoutReservation.reservationId){
          storedActiveReservationsAfter.splice(i, 1)
        }
        return null
      })

      setStoredActiveReservations(storedActiveReservationsAfter)


      API.put(apiName, path, myInit).then(response => {

        console.log(response)
        enqueueSnackbar(response.message, {variant : 'success'});

        setStoredCheckoutReservation(null)

      }).catch(error => {
        console.log(error)

        if(error.message){
          enqueueSnackbar(error.message, {variant : 'error'})
          return
        }

        // StoredActiveReservationsをもとに戻す
        setStoredActiveReservations(storedActiveReservationsBefore)

        enqueueSnackbar('エラーが発生しました。', {variant : 'error'})
      });


    }).catch(error => {
      console.log(error)

      if(error.message){
        enqueueSnackbar(error.message, {variant : 'error'})
        return
      }

      enqueueSnackbar('エラーが発生しました。', {variant : 'error'})
    });

  }



  return (
    <Fragment>
      <Grid container spacing={2} className={classes.container}>
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom>
            チェックアウト管理
          </Typography>
        </Grid>

        <Grid item xs={12}　style={{display: storedActiveReservations.length === 0 ? '' : 'none'}}>
          <Typography
            variant="h5"
            gutterBottom
            style={{marginTop: 20, marginLeft: 30}}
          >
            予約がありません
          </Typography>
        </Grid>

        <Grid item xs={12}　style={{display: storedActiveReservations.length > 0 ? '' : 'none'}}>
          <Paper className={classes.paper} elevation={1}>
            <Grid container spacing={2} className={classes.container}>
              <Grid item xs={12}>
                <Typography variant="h5" gutterBottom>
                  チェックイン済みのご予約
                </Typography>
              </Grid>

              <Table size='small'>
                <TableHead>
                  <TableRow className={classes.lightColorRow}>
                    <TableCell align="center">宿泊日</TableCell>
                    <TableCell align="center">お客様情報</TableCell>
                    <TableCell align="center">領収書/明細</TableCell>
                    <TableCell align="center"></TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>

                  {storedActiveReservations.map((reservation, i) => {
                    return (
                      <TableRow hover key={reservation.reservationId}>

                        <TableCell align="center">
                          {getDateRange(reservation.overnights).start}<br/>
                          <div style={{transform: 'rotate(90deg)', margin: 5, display: getDateRange(reservation.overnights).end ? '' : 'none'}}>〜</div>
                          {getDateRange(reservation.overnights).end}
                        </TableCell>

                        <TableCell align="left" style={{paddingLeft: 50}}>
                          <Typography gutterBottom variant="caption">
                            {reservation.lastNameKana} {reservation.firstNameKana}
                          </Typography>
                          <Typography variant="h6" style={{marginTop: '-5px'}}>
                            {reservation.lastName} {reservation.firstName} 様
                          </Typography>
                          <br/>
                          <Typography variant="subtitle2">
                            {reservation.addressPrefectures} {reservation.addressCity}
                          </Typography>
                          <Typography variant="subtitle2">
                            {reservation.addressOther}
                          </Typography>
                        </TableCell>

                        <TableCell align="center" size='small' style={{padding:16}}>
                          <div style={{display: reservation.receiptSnapshots && reservation.receiptSnapshots.length > 0 ? '' : 'none'}}>
                            <SvgIcon style={{color: '#00a2a2', padding:0, marginBottom: 10}} size={22} icon={fileText}/><br/>
                            発行済
                          </div>
                          <div style={{display: reservation.receiptSnapshots && reservation.receiptSnapshots.length > 0 ? 'none' : ''}}>
                            <SvgIcon style={{color: '#969696', padding:0, marginBottom: 10}} size={22} icon={fileText}/><br/>
                            未発行
                          </div>
                        </TableCell>

                        <TableCell align="right" size='small' style={{padding:16}}>

                          <Grid container spacing={2} direction="column" justify="center" alignItems="flex-end">

                            <Button
                              onClick={() => {
                                setStoredCheckoutReservation(reservation)
                                setInputCustomerNote(reservation.customerNote ? reservation.customerNote : '')
                              }}
                              variant="contained"
                              color="default"
                              className={classes.tableButton}
                            >
                              <Table className={classes.buttonTable}>
                                <TableBody>
                                  <TableRow style={{padding:0, margin:0}}>
                                    <TableCell style={{padding:0, margin:0}}>
                                      <SvgIcon style={{padding:0, margin:'0 3px'}} size={18} icon={checkCircle}/>
                                    </TableCell>
                                    <TableCell style={{padding:'4px 0 0 0', margin:0}}>
                                      チェックアウト
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </Button>

                          </Grid>

                        </TableCell>

                      </TableRow>
                    )
                  })}

                </TableBody>
              </Table>

            </Grid>
          </Paper>
        </Grid>
      </Grid>



      <Dialog
        open={storedCheckoutReservation ? true : false}
        keepMounted
        onClose={() => setStoredCheckoutReservation(null)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          チェックアウトしますか？
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            チェックアウト処理後は、<br/>
            予約情報、オーダー情報の修正、領収書/ご使用明細の発行処理が行えません。<br/><br/>

            すべての処理の完了後にチェックアウトしてください。<br/><br/><br/>

            <strong style={{fontSize: '1.2em'}}>{storedCheckoutReservation　&& storedCheckoutReservation.lastName + ' ' + storedCheckoutReservation.firstName}</strong> 様の顧客情報備考を更新する場合は、こちらにご入力ください。

          </DialogContentText>
          <TextField
            label="顧客情報備考"
            multiline
            rows={4}
            style={{width: '100%',marginBottom:'1em'}}
            value={inputCustomerNote}
            onChange={(e) => setInputCustomerNote(e.target.value)}
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setStoredCheckoutReservation(null)} color="primary">
            いいえ
          </Button>
          <Button onClick={() => checkout()} color="primary">
            はい
          </Button>
        </DialogActions>
      </Dialog>



    </Fragment>
  );
}
