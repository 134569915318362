import React, { useState, useEffect, Fragment } from 'react';
import AuthService from '../services/AuthService'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import { useSnackbar } from 'notistack';
import { API } from 'aws-amplify';

import { style } from '../services/Settings'

const useStyles = style

export default function UserCongig(props) {

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const authApi = new AuthService();

  const [nickname, setNickname] = useState("");
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [retypeNewPassword, setRetypeNewPassword] = useState("");
  const [emailNow, setEmailNow] = useState("");
  const [nicknameNow, setNicknameNow] = useState("");
  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);

  const [isSampleTenant, setIsSampleTenant] = useState(false);


  useEffect(() => {
    const authApi = new AuthService();

    authApi.getNickname()
      .then(
        nickname => {
          authApi.getEmail()
            .then(
              email => {
                setNicknameNow(nickname)
                setEmailNow(email)
              }
            )
        }
      )

    let apiName = 'MyAPIGatewayAPI'; // replace this with your api name.
    let path = '/tenant'; //replace this with the path you have configured on your API

    API.get(apiName, path).then(response => {
      console.log(response);
      if(response && response.tenant && response.tenant.isSampleTenant){
        setIsSampleTenant(response.tenant.isSampleTenant)
      }
    }).catch(error => {
      console.log(error);
      enqueueSnackbar('エラーが発生しました。', {variant : 'error'})
    })

  },[enqueueSnackbar])


  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenConfirm = () => {
    setOpen(false)
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
    setOpen(true)
  };

  const handleCloseConfirmFinish = () => {
    setOpenConfirm(false);
  };

  const changeNickname = () => {
    authApi.updateNickname(nickname)
      .then(response => {

        if(response !== "SUCCESS"){
          enqueueSnackbar('Error: もう一度お試しください', {variant : 'error'});
        }else{
          enqueueSnackbar('ユーザー名を変更しました', {variant : 'success'});
          props.handleSetNickname()
        }

        setNickname("")
        setNicknameNow(nickname)

      })

  }

  const changeEmail = () => {
    authApi.getEmail()
      .then(emailOld => {
        if(email === emailOld){
          enqueueSnackbar('すでに同じメールアドレスが登録されています', {variant : 'warning'});
        }else{
          authApi.updateEmail(email)
            .then(response => {

              if(response !== "SUCCESS"){
                enqueueSnackbar('Error: もう一度お試しください', {variant : 'error'});
              }else{
                handleOpen()
                enqueueSnackbar('メールアドレス(ユーザーID)を変更しました', {variant : 'success'});
              }

              setEmail("")
              setEmailNow(email)

            })
        }
      })


  }

  const verifyEmail = () => {
    authApi.verifyEmail(code)
      .then(response => {

        if(response !== "SUCCESS"){

          if(response.code === "CodeMismatchException" || response.code === "ExpiredCodeException"){
            enqueueSnackbar('Error: 無効な検証コードです', {variant : 'error'});
          }else{
            enqueueSnackbar('Error: もう一度お試しください', {variant : 'error'});
          }

        }else{
          enqueueSnackbar('メールアドレスの検証が完了しました', {variant : 'success'});
          handleClose()
        }

        setCode("")

      })

  }

  const handleResent = () => {
    authApi.resentVerifyEmail(code)
      .then(response => {
        if(response === undefined){
          enqueueSnackbar('メールアドレスの検証コードを送信しました', {variant : 'success'});
          handleOpen()
        }else{
          if(response.code !== "LimitExceededException"){
            enqueueSnackbar('Error: 送信回数の上限に達しました。時間を置いてもう一度お試しください。', {variant : 'error'});
          }
        }
      })

  }

  const changePasswoerd = () => {
    authApi.changePassword(oldPassword, newPassword)
      .then(response => {

        if(response.code){
          if(response.code === "NotAuthorizedException"){
            enqueueSnackbar('現在のパスワードが間違っています。', {variant : 'error'});
          }

          if(response.code === "InvalidParameterException"){
            enqueueSnackbar('パスワードは８文字以上で、大文字、小文字を含んで設定してください。', {variant : 'error'});
          }

          if(response.code === "InvalidPasswordException"){
            enqueueSnackbar('パスワードは８文字以上で、大文字、小文字を含んで設定してください。', {variant : 'error'});
          }

          if(response.code === "LimitExceededException"){
            enqueueSnackbar('失敗回数が規定の回数を超えました。しばらくたってからお試しください。', {variant : 'error'});
          }
        }else{
          enqueueSnackbar('パスワードを変更しました。', {variant : 'success'});
        }

        setOldPassword("")
        setNewPassword("")
        setRetypeNewPassword("")

      })

  }

  ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
      if (value !== newPassword) {
          return false;
      }
      return true;
  })

  return (
    <Fragment>
      <Grid container spacing={2} className={classes.container}>

        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom>
            ユーザ設定
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Paper className={classes.paper} elevation={1}>
            <Grid container spacing={4} className={classes.container}>

              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  ユーザー名変更
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <div className={classes.textField} style={{marginTop: 10, marginBottom: 0}}>
                  現在のユーザー名:
                  <span style={{fontWeight: 'bold'}}>
                    　{nicknameNow}
                  </span>
                </div>
              </Grid>

              <Grid item xs={12}>
                <ValidatorForm
                  onSubmit={() => changeNickname()}
                  onError={
                    errors => {
                      console.log(errors);
                      enqueueSnackbar('入力エラーがあります。確認してください。', {variant : 'warning'});
                    }
                  }
                  className={classes.validatorForm}
                >
                  <Grid container spacing={4} className={classes.container}>

                    <Grid item xs={6}>
                      <TextValidator
                        className={classes.textField}
                        name="nickname"
                        label="ユーザー名"
                        value={nickname}
                        margin="normal"
                        onChange={(e) => setNickname(e.target.value)}
                        required={true}
                        validators={['required']}
                        errorMessages={['必須項目を入力してください']}
                        type="text"
                      />
                    </Grid>
                    <Grid item xs={6}/>


                    <Grid item xs={6}/>
                    <Grid item xs={2}>
                      <Button type="submit" variant="contained" color="primary" className={classes.button}>
                        変更
                      </Button>
                    </Grid>

                  </Grid>
                </ValidatorForm>
              </Grid>

            </Grid>
          </Paper>
        </Grid>


        <Grid item xs={12}>
          <Paper className={classes.paper} elevation={1}>
            <Grid container spacing={4} className={classes.container}>

              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  メールアドレス(ユーザーID)変更
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={4} className={classes.container}>

                  <Grid item xs={12}>
                    <div className={classes.textField} style={{marginTop: 10, marginBottom: 0}}>
                      現在のメールアドレス:　
                      <span style={{fontWeight: 'bold'}}>
                        {emailNow}
                      </span>
                    </div>
                  </Grid>

                  <Grid item xs={6}>
                    <Button color="default" variant="contained" onClick={handleResent} disabled={isSampleTenant}>
                      メールアドレス検証コードを再送する
                    </Button>
                  </Grid>


                </Grid>
              </Grid>

              <Grid item xs={12}>
                <div className={classes.textField} style={{marginTop: 60, marginBottom: -40}}>
                  ログインに利用するメールアドレスが変更されます<br/>
                  変更後は新しいメールアドレスでログインしてください<br/><br/><br/>
                </div>
              </Grid>

              <Grid item xs={12}>
                <ValidatorForm
                  onSubmit={() => changeEmail()}
                  onError={
                    errors => {
                      console.log(errors);
                      enqueueSnackbar('入力エラーがあります。確認してください。', {variant : 'warning'});
                    }
                  }
                  className={classes.validatorForm}
                >
                  <Grid container spacing={4} className={classes.container}>

                    <Grid item xs={6}>
                      <TextValidator
                        className={classes.textField}
                        name="email"
                        label="メールアドレス"
                        value={email}
                        margin="normal"
                        onChange={(e) => setEmail(e.target.value)}
                        required={true}
                        validators={['required','isEmail']}
                        errorMessages={['必須項目を入力してください','メールアドレスが間違っています']}
                        type="text"
                      />
                    </Grid>
                    <Grid item xs={6}/>


                    <Grid item xs={6}/>
                    <Grid item xs={2}>
                      <Button type="submit" variant="contained" color="primary" className={classes.button} disabled={isSampleTenant}>
                        変更
                      </Button>
                    </Grid>

                  </Grid>
                </ValidatorForm>
              </Grid>

            </Grid>

            <Dialog open={open} aria-labelledby="form-dialog-title">
              <DialogTitle id="form-dialog-title">メールアドレスの検証</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  メールで送信された検証コードを入力して、<br/>
                  <span style={{fontWeight: 'bold'}}>必ずメールアドレスの検証</span>を行ってください<br/><br/>

                  検証を行わない場合、パスワードを忘れた場合に、<br/>
                  パスワード再設定ができません
                </DialogContentText>

                <ValidatorForm
                  onSubmit={() => verifyEmail()}
                  onError={
                    errors => {
                      console.log(errors);
                      enqueueSnackbar('任意の項目が不正です', {variant : 'warning'});
                    }
                  }
                >
                  <TextValidator
                    className={classes.textField}
                    name="code"
                    label="検証コード"
                    value={code}
                    margin="normal"
                    onChange={(e) => setCode(e.target.value)}
                    required={true}
                    validators={['required']}
                    errorMessages={['必須項目を入力してください']}
                    type="text"
                    style={{marginTop: 30}}
                  />

                  <DialogActions>
                    <Button type="submit" variant="contained" color="primary" className={classes.button} style={{float: 'right', marginTop:20}}>
                      検証
                    </Button>
                  </DialogActions>
                </ValidatorForm>

                  <DialogActions>
                    <Button color="primary" style={{marginTop:20}}　onClick={handleOpenConfirm}>
                      メールアドレスを検証せずに閉じる
                    </Button>
                  </DialogActions>

              </DialogContent>
            </Dialog>

            <Dialog
              open={openConfirm}
              keepMounted
              onClose={handleCloseConfirm}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle id="alert-dialog-slide-title">注意</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                メールアドレスの検証が完了していないとパスワードを忘れた場合に、<br/>
                <span style={{fontWeight: 'bold'}}>パスワードの再設定ができません</span><br/><br/>

                変更するメールアドレスを間違えて入力した場合は、<br/>
                必ずログアウト前に有効なメールアドレスに再設定してください<br/><br/>

                後で検証を行う場合は、「メールアドレス検証コードを再送する」<br/>
                のボタンから検証を完了させてください
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseConfirmFinish} color="primary">
                  理解して閉じる
                </Button>
                <Button onClick={handleCloseConfirm} color="primary"　variant="contained">
                  メールアドレス検証に戻る
                </Button>

              </DialogActions>
            </Dialog>

          </Paper>
        </Grid>


        <Grid item xs={12}>
          <Paper className={classes.paper} elevation={1}>
            <Grid container spacing={4} className={classes.container}>

              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  パスワード変更
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <ValidatorForm
                  onSubmit={() => changePasswoerd()}
                  onError={
                    errors => {
                      console.log(errors);
                      enqueueSnackbar('入力エラーがあります。確認してください。', {variant : 'warning'});
                    }
                  }
                  className={classes.validatorForm}
                >
                  <Grid container spacing={4} className={classes.container}>

                    <Grid item xs={6}>
                      <TextValidator
                        className={classes.textField}
                        name="oldPassword"
                        label="現在のパスワード"
                        value={oldPassword}
                        margin="normal"
                        onChange={(e) => setOldPassword(e.target.value)}
                        required={true}
                        validators={['required']}
                        errorMessages={['必須項目を入力してください']}
                        type="password"
                      />
                    </Grid>
                    <Grid item xs={6}/>

                    <Grid item xs={12}/>
                    <Grid item xs={6}>
                      <TextValidator
                        className={classes.textField}
                        name="newPassword"
                        label="新しいパスワード"
                        value={newPassword}
                        margin="normal"
                        onChange={(e) => setNewPassword(e.target.value)}
                        required={true}
                        validators={['required']}
                        errorMessages={['必須項目を入力してください']}
                        type="password"
                      />
                    </Grid>
                    <Grid item xs={6}/>

                    <Grid item xs={6}>
                      <TextValidator
                        className={classes.textField}
                        name="retypeNewPassword"
                        label="新しいパスワード(確認)"
                        value={retypeNewPassword}
                        margin="normal"
                        onChange={(e) => setRetypeNewPassword(e.target.value)}
                        required={true}
                        validators={['required', 'isPasswordMatch']}
                        type="password"
                        errorMessages={['必須項目を入力してください', '新しいパスワードが一致しません']}
                      />
                    </Grid>
                    <Grid item xs={6}/>

                    <Grid item xs={6}/>
                    <Grid item xs={2}>
                      <Button type="submit" variant="contained" color="primary" className={classes.button} disabled={isSampleTenant}>
                        変更
                      </Button>
                    </Grid>

                  </Grid>
                </ValidatorForm>
              </Grid>

            </Grid>
          </Paper>
        </Grid>


      </Grid>
    </Fragment>
  );
}
