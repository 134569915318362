import { BrowserRouter} from "react-router-dom";

import Main from './Main';

export default function App(props) {

    return(
      <BrowserRouter>
        <Main/>
      </BrowserRouter>
    )
}
