import React, { useEffect, useState, useCallback, Fragment } from 'react';
import { useParams } from "react-router";

import { API } from 'aws-amplify';
import moment from 'moment';
import { useForm } from "react-hook-form";

export default function CustomerPage(props) {

  const { pathParam } = useParams()

  const [storedReservation,setStoredReservation] = useState(null)
  const [isTypedPostNum, setIsTypedPostNum] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isSubmitError, setIsSubmitError] = useState(false);
  const [isChecking, setIsChecking] = useState(false);
  const [isNoReservation, setIsNoReservation] = useState(false);
  // これ以外の値はreact-hook-formで管理

  const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      lastNameKana: '',
      firstNameKana: '',
      lastName: '',
      firstName: '',
      postNum: '',
      addressPrefectures: '',
      addressCity: '',
      addressOther: '',
      addressOther2: '',
      tel1: '',
      email: '',
    }
  })

  // 開発用
  // useEffect(() => {
  //   setValue('lastNameKana', 'トキタ')
  //   setValue('firstNameKana', 'アキノリ')
  //   setValue('lastName', '時田')
  //   setValue('firstName', '明典')
  //   setValue('postNum', '3540024')
  //   setValue('addressPrefectures', '埼玉県')
  //   setValue('addressCity', '富士見市')
  //   setValue('addressOther', '鶴瀬東2丁目18-14')
  //   setValue('addressOther2', 'クリオ鶴瀬壱番館 306')
  //   setValue('tel1', '09035782830')
  //   setValue('email', 'akinori.tokita@gmail.com')
  // }, [setValue]);


  // 予約情報の取得
  useEffect(() => {

    // 部屋タイプとjalanIdの取得
    let apiName = 'MyAPIGatewayAPI'; // replace this with your api name.
    let path = '/reservations/pre/' + pathParam; //replace this with the path you have configured on your API

    API.get(apiName, path).then(response => {
      console.log(response);
      setStoredReservation(response.reservation)
    }).catch(error => {
      console.log(error);
      setIsNoReservation(true)
    })

  },[pathParam])

  const getAddress = useCallback((postNum) => {
    let apiName = 'MyAPIGatewayAPI'; // replace this with your api name.
    let path = '/address' ; //replace this with the path you have configured on your API
    const myInit = { // OPTIONAL
      queryStringParameters: {  // OPTIONAL
        zipcode: postNum
      },
    };

    API.get(apiName, path, myInit).then(response => {
      if(response.status === 200){
        console.log(response.results[0]);

        setValue('addressPrefectures', response.results[0].address1, { shouldValidate: true })
        setValue('addressCity', response.results[0].address2, { shouldValidate: true })
        setValue('addressOther', response.results[0].address3, { shouldValidate: true })

      }else{
        console.log(response.message)
      }
    }).catch(error => {
      console.log(error);
    })
  }, [setValue]);

  const typedPostNum = (value) => {
    setIsTypedPostNum(true)
  }

  // inputPostNumが変更されたときに住所を検索する
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if(isTypedPostNum){
        if(watch('postNum').length === 7){
          getAddress(watch('postNum'))
        }
        setIsTypedPostNum(false)
      }
    }, 1000)
    return () => clearTimeout(timeoutId);
  }, [getAddress, isTypedPostNum, watch]);



  const handleOnSubmit = (values) => {
    if(isChecking){
      setIsSending(true)
      console.log(values)

      let reservationInfo = {
        lastNameKana: values.lastNameKana,
        firstNameKana: values.firstNameKana,
        lastName: values.lastName,
        firstName: values.firstName,
        postNum: values.postNum,
        addressPrefectures: values.addressPrefectures,
        addressCity: values.addressCity,
        addressOther: values.addressOther + ' ' + values.addressOther2,
        tel1: values.tel1,
        email: values.email,
        startDate: storedReservation.startDate,
      }


      let apiName = 'MyAPIGatewayAPI'
      let path = '/reservations/pre/' + pathParam;

      let body = {
        ...reservationInfo
      }

      let myInit = {
        body: body
      }

      API.put(apiName, path, myInit).then(async (response) => {

        console.log(response)
        let storedReservationAfter = JSON.parse(JSON.stringify(storedReservation))
        storedReservationAfter.reservationStatus = 'active_reserved'
        setStoredReservation(storedReservationAfter)
        setIsSubmitError(false)
        setIsSending(true)
        window.scrollTo( 0, 0);

      }).catch(error => {

        console.log(error)
        setIsSubmitError(true)
        setIsSending(false)
      });
    }else{
      setIsChecking(true)
			window.scrollTo( 0, 0);
    }
  }

  const handleOnError = (errors) => {
    console.log(errors)
  }



  const getRoomTypePlanTotal = (roomTypePlan) => {

    let total = 0

    roomTypePlan.storedPlans.map(plan => {
      total = total + (Number(plan.unitPrice) * Number(plan.amount))
      return null
    })

    total = total * Number(roomTypePlan.dateNum)

    return total

  }

  const getTotal = () => {
    let plansTotal = 0

    storedReservation.roomTypePlans.map(storedRoomTypePlan => {
      storedRoomTypePlan.storedPlans.map(storedPlan => {
        plansTotal = plansTotal + (Number(storedPlan.unitPrice) * Number(storedPlan.amount) * Number(storedRoomTypePlan.dateNum))
        return null
      })
      return null
    })

    let additionalChargeTotal = 0
    if(
      storedReservation.charges &&
      storedReservation.charges.additionalCharges &&
      storedReservation.charges.additionalCharges.additionalCharge &&
      storedReservation.charges.additionalCharges.additionalCharge.price
    ){
      additionalChargeTotal = Number(storedReservation.charges.additionalCharges.additionalCharge.price)
    }

    let discountTotal = 0

    if(
      storedReservation.charges &&
      storedReservation.charges.discounts &&
      storedReservation.charges.discounts.discount &&
      storedReservation.charges.discounts.discount.price
    ){
      discountTotal = Number(storedReservation.charges.discounts.discount.price)
    }

    let total = 0

    total = plansTotal + additionalChargeTotal - discountTotal

    return total
  }


  if(storedReservation && storedReservation.reservationStatus === 'active_reserved_incomplete'){
    return (
      <div className="form-v4-page-content">
        <div className="form-v4-content">
          <div className="form-left">

            <p className="check-message" style={{display: isChecking ? '' : 'none'}}>入力内容をお確かめの上、「送信する」を押してください。</p>

            <h2>ご予約内容</h2>

            <p className="tenant-name">
              {storedReservation.tenantName}
            </p>

            <p className="text" style={{display: isChecking ? 'none' : ''}}>
              ご利用ありがとうございます。
            </p>
            <p className="text" style={{display: isChecking ? 'none' : ''}}>
              ご予約内容をご確認のうえ、お客様情報をご入力して予約手続きを完了させてください。
            </p>

            {storedReservation.roomTypePlans.map(roomTypePlan => (
              <Fragment key={roomTypePlan.storedRoomTypePlanId}>
                <p className="date" >
                  {moment(roomTypePlan.startDate).format('YYYY年M月D日')} から {roomTypePlan.dateNum}泊
                </p>

                <div className="plan">

                  {roomTypePlan.storedPlans.map(plan => (
                    <Fragment key={plan.storedPlanId}>
                      <p  className="plan-name">
                        {plan.planNameForGuest}
                      </p>
                      <p className="plan-price">
                        {Number(plan.unitPrice).toLocaleString()}円 x {plan.amount}名様 x {roomTypePlan.dateNum}泊
                      </p>
                    </Fragment>
                  ))}

                  <p className="room-type-plan-total">
                    小計: {getRoomTypePlanTotal(roomTypePlan).toLocaleString()}円
                  </p>

                </div>
              </Fragment>
            ))}

            <div className="plan">
              <p
                className="plan-price"
                sytle={{
                  display: storedReservation.charges &&
                  storedReservation.charges.discounts &&
                  storedReservation.charges.discounts.discount &&
                  storedReservation.charges.discounts.discount.price
                  ? '' : 'none'
                }}>
                割引額: {Number(storedReservation.charges.discounts.discount.price).toLocaleString()}円
              </p>
              <p
                className="plan-price"
                sytle={{
                  display: storedReservation.charges &&
                  storedReservation.charges.additionalCharges &&
                  storedReservation.charges.additionalCharges.additionalCharge &&
                  storedReservation.charges.additionalCharges.additionalCharge.price
                  ? '' : 'none'
                }}>
                追加料金: {Number(storedReservation.charges.additionalCharges.additionalCharge.price).toLocaleString()}円
              </p>
            </div>


            <div className="plan">
              <p className="plan-total">
                合計: {getTotal().toLocaleString()}円
              </p>
              <p className="plan-price">
                (内消費税: {Math.floor(getTotal() * storedReservation.charges.taxRate / (1 + storedReservation.charges.taxRate)).toLocaleString()}円)
              </p>
            </div>

          </div>

          <form className="form-detail" id="myform" onSubmit={handleSubmit(handleOnSubmit, handleOnError)}>
            <h2>お客様情報 {isChecking ? '確認' : ''}</h2>

            <div className="checking" style={{display: isChecking ? '' : 'none'}}>
              入力内容をお確かめの上、「送信する」を押してください。
            </div>

            <h3>お名前</h3>
            <div className="form-group">
              <div className="form-row form-row-1">
                <label htmlFor="lastNameKana">セイ *</label>
                <input
                  type="text"
                  name="lastNameKana"
                  id="lastNameKana"
                  className={errors.lastNameKana?.message ? "input-text error" : "input-text"}
                  {...register('lastNameKana', {
                    required: '必須項目にご入力ください',
                    pattern: {
                      value: /^[ァ-ンヴー]+$/,
                      message: 'カタカナで入力してください'
                    }
                  })}
                  disabled={isChecking}
                />
                <label className="error" htmlFor="lastNameKana">{errors.lastNameKana?.message}</label>
              </div>
              <div className="form-row form-row-1">
                <label htmlFor="firstNameKana">メイ *</label>
                <input
                  type="text"
                  name="firstNameKana"
                  id="firstNameKana"
                  className={errors.firstNameKana?.message ? "input-text error" : "input-text"}
                  {...register('firstNameKana', {
                    required: '必須項目にご入力ください',
                    pattern: {
                      value: /^[ァ-ンヴー]+$/,
                      message: 'カタカナで入力してください'
                    }
                  })}
                  disabled={isChecking}
                />
                <label className="error" htmlFor="firstNameKana">{errors.firstNameKana?.message}</label>
              </div>
            </div>
            <div className="form-group">
              <div className="form-row form-row-1">
                <label htmlFor="lastName">姓 *</label>
                <input
                  type="text"
                  name="lastName"
                  id="lastName"
                  className={errors.lastName?.message ? "input-text error" : "input-text"}
                  {...register('lastName', {
                    required: '必須項目にご入力ください',
                  })}
                  disabled={isChecking}
                />
                <label className="error" htmlFor="lastName">{errors.lastName?.message}</label>
              </div>
              <div className="form-row form-row-1">
                <label htmlFor="firstName">名 *</label>
                <input
                  type="text"
                  name="firstName"
                  id="firstName"
                  className={errors.firstName?.message ? "input-text error" : "input-text"}
                  {...register('firstName', {
                    required: '必須項目にご入力ください',
                  })}
                  disabled={isChecking}
                />
                <label className="error" htmlFor="firstName">{errors.firstName?.message}</label>
              </div>
            </div>

            <h3>ご住所</h3>

            <div className="form-group">
              <div className="form-row form-row-1">
                <label htmlFor="postNum">郵便番号 *<small className="sub">(ハイフン無し・数字のみ)</small></label>
                <input
                  type="text"
                  name="postNum"
                  id="postNum"
                  className={errors.postNum?.message ? "input-text error" : "input-text"}
                  {...register('postNum', {
                    required: '必須項目にご入力ください',
                    pattern: {
                      value: /^\d{7}$/,
                      message: 'ハイフン無しの数字のみで入力してください'
                    },
                    onChange: (e) => typedPostNum(e)
                  })}
                  disabled={isChecking}
                />
                <label className="error" htmlFor="postNum">{errors.postNum?.message}</label>
              </div>
            </div>
            <div className="form-group">
              <div className="form-row form-row-1">
                <label htmlFor="addressPrefectures">都道府県 *</label>
                <input
                  type="text"
                  name="addressPrefectures"
                  id="addressPrefectures"
                  className={errors.addressPrefectures?.message ? "input-text error" : "input-text"}
                  {...register('addressPrefectures', {
                    required: '必須項目にご入力ください',
                  })}
                  disabled={isChecking}
                />
                <label className="error" htmlFor="addressPrefectures">{errors.addressPrefectures?.message}</label>
              </div>
              <div className="form-row form-row-1">
                <label htmlFor="addressCity">市区町村 *</label>
                <input
                  type="text"
                  name="addressCity"
                  id="addressCity"
                  className={errors.addressCity?.message ? "input-text error" : "input-text"}
                  {...register('addressCity', {
                    required: '必須項目にご入力ください',
                  })}
                  disabled={isChecking}
                />
                <label className="error" htmlFor="addressCity">{errors.addressCity?.message}</label>
              </div>
            </div>
            <div className="form-row">
              <label htmlFor="addressOther">町名・番地 *</label>
              <input
                type="text"
                name="addressOther"
                id="addressOther"
                className={errors.addressOther?.message ? "input-text error" : "input-text"}
                {...register('addressOther', {
                  required: '必須項目にご入力ください',
                })}
                disabled={isChecking}
              />
              <label className="error" htmlFor="addressOther">{errors.addressOther?.message}</label>
            </div>
            <div className="form-row">
              <label htmlFor="addressOther2">建物名・お部屋番号等</label>
              <input
                type="text"
                name="addressOther2"
                id="addressOther2"
                className={errors.addressOther2?.message ? "input-text error" : "input-text"}
                {...register('addressOther2')}
                disabled={isChecking}
              />
            </div>

            <h3>ご連絡先</h3>

            <div className="form-row">
              <label htmlFor="tel1">電話番号 *<small className="sub">(ハイフン無し・数字のみ)</small></label>
              <input
                type="tel"
                name="tel1"
                id="tel1"
                className={errors.tel1?.message ? "input-text error" : "input-text"}
                {...register('tel1', {
                  required: '必須項目にご入力ください',
                  pattern: {
                    value: /^(0{1}\d{9,10})$/,
                    message: '正しい電話番号をご入力ください'
                  }
                })}
                disabled={isChecking}
              />
              <label className="error" htmlFor="tel1">{errors.tel1?.message}</label>
            </div>

            <div className="form-row">
              <label htmlFor="your_email">Emailアドレス *</label>
              <input
                type="email"
                name="email"
                id="email"
                className={errors.email?.message ? "input-text error" : "input-text"}
                {...register('email', {
                  required: '必須項目にご入力ください',
                  pattern: {
                    value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                    message: '正しいメールアドレスをご入力ください'
                  }
                })}
                disabled={isChecking}
              />
              <label className="error" htmlFor="email">{errors.email?.message}</label>
            </div>

            <div className="checking" style={{display: isChecking ? '' : 'none'}}>
              入力内容をお確かめの上、「送信する」を押してください。
            </div>

            <div className="form-row-last">
              <input type="button" name="back" className="back" value='戻る' style={{display: isChecking ? '' : 'none'}} onClick={() => setIsChecking(false)}/>
              <input type="submit" name="register" className="register" value={isChecking ? '送信する' : '確認する'} disabled={isSending}/>
            </div>
            <div className="submit-error" style={{display: isSubmitError ? '' : 'none'}}>
              エラーが発生しました。もう一度お試しください。
            </div>
          </form>
        </div>
      </div>
    );
  }else if(storedReservation && storedReservation.reservationStatus === 'active_reserved'){
    return (
      <div className="form-v4-page-content">
        <div className="form-v4-content">
          <div className="form-left2">

            <h2>ご予約完了</h2>

            <p className="tenant-name">
              {storedReservation.tenantName}
            </p>
            <p className="text">
              ご予約ありがとうございます。<br/>
              ご予約内容は以下の通りです。<br/>
            </p>
            <p className="text">
              どうぞお気をつけておこしくださいませ。
            </p>

            {storedReservation.roomTypePlans.map(roomTypePlan => (
              <Fragment key={roomTypePlan.storedRoomTypePlanId}>
                <p className="date" >
                  {moment(roomTypePlan.startDate).format('YYYY年M月D日')} から {roomTypePlan.dateNum}泊
                </p>

                <div className="plan">

                  {roomTypePlan.storedPlans.map(plan => (
                    <Fragment key={plan.storedPlanId}>
                      <p  className="plan-name">
                        {plan.planNameForGuest}
                      </p>
                      <p className="plan-price">
                        {Number(plan.unitPrice).toLocaleString()}円 x {plan.amount}名様 x {roomTypePlan.dateNum}泊
                      </p>
                    </Fragment>
                  ))}

                  <p className="room-type-plan-total">
                    小計: {getRoomTypePlanTotal(roomTypePlan).toLocaleString()}円
                  </p>

                </div>
              </Fragment>
            ))}

            <div className="plan">
              <p
                className="plan-price"
                sytle={{
                  display: storedReservation.charges &&
                  storedReservation.charges.discounts &&
                  storedReservation.charges.discounts.discount &&
                  storedReservation.charges.discounts.discount.price
                  ? '' : 'none'
                }}>
                割引額: {Number(storedReservation.charges.discounts.discount.price).toLocaleString()}円
              </p>
              <p
                className="plan-price"
                sytle={{
                  display: storedReservation.charges &&
                  storedReservation.charges.additionalCharges &&
                  storedReservation.charges.additionalCharges.additionalCharge &&
                  storedReservation.charges.additionalCharges.additionalCharge.price
                  ? '' : 'none'
                }}>
                追加料金: {Number(storedReservation.charges.additionalCharges.additionalCharge.price).toLocaleString()}円
              </p>
            </div>


            <div className="plan">
              <p className="plan-total">
                合計: {getTotal().toLocaleString()}円
              </p>
              <p className="plan-price">
                (内消費税: {Math.floor(getTotal() * storedReservation.charges.taxRate / (1 + storedReservation.charges.taxRate)).toLocaleString()}円)
              </p>
            </div>

          </div>

          <div className="form-detail" id="myform" onSubmit={handleSubmit(handleOnSubmit, handleOnError)}>
            <h2>ご案内</h2>
            
            {/* ふんわりこん向けにコテージの場合だけご案内を別に表示させる */}

            {storedReservation.guidanceCottage.map((item, i) => (
              storedReservation.roomTypePlans[0].roomTypeId === "rt-d1ae6880-c35a-43c0-8bb0-6bd893ad0ac2" &&
              <Fragment key={i}>
                <h4>{item.title}</h4>
                <p style={{whiteSpace: 'pre-wrap'}}>
                  {item.text}
                </p>
              </Fragment>
            ))}

            {storedReservation.guidanceCottage.map((item, i) => (
              storedReservation.roomTypePlans[0].roomTypeId === "rt-1bc250b5-5f8e-44ae-9f84-11107fecd3fc" &&
              <Fragment key={i}>
                <h4>{item.title}</h4>
                <p style={{whiteSpace: 'pre-wrap'}}>
                  {item.text}
                </p>
              </Fragment>
            ))}

            {storedReservation.guidance.map((item, i) => (
              storedReservation.roomTypePlans[0].roomTypeId !== ("rt-d1ae6880-c35a-43c0-8bb0-6bd893ad0ac2" && "rt-1bc250b5-5f8e-44ae-9f84-11107fecd3fc") &&
              <Fragment key={i}>
                <h4>{item.title}</h4>
                <p style={{whiteSpace: 'pre-wrap'}}>
                  {item.text}
                </p>
              </Fragment>
            ))}

          </div>
        </div>
      </div>
    )
  }else{
    if(isNoReservation){
      return (
        <div className="form-v4-content-no-reservation">
          <h2>URLが無効です</h2>
          <div className="please">ご予約の施設にご確認ください。</div>
        </div>
      )
    }else{
      return (
        <div className="form-v4-content-no-reservation-background">
          <div className="form-v4-content-loader">
            Loading...
          </div>
        </div>
      )
    }
  }
};
