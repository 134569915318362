import React, { Fragment } from 'react';
import moment from 'moment';

const GUEST_TYPE = {
  adult: "大人",
  child: "子供",
  toddler: "幼児",
}

const BED = {
  true: 'ベッド有',
  false: 'ベッド無'
}

const DINNER = {
  true: '夕食有',
  false: '夕食無'
}

const BREAKFAST = {
  true: '朝食有',
  false: '朝食無'
}

const HONORIFIC_TITLE = {
  no: '',
  sama: '様',
  onchu: '御中'
}

export default class Receipt extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      reservationsToPrint: []
    };
  }

  componentDidUpdate(prevProps){

    // console.log(this.props)

    if(this.props.reservations !== prevProps.reservations){
      this.setState({
        reservationsToPrint: this.props.reservations
      },() => {
        // console.log(this.state.reservationsToPrint)
      })
    }
  }



  getDateRange = (overnights) => {

    let dates = overnights.map(overnight => {
      return overnight.date
    })

    dates.sort((a, b) => moment(a).diff(moment(b)));

    return {
      start: moment(dates[0]).format('YYYY-MM-DD'),
      end: moment(dates[dates.length -1]).add(1, 'days').format('YYYY-MM-DD')
    }
  }


  getReservationTotal = (reservation) => {
    let plansTotal = 0

    if(reservation.methodOfPaymentPlans === 'jalan'){
      return plansTotal
    }

    reservation.roomTypePlans.map(roomTypePlan => {
      roomTypePlan.storedPlans.map(storedPlan => {
        plansTotal += storedPlan.amount * storedPlan.unitPrice
        return null
      })
      plansTotal = plansTotal * Number(roomTypePlan.dateNum)
      return null
    })


    if(
      reservation.charges &&
      reservation.charges.additionalCharges &&
      reservation.charges.additionalCharges.additionalCharge &&
      reservation.charges.additionalCharges.additionalCharge.price !== 0
    ){
      plansTotal += reservation.charges.additionalCharges.additionalCharge.price
    }

    if(
      reservation.charges &&
      reservation.charges.discounts &&
      reservation.charges.discounts.discount &&
      reservation.charges.discounts.discount.price !== 0
    ){
      plansTotal -= reservation.charges.discounts.discount.price
    }

    if(
      reservation.charges &&
      reservation.charges.discounts &&
      reservation.charges.discounts.jalanPoint &&
      reservation.charges.discounts.jalanPoint.price !== 0
    ){
      plansTotal -= reservation.charges.discounts.jalanPoint.price
    }

    if(
      reservation.charges &&
      reservation.charges.discounts &&
      reservation.charges.discounts.jalanCoupon &&
      reservation.charges.discounts.jalanCoupon.price !== 0
    ){
      plansTotal -= reservation.charges.discounts.jalanCoupon.price
    }

    if(
      reservation.charges &&
      reservation.charges.discounts &&
      reservation.charges.discounts.jalanSubsidy &&
      reservation.charges.discounts.jalanSubsidy.price !== 0
    ){
      plansTotal -= reservation.charges.discounts.jalanSubsidy.price
    }

    return plansTotal
  }




  getReservationTotalJalanPaid = (reservation) => {

    let reservationAfter = JSON.parse(JSON.stringify(reservation))

    reservationAfter.methodOfPaymentPlans = ''

    let plansTotalJalanPaid = this.getReservationTotal(reservationAfter)

    return plansTotalJalanPaid
  }


  getReservationTotalInnerTax = (reservation) => {
    if(reservation.methodOfPaymentPlans === 'jalan'){
      return 0
    }

    let taxRate = reservation.charges.taxRate

    return Math.floor(this.getReservationTotal(reservation) * taxRate / (1 + taxRate))
  }


  getOrdersTotal = (reservation) => {
    let ordersTotal = 0

    reservation.orders.map(order => {
      ordersTotal += order.unitPrice * order.amount
      return null
    })

    return ordersTotal
  }



  getOrdersTotalInnerTax = (reservation) => {
    let ordersTotalInnerTax = 0

    let taxRates = []

    reservation.orders.map(order => {
      taxRates.push(order.taxRate)
      return null
    })

    taxRates = Array.from(new Set(taxRates))

    taxRates.map(taxRate => {
      let totalThisRate = 0
      reservation.orders.map(order => {
        if(taxRate === order.taxRate){
          totalThisRate += order.unitPrice * order.amount
        }
        return null
      })
      ordersTotalInnerTax += Math.floor(totalThisRate * taxRate / (1 + taxRate))
      return null
    })

    return ordersTotalInnerTax
  }



  getIsChargesExist = (reservation) => {

    if(
      reservation.charges &&
      reservation.charges.additionalCharges &&
      reservation.charges.additionalCharges.additionalCharge &&
      reservation.charges.additionalCharges.additionalCharge.price !== 0
    ){
      return true
    }

    if(
      reservation.charges &&
      reservation.charges.discounts &&
      reservation.charges.discounts.discount &&
      reservation.charges.discounts.discount.price !== 0
    ){
      return true
    }

    if(
      reservation.charges &&
      reservation.charges.discounts &&
      reservation.charges.discounts.jalanPoint &&
      reservation.charges.discounts.jalanPoint.price !== 0
    ){
      return true
    }

    if(
      reservation.charges &&
      reservation.charges.discounts &&
      reservation.charges.discounts.jalanCoupon &&
      reservation.charges.discounts.jalanCoupon.price !== 0
    ){
      return true
    }

    if(
      reservation.charges &&
      reservation.charges.discounts &&
      reservation.charges.discounts.jalanSubsidy &&
      reservation.charges.discounts.jalanSubsidy.price !== 0
    ){
      return true
    }

    return false
  }


  returnCompornent = (extraTitle) => {
    if(this.state.reservationsToPrint.length > 0){

      return (
        <Fragment>
          {this.state.reservationsToPrint.map(reservation => {


            return (

              <div className="body" key={reservation.reservationId}>

                <link rel="stylesheet" href="../css/Receipts.css" />
                <div className="clearfix">
                  <div id="logo">
                    {/* <img src="../images/funwarikon.png" alt="logo" /> */}
                  </div>
                  <h1>
                    {reservation.inputFormType === 'receipt' && "領収書 兼 ご利用明細書" + extraTitle}
                    {reservation.inputFormType === 'detailedStatement' && "ご利用明細書" + extraTitle}
                  </h1>
                  <div id="company">
                    <div>{this.props.tenantInfo.tenantName}</div>
                    <div>
                      〒{this.props.tenantInfo.postNum}
                      <br/>
                      {this.props.tenantInfo.address1}
                      <br/>
                      {this.props.tenantInfo.address2}
                    </div>
                    <div id="tel">tel: {this.props.tenantInfo.tel}</div>
                    <div>mail: {this.props.tenantInfo.email}</div>
                  </div>

                  <div id="project">
                    <div id="name">
                      {(reservation.inputFormType === 'receipt' && reservation.inputReceiptAddress !== '') &&　<div>{reservation.inputReceiptAddress}</div>}
                      {(reservation.inputFormType === 'receipt' && reservation.inputReceiptAddress === '') &&　<div style={{marginLeft: 300}}>{HONORIFIC_TITLE[reservation.inputHonorificTitle]}</div>}
                      {reservation.inputFormType !== 'receipt' &&　<div><span>御芳名</span>{reservation.lastName} {reservation.firstName} 様</div>}
                    </div>
                    <div id="total">
                      <div>
                        <span>
                        {reservation.inputFormType === 'receipt' && '金額'}
                        {reservation.inputFormType === 'detailedStatement' && '合計金額'}
                        </span>
                        ¥ {(this.getReservationTotal(reservation) + this.getOrdersTotal(reservation)).toLocaleString()}ー
                      </div>
                    </div>
                    <div id="tadashi">
                      {reservation.inputFormType === 'receipt' && '但し、ご宿泊代金およびご飲食費として、上記の金額正に領収いたしました。'}
                      {(reservation.inputFormType === 'receipt' &&　reservation.inputMethodOfPayment === 'card') ? "【クレジットカード利用】" : ""}
                      {(reservation.inputFormType === 'receipt' &&　reservation.inputMethodOfPayment === 'eMoney') ? "【電子マネー利用】" : ""}
                      <br/>
                      {reservation.inputFormType === 'receipt' && reservation.inputReceiptNote}
                    </div>
                    <div><span>発行日</span>{moment(reservation.inputIssueDate).format('YYYY年M月D日')}</div>
                    <div id="checkin"><span>チェックイン日</span>{moment(this.getDateRange(reservation.overnights).start).format('YYYY年M月D日')}</div>
                    <div><span>チェックアウト日</span>{moment(this.getDateRange(reservation.overnights).end).format('YYYY年M月D日')}</div>
                  </div>
                </div>


                <main>
                  <table>
                    <thead>
                      <tr>
                        <th colSpan="2"></th>
                        <th className="form-number" colSpan="3">
                          {reservation.inputFormType === 'receipt' && '領収書No. ' + reservation.storedFormNumber}
                          {reservation.inputFormType === 'detailedStatement' && '明細書No. ' + reservation.storedFormNumber}
                        </th>
                      </tr>
                      <tr>
                        <th className="desc">ご利用明細</th>
                        <th>単価</th>
                        <th>数量</th>
                        <th>税率</th>
                        <th>小計</th>
                      </tr>
                    </thead>

                    <tbody>

                      {reservation.roomTypePlans.map(roomTypePlan => {
                          return (
                            <Fragment key={roomTypePlan.storedRoomTypePlanId}>
                              {roomTypePlan.storedPlans.map(storedPlan => {
                                  return (
                                    <tr key={storedPlan.storedPlanId}>
                                      <td className="desc" style={{whiteSpace: 'normal', wordWrap: 'break-word'}}>
                                        {!storedPlan.planNameForGuest && <div>{roomTypePlan.roomTypeName} { GUEST_TYPE[storedPlan.guestType]} {BED[storedPlan.bed ? 'true' : 'false']} {DINNER[storedPlan.dinner ? 'true' : 'false']} {BREAKFAST[storedPlan.breakfast ? 'true' : 'false']} ご宿泊料</div>}
                                        {storedPlan.planNameForGuest && storedPlan.planNameForGuest + ' ご宿泊料'}
                                      </td>
                                      <td className="other">¥ {storedPlan.unitPrice.toLocaleString()}</td>
                                      <td className="other">{storedPlan.amount}</td>
                                      <td className="other">{storedPlan.taxRate * 100} %</td>
                                      <td className="other">{(storedPlan.unitPrice * storedPlan.amount).toLocaleString()}</td>
                                    </tr>
                                  )
                              })}
                            </Fragment>
                          )
                      })}



                      {
                        this.getIsChargesExist(reservation) &&
                        (
                          <tr>
                            <td align="center" colSpan="5"></td>
                          </tr>
                        )
                      }


                      {
                        reservation.charges &&
                        reservation.charges.additionalCharges &&
                        reservation.charges.additionalCharges.additionalCharge &&
                        reservation.charges.additionalCharges.additionalCharge.price !== 0 &&
                        (
                          <tr>
                            <td className="desc" style={{whiteSpace: 'normal', wordWrap: 'break-word'}}>{reservation.charges.additionalCharges.additionalCharge.name}</td>
                            <td className="other"></td>
                            <td className="other"></td>
                            <td className="other"></td>
                            <td className="other">¥ {reservation.charges.additionalCharges.additionalCharge.price.toLocaleString()}</td>
                          </tr>
                        )
                      }


                      {
                        reservation.charges &&
                        reservation.charges.discounts &&
                        reservation.charges.discounts.discount &&
                        reservation.charges.discounts.discount.price !== 0 &&
                        (
                          <tr>
                            <td className="desc" style={{whiteSpace: 'normal', wordWrap: 'break-word'}}>{reservation.charges.discounts.discount.name}</td>
                            <td className="other"></td>
                            <td className="other"></td>
                            <td className="other"></td>
                            <td className="other">- ¥ {reservation.charges.discounts.discount.price.toLocaleString()}</td>
                          </tr>
                        )
                      }


                      {
                        reservation.charges &&
                        reservation.charges.discounts &&
                        reservation.charges.discounts.jalanPoint &&
                        reservation.charges.discounts.jalanPoint.price !== 0 &&
                        (
                          <tr>
                            <td className="desc" style={{whiteSpace: 'normal', wordWrap: 'break-word'}}>{reservation.charges.discounts.jalanPoint.name}</td>
                            <td className="other"></td>
                            <td className="other"></td>
                            <td className="other"></td>
                            <td className="other">- ¥ {reservation.charges.discounts.jalanPoint.price.toLocaleString()}</td>
                          </tr>
                        )
                      }

                      {
                        reservation.charges &&
                        reservation.charges.discounts &&
                        reservation.charges.discounts.jalanCoupon &&
                        reservation.charges.discounts.jalanCoupon.price !== 0 &&
                        (
                          <tr>
                            <td className="desc" style={{whiteSpace: 'normal', wordWrap: 'break-word'}}>{reservation.charges.discounts.jalanCoupon.name}</td>
                            <td className="other"></td>
                            <td className="other"></td>
                            <td className="other"></td>
                            <td className="other">- ¥ {reservation.charges.discounts.jalanCoupon.price.toLocaleString()}</td>
                          </tr>
                        )
                      }

                      {
                        reservation.charges &&
                        reservation.charges.discounts &&
                        reservation.charges.discounts.jalanSubsidy &&
                        reservation.charges.discounts.jalanSubsidy.price !== 0 &&
                        (
                          <tr>
                            <td className="desc" style={{whiteSpace: 'normal', wordWrap: 'break-word'}}>{reservation.charges.discounts.jalanSubsidy.name}</td>
                            <td className="other"></td>
                            <td className="other"></td>
                            <td className="other"></td>
                            <td className="other">- ¥ {reservation.charges.discounts.jalanSubsidy.price.toLocaleString()}</td>
                          </tr>
                        )
                      }


                      {
                        reservation.methodOfPaymentPlans === 'jalan' &&
                        (
                          <Fragment>
                            <tr>
                              <td align="center" colSpan="5"></td>
                            </tr>

                            <tr>
                              <td className="desc" style={{whiteSpace: 'normal', wordWrap: 'break-word'}}>じゃらんお支払済み金額</td>
                              <td className="other"></td>
                              <td className="other"></td>
                              <td className="other"></td>
                              <td className="other">- ¥ {this.getReservationTotalJalanPaid(reservation).toLocaleString()}</td>
                            </tr>
                          </Fragment>
                        )
                      }


                      <tr>
                        <td align="center" colSpan="5"></td>
                      </tr>


                      <tr>
                        <td className="other"></td>
                        <td className="other"></td>
                        <td className="other"></td>
                        <td className="other" style={{whiteSpace: 'normal', wordWrap: 'break-word'}}>宿泊料金小計</td>
                        <td className="other">¥ {this.getReservationTotal(reservation).toLocaleString()}</td>
                      </tr>
                      <tr>
                        <td className="other"></td>
                        <td className="other"></td>
                        <td className="other"></td>
                        <td className="other" style={{whiteSpace: 'normal', wordWrap: 'break-word'}}>( 内消費税等</td>
                        <td className="other">¥ {this.getReservationTotalInnerTax(reservation).toLocaleString()} )</td>
                      </tr>


                      <tr style={{display: reservation.orders.length > 0 ? '' : 'none'}}>
                        <td align="center" colSpan="5"></td>
                      </tr>


                      {reservation.orders.map(order => {
                          return (
                            <tr key={order.itemId}>
                              <td className="desc" style={{whiteSpace: 'normal', wordWrap: 'break-word'}}>{order.name}</td>
                              <td className="other">¥ {order.unitPrice.toLocaleString()}</td>
                              <td className="other">{order.amount}</td>
                              <td className="other">{order.taxRate * 100} %</td>
                              <td className="other">¥ {(order.unitPrice * order.amount).toLocaleString()}</td>
                            </tr>
                          )
                      })}

                      <tr style={{display: reservation.orders.length > 0 ? '' : 'none'}}>
                        <td align="center" colSpan="5"></td>
                      </tr>

                      <tr style={{display: reservation.orders.length > 0 ? '' : 'none'}}>
                        <td className="other"></td>
                        <td className="other"></td>
                        <td className="other"></td>
                        <td className="other" style={{whiteSpace: 'normal', wordWrap: 'break-word'}}>ご飲食料金小計</td>
                        <td className="other">¥ {this.getOrdersTotal(reservation).toLocaleString()}</td>
                      </tr>
                      <tr style={{display: reservation.orders.length > 0 ? '' : 'none'}}>
                        <td className="other"></td>
                        <td className="other"></td>
                        <td className="other"></td>
                        <td className="other" style={{whiteSpace: 'normal', wordWrap: 'break-word'}}>( 内消費税等</td>
                        <td className="other">¥ {this.getOrdersTotalInnerTax(reservation).toLocaleString()} )</td>
                      </tr>

                      <tr>
                        <td align="center" colSpan="5"></td>
                      </tr>


                      <tr className="grand">
                        <td className="other"></td>
                        <td className="other"></td>
                        <td className="other"></td>
                        <td className="other" style={{whiteSpace: 'normal', wordWrap: 'break-word'}}>合計</td>
                        <td className="other">¥ {(this.getReservationTotal(reservation) + this.getOrdersTotal(reservation)).toLocaleString()}</td>
                      </tr>
                      <tr>
                        <td className="other"></td>
                        <td className="other"></td>
                        <td className="other"></td>
                        <td className="other" style={{whiteSpace: 'normal', wordWrap: 'break-word'}}>(内消費税等</td>
                        <td className="other">¥ {(this.getReservationTotalInnerTax(reservation) + this.getOrdersTotalInnerTax(reservation)).toLocaleString()})</td>
                      </tr>


                    </tbody>
                  </table>
                </main>
                <div className="footer">
                  このたびは、ご利用誠にありがとうございました。<br/>{reservation.lastName} {reservation.firstName} 様のまたのご利用を心よりお待ちしております。<br/>どうぞ、お気をつけてお帰りくださいませ。
                </div>
              </div>
            );


          })}
        </Fragment>
      )

    }else{
      return (
        <div className="bodyNoReservation" style={{margin: 320}}>
          印刷する領収書がありません
        </div>
      );
    }

  }




  render() {
    return (
      <div>
        {this.returnCompornent("")}
        {this.returnCompornent("【店舗控】")}
      </div>
    );
  }

}
