import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { useHistory } from "react-router";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import ErrorIcon from '@material-ui/icons/Error';
import Chip from '@material-ui/core/Chip';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import { useLocation } from 'react-router-dom'
import DialogContentText from '@material-ui/core/DialogContentText';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';


import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import { useSnackbar } from 'notistack';
import { API } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import axios from 'axios';
import cheerio from 'cheerio'
import moji from 'moji'
import copy from 'copy-to-clipboard';

import { style, taxRate } from '../services/Settings'

const useStyles = style


function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Reservation(props) {

  const classes = useStyles();
  const history = useHistory();
  let query = useQuery();

  const startDateFromQuery = query.get("startDate")
  const roomTypeIdFromQuery = query.get("roomTypeId")

  const { enqueueSnackbar } = useSnackbar();

  const [roomPlanMasters, setRoomPlanMasters] = useState([]);


  const [inputReservationType, setInputReservationType] = useState('direct');
  const [inputJalanReservationNumber, setInputJalanReservationNumber] = useState('');
  const [inputIsJalanPayed, setInputIsJalanPayed] = useState(false);
  const [inputIsHpDirect, setInputIsHpDirect] = useState(false);
  const [inputRoomType, setInputRoomType] = useState(null);
  const [inputStartDate, setInputStartDate] = useState(moment().format('YYYY-MM-DD'));
  const [inputDateNum, setInputDateNum] = useState('1');
  const [inputDiscount, setInputDiscount] = useState('0');
  const [inputJalanPoint, setInputJalanPoint] = useState('0');
  const [inputJalanCoupon, setInputJalanCoupon] = useState('0');
  const [inputJalanSubsidy, setInputJalanSubsidy] = useState('0');
  const [inputAdditionalCharge, setInputAdditionalCharge] = useState('0');
  const [inputReservationNote, setInputReservationNote] = useState('');

  const [inputCustomerInfoInputMethod, setInputCustomerInfoInputMethod] = useState('input');

  const [inputLastNameKana, setInputLastNameKana] = useState('');
  const [inputFirstNameKana, setInputFirstNameKana] = useState('');
  const [inputLastName, setInputLastName] = useState('');
  const [inputFirstName, setInputFirstName] = useState('');
  const [inputPostNum, setInputPostNum] = useState('');
  const [inputAddressPrefectures, setInputAddressPrefectures] = useState('');
  const [inputAddressCity, setInputAddressCity] = useState('');
  const [inputAddressOther, setInputAddressOther] = useState('');
  const [inputTel1, setInputTel1] = useState('');
  const [inputTel2, setInputTel2] = useState('');
  const [inputEmail, setInputEmail] = useState('');
  const [inputCustomerNote, setInputCustomerNote] = useState('');

  const [inputLastNameKanaForSearch, setInputLastNameKanaForSearch] = useState('');
  const [inputFirstNameKanaForSearch, setInputFirstNameKanaForSearch] = useState('');
  const [inputTelForSearch, setInputTelForSearch] = useState('');
  const [inputEmailForSearch, setInputEmailForSearch] = useState('');

  const [inputIsNotUseSiteController, setInputIsNotUseSiteController] = useState(false);

  const [storedRoomTypePlans, setStoredRoomTypePlans] = useState([]);
  const [isRepeatCustomerOpen, setIsRepeatCustomerOpen] = useState(false);
  const [searchCustomersResults, setSearchCustomersResults] = useState([]);
  const [searchCustomersResultsForLastNameKana, setSearchCustomersResultsForLastNameKana] = useState([]);
  const [storedRepeatCustomerId, setStoredRepeatCustomerId] = useState('');
  const [storedLastNameslist, setStoredLastNameslist] = useState([]);

  const [jalanId, setJalanId] = useState('');
  const [isWaitingSiteController, setIsWaitingSiteController] = useState(false);
  const [lastNameOnFocus, setLastNameOnFocus] = useState(false);
  const [lastNameKanaOnFocus, setLastNameKanaOnFocus] = useState(false);
  const [isTypedPostNum, setIsTypedPostNum] = useState(false);



  const nl2brJsx = (str) =>{
    let lines = [];
    let array = str.split("\n");
    for(let i in array){
      lines.push(<Fragment key={"lines" + i}>{array[i]}<br/></Fragment>);
    }
    return lines;
  }


  const GUEST_TYPE = {
    adult: "大人",
    child: "子供",
    toddler: "幼児",
  }

  const DISCOUNTS = {
    discount: "割引",
    jalanPoint: "じゃらんポイント",
    jalanCoupon: "じゃらんクーポン",
    jalanSubsidy: "じゃらん補助金"
  }

  const ADDTIONAL_CHARGES = {
    additionalCharge: "追加料金"
  }


  // テナント情報の取得
  useEffect(() => {

    // ページのトップにフォーカス
    window.scrollTo(0, 0)

    props.handleChangePath()

    // 部屋タイプとjalanIdの取得
    let apiName = 'MyAPIGatewayAPI'; // replace this with your api name.
    let path = '/tenant'; //replace this with the path you have configured on your API

    API.get(apiName, path).then(response => {
      console.log(response);
      setRoomPlanMasters(response.tenant.roomPlanMasters)
      setJalanId(response.tenant.jalanId)
    }).catch(error => {
      console.log(error);
      enqueueSnackbar('エラーが発生しました。', {variant : 'error'})
    })

  },[props, enqueueSnackbar])



  // jalanIdが無い場合は、inputIsNotUseSiteControllerを常にTrueにする
  useEffect(() => {

    let unmounted = false;

    //アンマウントされていなければステートを更新
    if(!unmounted) {

      if(jalanId !== '' && !jalanId){
        setInputIsNotUseSiteController(true)
      }

    };

    //クリーンアップ関数を返す
    return ()=>{ unmounted = true; };

  },[jalanId])



  // クエリ文字列の内容を設定
  useEffect(() => {

    let unmounted = false;

    //アンマウントされていなければステートを更新
    if(!unmounted) {

      if(roomPlanMasters.length > 0){
        let num = 0
        roomPlanMasters.map((roomPlanMaster, i) => {
          if(roomPlanMaster.roomTypeId === roomTypeIdFromQuery){
            num = i
          }
          return null
        })

        setInputRoomType(roomPlanMasters[num])
      }

      if(startDateFromQuery){
        setInputStartDate(startDateFromQuery)
      }

    };

    //クリーンアップ関数を返す
    return ()=>{ unmounted = true; };

  },[roomPlanMasters, roomTypeIdFromQuery, startDateFromQuery])



  // テスト用の値設定
  // useEffect(() => {
  //   let unmounted = false;
  //
  //   //アンマウントされていなければステートを更新
  //   if(!unmounted) {
  //     setInputReservationType('direct');
  //     // setInputJalanReservationNumber('A0B1C2D3');
  //     // setInputIsJalanPayed(true)
  //     setInputStartDate('2021-06-01');
  //     setInputDateNum('1');
  //     setInputDiscount('1000');
  //     setInputJalanPoint('1000');
  //     setInputJalanCoupon('1000');
  //     setInputJalanSubsidy('1000');
  //     setInputAdditionalCharge('1000');
  //     setInputReservationNote('予約情報の備考\n予約情報の備考\n予約情報の備考');
  //
  //     setInputLastNameKana('トキタ');
  //     setInputFirstNameKana('アキノリ');
  //     setInputLastName('時田');
  //     setInputFirstName('明典');
  //     setInputPostNum('3540024');
  //     setInputAddressPrefectures('埼玉県');
  //     setInputAddressCity('富士見市');
  //     setInputAddressOther('鶴瀬東2丁目18-14 クリオ鶴瀬壱番館306');
  //     setInputTel1('09035782830');
  //     setInputTel2('0335782830');
  //     setInputEmail('akinori.tokita@gmail.com');
  //     setInputCustomerNote('顧客情報の備考\n顧客情報の備考\n顧客情報の備考');
  //
  //     setStoredRoomTypePlans([
  //       {
  //         "storedRoomTypePlanId": "srt-" + uuidv4(),
  //         "roomTypeId": "rt-7a4dad60-974c-4dc6-9721-ce239f3038ae",
  //         "roomTypeName": "ツインルーム",
  //         "jalanRoomTypeId": "0039263",
  //         "startDate": "2021-06-01",
  //         "dateNum": "1",
  //         "inputGuestType": "adult",
  //         "inputBed": "true",
  //         "inputDinner": "true",
  //         "inputBreakfast": "true",
  //         "inputUnitPrice": "12000",
  //         "inputAmount": "2",
  //         "storedPlans": [
  //           {
  //             "storedPlanId": "sp-" + uuidv4(),
  //             "guestType": "adult",
  //             "bed": true,
  //             "dinner": true,
  //             "breakfast": true,
  //             "unitPrice": "12000",
  //             "amount": "2"
  //           }
  //         ]
  //       }
  //     ])
  //   };
  //
  //   //クリーンアップ関数を返す
  //   return ()=>{ unmounted = true; };
  // },[]);


  // リピーターの設定
  useEffect(() => {

    let unmounted = false;

    //アンマウントされていなければステートを更新
    if(!unmounted) {

      if(storedRepeatCustomerId){
        setIsRepeatCustomerOpen(false)

        searchCustomersResults.map(result => {
          if(result.customerId === storedRepeatCustomerId){
            setInputLastNameKana(result.lastNameKana ? result.lastNameKana : '')
            setInputFirstNameKana(result.firstNameKana ? result.firstNameKana : '')
            setInputLastName(result.lastName ? result.lastName : '')
            setInputFirstName(result.firstName ? result.firstName : '')
            setInputPostNum(result.postNum ? result.postNum : '')
            setInputAddressPrefectures(result.addressPrefectures ? result.addressPrefectures : '')
            setInputAddressCity(result.addressCity ? result.addressCity : '')
            setInputAddressOther(result.addressOther ? result.addressOther : '')
            setInputTel1(result.tel1 ? result.tel1 : '')
            setInputTel2(result.tel2 ? result.tel2 : '')
            setInputEmail(result.email ? result.email : '')
            setInputCustomerNote(result.customerNote ? result.customerNote : '')
          }
          return null
        })

        searchCustomersResultsForLastNameKana.map(result => {
          if(result.customerId === storedRepeatCustomerId){
            setInputLastNameKana(result.lastNameKana ? result.lastNameKana : '')
            setInputFirstNameKana(result.firstNameKana ? result.firstNameKana : '')
            setInputLastName(result.lastName ? result.lastName : '')
            setInputFirstName(result.firstName ? result.firstName : '')
            setInputPostNum(result.postNum ? result.postNum : '')
            setInputAddressPrefectures(result.addressPrefectures ? result.addressPrefectures : '')
            setInputAddressCity(result.addressCity ? result.addressCity : '')
            setInputAddressOther(result.addressOther ? result.addressOther : '')
            setInputTel1(result.tel1 ? result.tel1 : '')
            setInputTel2(result.tel2 ? result.tel2 : '')
            setInputEmail(result.email ? result.email : '')
            setInputCustomerNote(result.customerNote ? result.customerNote : '')
          }
          return null
        })
      }

    };

    //クリーンアップ関数を返す
    return ()=>{ unmounted = true; };

  },[storedRepeatCustomerId, searchCustomersResults, searchCustomersResultsForLastNameKana])


  // リピーター検索ダイアログを閉じたとき
  useEffect(() => {

    let unmounted = false;

    //アンマウントされていなければステートを更新
    if(!unmounted) {

      if(!isRepeatCustomerOpen){
        setInputLastNameKanaForSearch('')
        setInputFirstNameKanaForSearch('')
        setInputTelForSearch('')
        setInputEmailForSearch('')
        setSearchCustomersResults([])
      }

    };

    //クリーンアップ関数を返す
    return ()=>{ unmounted = true; };

  },[isRepeatCustomerOpen])


  // リピーター解除時
  useEffect(() => {

    let unmounted = false;

    //アンマウントされていなければステートを更新
    if(!unmounted) {

      if(!Boolean(storedRepeatCustomerId)){
        setInputLastNameKana('')
        setInputFirstNameKana('')
        setInputLastName('')
        setInputFirstName('')
        setInputPostNum('')
        setInputAddressPrefectures('')
        setInputAddressCity('')
        setInputAddressOther('')
        setInputTel1('')
        setInputTel2('')
        setInputEmail('')
        setInputCustomerNote('')

        setStoredLastNameslist([])
        setSearchCustomersResultsForLastNameKana([])
      }

    };

    //クリーンアップ関数を返す
    return ()=>{ unmounted = true; };

  },[storedRepeatCustomerId])



  const addStoredRoomTypePlans = () => {

    let dates = []

    for(let i = 0; i < inputDateNum; i++){
      dates.push(moment(inputStartDate).add(i,'days'))
    }

    let isSelectInvalidRoomType = false

    dates.map(date => {
      if(!(date.isSameOrAfter(moment(inputRoomType.startDate)) && (inputRoomType.endDate === null || date.isSameOrBefore(moment(inputRoomType.endDate))))){
        isSelectInvalidRoomType = true
      }
      return null
    })

    if(isSelectInvalidRoomType){
      enqueueSnackbar('選択された日付でこのお部屋タイプは利用できません', {variant : 'warning'})
      return
    }

    let storedRoomTypePlansAfter = storedRoomTypePlans.concat()

    // storedRoomTypePlansの初期値を設定
    storedRoomTypePlansAfter.push({
      storedRoomTypePlanId : 'srtp-' + uuidv4(),
      roomTypeId : inputRoomType.roomTypeId,
      roomTypeName : inputRoomType.roomTypeName,
      jalanRoomTypeId : inputRoomType.jalanRoomTypeId,
      startDate : inputStartDate,
      dateNum : inputDateNum,
      inputGuestType: 'adult',
      inputBed: 'true',
      inputDinner: 'true',
      inputBreakfast: 'true',
      inputUnitPrice: '0',
      inputAmount: '2',
      storedPlans: []
    })

    setStoredRoomTypePlans(storedRoomTypePlansAfter)
    setInputRoomType(null)
  }


  const deleteStoredRoomType = (storedRoomTypePlanId) => {
    let storedRoomTypePlansAfter1 = storedRoomTypePlans.concat()

    const storedRoomTypePlansAfter2 = storedRoomTypePlansAfter1.filter((roomType) => {
      return roomType.storedRoomTypePlanId !== storedRoomTypePlanId
    })

    setStoredRoomTypePlans(storedRoomTypePlansAfter2)
  }


  const handleChageStoredRoomTypePlan = (storedRoomTypePlanId, state, value) => {
    let storedRoomTypePlansAfter = storedRoomTypePlans.concat()

    storedRoomTypePlansAfter.map((roomType, i) => {
      if(roomType.storedRoomTypePlanId === storedRoomTypePlanId){
        storedRoomTypePlansAfter[i][state] = value
      }
      return null
    })

    setStoredRoomTypePlans(storedRoomTypePlansAfter)
  }


  const addStoredPlan = (storedRoomTypePlanId) => {

    let roomTypePlan = null

    storedRoomTypePlans.map(storedRoomTypePlan => {
      if(storedRoomTypePlan.storedRoomTypePlanId === storedRoomTypePlanId){
        roomTypePlan = storedRoomTypePlan
      }
      return null
    })


    if(roomTypePlan.inputGuestType === null || roomTypePlan.inputBed === null || roomTypePlan.inputDinner === null || roomTypePlan.inputBreakfast === null ){
      enqueueSnackbar('全ての項目を入力してください', {variant : 'warning'})
      return
    }

    let storedRoomTypePlansAfter = storedRoomTypePlans.concat()

    storedRoomTypePlansAfter.map((roomType,i) => {
      if(roomType.storedRoomTypePlanId === storedRoomTypePlanId){
        let storedPlansAfter = roomType.storedPlans.concat()

        // storedPlansの初期値を設定
        storedPlansAfter.push({
          storedPlanId: 'sp-' + uuidv4(),
          planId: roomTypePlan.storedPlanId ? roomTypePlan.storedPlanId : null,
          planNameForAdmin: roomTypePlan.storedPlanNameForAdmin ? roomTypePlan.storedPlanNameForAdmin : null,
          planNameForGuest: roomTypePlan.storedPlanNameForGuest ? roomTypePlan.storedPlanNameForGuest : null,
          guestType: roomTypePlan.inputGuestType,
          bed: roomTypePlan.inputBed === 'true' ? true : false,
          dinner: roomTypePlan.inputDinner === 'true' ? true : false,
          breakfast: roomTypePlan.inputBreakfast === 'true' ? true : false,
          unitPrice: roomTypePlan.inputUnitPrice,
          amount: roomTypePlan.inputAmount
        })

        console.log('storedPlansAfter: ', storedPlansAfter)

        storedRoomTypePlansAfter[i].storedPlans = storedPlansAfter

      }
      return null
    })

    setStoredRoomTypePlans(storedRoomTypePlansAfter)
  }



  const deleteStoredPlan = (storedPlanId) => {

    let storedRoomTypePlansAfter = storedRoomTypePlans.concat()

    storedRoomTypePlansAfter.map((roomType,i) => {

      let storedPlansAfter1 = roomType.storedPlans.concat()

      let storedPlansAfter2 = storedPlansAfter1.filter((plan) => {
        return plan.storedPlanId !== storedPlanId
      })

      storedRoomTypePlansAfter[i].storedPlans = storedPlansAfter2

      setStoredRoomTypePlans(storedRoomTypePlansAfter)
      return null
    })

  }

  const getRoomTypePlanTotalAmount = (roomTypePlan) => {
    let total = 0

    roomTypePlan.storedPlans.map(plan => {
      total = total + Number(plan.amount)
      return null
    })

    return total
  }

  const getRoomTypePlanUnitPriceTotal = (roomTypePlan) => {
    let total = 0

    roomTypePlan.storedPlans.map(plan => {
      total = total + (Number(plan.unitPrice) * Number(plan.amount))
      return null
    })

    return total
  }

  const getRoomTypePlanTotal = (roomTypePlan) => {

    let total = 0

    roomTypePlan.storedPlans.map(plan => {
      total = total + (Number(plan.unitPrice) * Number(plan.amount))
      return null
    })

    total = total * Number(roomTypePlan.dateNum)

    return total

  }

  const getTotal = () => {
    let plansTotal = 0

    storedRoomTypePlans.map(storedRoomTypePlan => {
      storedRoomTypePlan.storedPlans.map(storedPlan => {
        plansTotal = plansTotal + (Number(storedPlan.unitPrice) * Number(storedPlan.amount) * Number(storedRoomTypePlan.dateNum))
        return null
      })
      return null
    })

    let additionalChargeTotal = 0

    if(inputReservationType === 'direct'){
      additionalChargeTotal = Number(inputAdditionalCharge)
    }

    let discountTotal = 0

    if(inputReservationType === 'direct'){
      discountTotal = Number(inputDiscount)
    }else if(inputReservationType === 'jalan'){
      discountTotal = Number(inputJalanPoint) + Number(inputJalanCoupon) + Number(inputJalanSubsidy)
    }

    let total = 0

    total = plansTotal + additionalChargeTotal - discountTotal

    return total
  }


  const getPlansTable = () => {

    let plansTotal = 0

    storedRoomTypePlans.map(storedRoomTypePlan => {
      storedRoomTypePlan.storedPlans.map(storedPlan => {
        plansTotal = plansTotal + (Number(storedPlan.unitPrice) * Number(storedPlan.amount) * Number(storedRoomTypePlan.dateNum))
        return null
      })
      return null
    })

    let additionalChargeTotal = 0

    if(inputReservationType === 'direct'){
      additionalChargeTotal = Number(inputAdditionalCharge)
    }

    let discountTotal = 0

    if(inputReservationType === 'direct'){
      discountTotal = Number(inputDiscount)
    }else if(inputReservationType === 'jalan'){
      discountTotal = Number(inputJalanPoint) + Number(inputJalanCoupon) + Number(inputJalanSubsidy)
    }

    let total = 0

    total = plansTotal + additionalChargeTotal - discountTotal

    let innerTax = Math.floor(total * taxRate / (1 + taxRate))

    return (
      <Fragment>
        <TableRow>
          <TableCell align="left"></TableCell>
          <TableCell align="right"></TableCell>
        </TableRow>
        <TableRow className={classes.darkColorRow}>
          <TableCell align="left">宿泊料金小計</TableCell>
          <TableCell align="right">¥ {plansTotal.toLocaleString()}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left"></TableCell>
          <TableCell align="right"></TableCell>
        </TableRow>
        <TableRow className={classes.darkColorRow}  style={{display: inputReservationType === 'direct' ? '' : 'none'}}>
          <TableCell align="left">追加料金小計</TableCell>
          <TableCell align="right">¥ {additionalChargeTotal.toLocaleString()}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left"></TableCell>
          <TableCell align="right"></TableCell>
        </TableRow>
        <TableRow className={classes.lightColorRow}  style={{display: inputReservationType === 'direct' ? '' : 'none'}}>
          <TableCell align="left">割引額</TableCell>
          <TableCell align="right">- ¥ {inputDiscount.toLocaleString()}</TableCell>
        </TableRow>
        <TableRow className={classes.lightColorRow}  style={{display: inputReservationType === 'jalan' ? '' : 'none'}}>
          <TableCell align="left">じゃらんポイント利用額</TableCell>
          <TableCell align="right">- ¥ {inputJalanPoint.toLocaleString()}</TableCell>
        </TableRow>
        <TableRow className={classes.lightColorRow}  style={{display: inputReservationType === 'jalan' ? '' : 'none'}}>
          <TableCell align="left">じゃらんクーポン利用額</TableCell>
          <TableCell align="right">- ¥ {inputJalanCoupon.toLocaleString()}</TableCell>
        </TableRow>
        <TableRow className={classes.lightColorRow}  style={{display: inputReservationType === 'jalan' ? '' : 'none'}}>
          <TableCell align="left">じゃらん補助金利用額</TableCell>
          <TableCell align="right">- ¥ {inputJalanSubsidy.toLocaleString()}</TableCell>
        </TableRow>
        <TableRow className={classes.darkColorRow}>
          <TableCell align="left">割引額小計</TableCell>
          <TableCell align="right">- ¥ {discountTotal.toLocaleString()}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left"></TableCell>
          <TableCell align="right"></TableCell>
        </TableRow>
        <TableRow className={classes.primaryColorRow}>
          <TableCell align="left">合計 (税込)</TableCell>
          <TableCell align="right">¥ {total.toLocaleString()}</TableCell>
        </TableRow>
        <TableRow className={classes.lightColorRow}>
          <TableCell align="left">( 内消費税等</TableCell>
          <TableCell align="right">¥ {innerTax.toLocaleString()} )</TableCell>
        </TableRow>

      </Fragment>
    )
  }



  const receiveReservation = () => {

    //プラン入力があることのチェック
    if(storedRoomTypePlans.length === 0){
      enqueueSnackbar('プランを設定してください', {variant : 'warning'})
      return
    }

    let isPlanExist = false

    storedRoomTypePlans.map(roomTypePlan => {
      if(roomTypePlan.storedPlans.length < 1){
        isPlanExist = true
      }
      return null
    })

    if(isPlanExist){
      enqueueSnackbar('プランを設定してください', {variant : 'warning'})
      return
    }


    // 合計金額がプラスであることの確認
    if(getTotal() < 0){
      enqueueSnackbar('合計金額がマイナスになっています', {variant : 'warning'})
      return
    }


    let reservationType = 'direct'
    if(inputReservationType === 'jalan'){
      reservationType = 'jalan-' + inputJalanReservationNumber
    }

    let discountsForBody = {}

    if(inputReservationType === 'direct'){
      discountsForBody.discount = {
        name: DISCOUNTS.discount,
        price: Number(inputDiscount),
      }
    }

    if(inputReservationType === 'jalan'){
      discountsForBody.jalanPoint = {
        name: DISCOUNTS.jalanPoint,
        price: Number(inputJalanPoint),
      }

      discountsForBody.jalanCoupon = {
        name: DISCOUNTS.jalanCoupon,
        price: Number(inputJalanCoupon),
      }

      discountsForBody.jalanSubsidy = {
        name: DISCOUNTS.jalanSubsidy,
        price: Number(inputJalanSubsidy),
      }
    }

    let additionalChargesForBody = {}

    if(inputReservationType === 'direct'){
      additionalChargesForBody.additionalCharge = {
        name: ADDTIONAL_CHARGES.additionalCharge,
        price: Number(inputAdditionalCharge),
      }
    }

    let taxRateForBody = taxRate

    let charges = {
      discounts: discountsForBody,
      additionalCharges: additionalChargesForBody,
      taxRate: taxRateForBody
    }

    let methodOfPaymentPlans = ''

    if(inputIsJalanPayed){
      methodOfPaymentPlans = 'jalan'
    }


    let isHpDirect = inputIsHpDirect


    let overnights = []

    storedRoomTypePlans.map(roomTypePlan => {

      let plansForOvernight = []

      roomTypePlan.storedPlans.map(plan => {

        plansForOvernight.push(
          {
            guestType : plan.guestType,
            bed : plan.bed,
            dinner : plan.dinner,
            breakfast: plan.breakfast,
            price : Number(plan.unitPrice),
            amount: Number(plan.amount),
            taxRate: Number(taxRate),
            planId: plan.planId,
            planNameForAdmin: plan.planNameForAdmin,
            planNameForGuest: plan.planNameForGuest,
          }
        )
        return null
      })

      for(let i = 0 ; i < roomTypePlan.dateNum ; i++){

        overnights.push(
          {
            roomTypeId: roomTypePlan.roomTypeId,
            roomTypeName: roomTypePlan.roomTypeName,
            jalanRoomTypeId : roomTypePlan.jalanRoomTypeId,
            date: moment(roomTypePlan.startDate).add(i, 'days').format('YYYY-MM-DD'),
            plans: plansForOvernight
          }
        )
      }
      return null
    })

    let roomTypePlans = storedRoomTypePlans.map( roomTypePlan => {

      let storedPlans = roomTypePlan.storedPlans.map(plan => {
        return {
          storedPlanId: plan.storedPlanId,
          amount: Number(plan.amount),
          bed: plan.bed,
          breakfast: plan.breakfast,
          dinner: plan.dinner,
          guestType: plan.guestType,
          unitPrice:Number(plan.unitPrice),
          taxRate: Number(taxRate),
          planId: plan.planId,
          planNameForAdmin: plan.planNameForAdmin,
          planNameForGuest: plan.planNameForGuest,
        }
      })

      return {
        storedRoomTypePlanId: roomTypePlan.storedRoomTypePlanId,
        dateNum: roomTypePlan.dateNum,
        roomTypeId: roomTypePlan.roomTypeId,
        roomTypeName: roomTypePlan.roomTypeName,
        jalanRoomTypeId: roomTypePlan.jalanRoomTypeId,
        startDate: roomTypePlan.startDate,
        storedPlans: storedPlans
      }
    })

    // 7ヶ月以上にまたがる場合の確認
    // 予約に含まれる日付を含む月が6ヶ月以上となる宿泊は受付不可とする(トランザクションの上限を見据えて余裕を持って)
    let dates = []

    overnights.map(overnight => {
      dates.push(overnight.date)
      return null
    })

    dates = Array.from(new Set(dates))

    console.log(dates)

    let yearMonths = dates.map(date => {
      let yearMonth = date.substr(0, 7)
      return yearMonth
    })

    yearMonths = Array.from(new Set(yearMonths))

    if(yearMonths.length > 6){
      enqueueSnackbar('予約に含まれる日付を含む月が6ヶ月以内となるようにしてください', {variant : 'warning'})
      return
    }

    let reservationNote = inputReservationNote
    let lastName = inputLastName
    let firstName = inputFirstName
    let lastNameKana = inputLastNameKana
    let firstNameKana = inputFirstNameKana
    let tel1 = inputTel1
    let tel2 = inputTel2
    let email = inputEmail
    let postNum = inputPostNum
    let addressPrefectures = inputAddressPrefectures
    let addressCity = inputAddressCity
    let addressOther = inputAddressOther
    let customerNote = inputCustomerNote
    let repeatCustomerId = storedRepeatCustomerId

    let customerInfoInputMethod = inputCustomerInfoInputMethod


    let body = {
      reservationType: reservationType,
      isHpDirect: isHpDirect,
      charges: charges,
      methodOfPaymentPlans: methodOfPaymentPlans,
      overnights: overnights,
      roomTypePlans: roomTypePlans,
      reservationNote: reservationNote,
      lastName: lastName,
      firstName: firstName,
      lastNameKana: lastNameKana,
      firstNameKana: firstNameKana,
      postNum: postNum,
      addressPrefectures: addressPrefectures,
      addressCity: addressCity,
      addressOther: addressOther,
      tel1: tel1,
      tel2: tel2,
      email: email,
      customerNote: customerNote,
      repeatCustomerId: repeatCustomerId,
      customerInfoInputMethod: customerInfoInputMethod,
    }

    console.log('body: ', body)

    let createReservation = () => {
      let apiName = 'MyAPIGatewayAPI'; // replace this with your api name.
      let path = '/reservation-receiver'; //replace this with the path you have configured on your API
      let myInit = {
        body: body
      }

      API.post(apiName, path, myInit).then(response => {
        console.log(response)
        enqueueSnackbar(response.message, {variant : 'success'});
        setStoredRepeatCustomerId('')
        // 発行されたURLをクリップボードにコピー
        if(response.pathParam){
          copy(process.env.REACT_APP_WEB_APP_ENDPOINT + '/customer/' + response.pathParam)
          enqueueSnackbar('クリップボードにURLをコピーしました', {variant : 'success'});
        }
        history.push('/main/reservation-manage?reservationId=' + response.reservationId);
      }).catch(error => {
        console.log(error)
        if(!(error.response === undefined)){
          if(!(error.response.status === undefined)){
            if(error.response.status === 409){
              enqueueSnackbar(error.response.data.message, {variant : 'error'})
              return
            }
          }
        }

        if(error.message){
          enqueueSnackbar(error.message, {variant : 'error'})
          return
        }

        enqueueSnackbar('エラーが発生しました。', {variant : 'error'})
      });
    }

    // サイトコントローラーの動作を考慮
    if(inputReservationType === "direct" && !inputIsNotUseSiteController){
      setIsWaitingSiteController(true)

      let apiName = 'MyAPIGatewayAPI'
      let path = '/jalan/decrease'

      let body = {
        overnights : overnights
      }

      let myInit = {
        body: body
      }

      API.post(apiName, path, myInit).then(async (response) => {

        let executionArn = response.executionArn

        console.log(response)

        let path = '/jalan/decrease/status'

        let body = {
          executionArn : executionArn
        }

        let myInit = {
          body: body
        }

        //結果をポーリング

        function sleep(waitSec) {
          return new Promise(function (resolve) {
            setTimeout(function() { resolve() }, waitSec);
          });
        }

        let res

        let count = 0
        while (count < 40) {

          try{
            res = await API.post(apiName, path, myInit)
            console.log(res)

            if(res.status === "RUNNING"){
              count++
              await sleep(3000)
            }else{
              break
            }

          }catch(error){

            console.log(error)
            if(error.message){
              enqueueSnackbar(error.message, {variant : 'error'})
              setIsWaitingSiteController(false)
            }else{
              enqueueSnackbar('エラーが発生しました。', {variant : 'error'})
              setIsWaitingSiteController(false)
            }
            break
          }
        }

        if(res.status === "SUCCEEDED"){

          if(res.output.message !== "success"){
            enqueueSnackbar(res.output.message, {variant : 'error', autoHideDuration : 20000})
            setIsWaitingSiteController(false)
          }else{
            if(res.output.beforeSale.length > 0){
              res.output.beforeSale.map(item => {
                let message = moment(item.date).format('YYYY年M月D日') + ' の ' + item.roomTypeName + ' はじゃらんで販売開始前のため、じゃらんの在庫は変更されませんでした。'
                enqueueSnackbar(message, {variant : 'warning', autoHideDuration : 20000})
                return null
              })
            }

            if(res.output.stopSale.length > 0){
              res.output.stopSale.map(item => {
                let message = moment(item.date).format('YYYY年M月D日') + ' の ' + item.roomTypeName + ' はじゃらんで販売停止中のため、じゃらんの在庫は変更されませんでした。'
                enqueueSnackbar(message, {variant : 'warning', autoHideDuration : 20000})
                return null
              })

            }

            createReservation()
            setIsWaitingSiteController(false)
          }

        }else{
          enqueueSnackbar('処理中にエラーが発生しました。念の為、じゃらんの在庫状況を確認してください。', {variant : 'error'})
          setIsWaitingSiteController(false)
        }

      }).catch(error => {

        setIsWaitingSiteController(false)

        console.log(error)
        if(error.message){
          enqueueSnackbar(error.message, {variant : 'error'})
        }else{
          enqueueSnackbar('エラーが発生しました。', {variant : 'error'})
        }

      });

    }else{
      createReservation()
    }
  };



  const searchRepeatCustomer = () => {
    if(
      inputLastNameKanaForSearch === '' &&
      inputFirstNameKanaForSearch === '' &&
      inputTelForSearch === '' &&
      inputEmailForSearch === ''
    ){
      enqueueSnackbar('検索項目を設定してください', {variant : 'warning'})
      return
    }



    let apiName = 'MyAPIGatewayAPI'; // replace this with your api name.
    let path = '/customers'; //replace this with the path you have configured on your API
    const myInit = {
      queryStringParameters: {
        lastNameKana: inputLastNameKanaForSearch,
        firstNameKana: inputFirstNameKanaForSearch,
        tel: inputTelForSearch,
        email: inputEmailForSearch,
      },
    };

    API.get(apiName, path, myInit).then(response => {
      console.log(response)
      setSearchCustomersResults(response.customers)
      let variant = 'success'
      if(response.customers.length === 0){
        variant = 'warning'
      }
      enqueueSnackbar(response.message, {variant : variant});
    }).catch(error => {
      console.log(error)

      if(error.message){
        enqueueSnackbar(error.message, {variant : 'error'})
        return
      }

      enqueueSnackbar('エラーが発生しました。', {variant : 'error'})
    });


  }


  const searchRepeatCustomerByLastNameKana = useCallback(
    () => {

      let apiName = 'MyAPIGatewayAPI'; // replace this with your api name.
      let path = '/customers'; //replace this with the path you have configured on your API
      const myInit = {
        queryStringParameters: {
          lastNameKana: inputLastNameKana,
          firstNameKana: '',
          tel: '',
          email: '',
        },
      };

      API.get(apiName, path, myInit).then(response => {
        console.log(response)
        setSearchCustomersResultsForLastNameKana(response.customers)
      }).catch(error => {
        console.log(error)

        if(error.message){
          enqueueSnackbar(error.message, {variant : 'error'})
          return
        }

        enqueueSnackbar('エラーが発生しました。', {variant : 'error'})
      });


    },
    [inputLastNameKana, enqueueSnackbar]
  )



  const getAddress = (postNum) => {

    let apiName = 'MyAPIGatewayAPI'; // replace this with your api name.
    let path = '/address' ; //replace this with the path you have configured on your API
    const myInit = { // OPTIONAL
      queryStringParameters: {  // OPTIONAL
        zipcode : postNum
      },
    };

    API.get(apiName, path, myInit).then(response => {
      if(response.status === 200){
        console.log(response.results[0]);

        setInputAddressPrefectures(response.results[0].address1)
        setInputAddressCity(response.results[0].address2)
        setInputAddressOther(response.results[0].address3)

      }else{
        console.log(response.message)
      }
    }).catch(error => {
      console.log(error);
    })
  }


  const changeChar = (event, key) => {
    let value = event.target.value; // this is the search text

    value = value.replace(/[Ａ-Ｚａ-ｚ０-９]/g, (s) => {
      return String.fromCharCode(s.charCodeAt(0) - 65248);
    });

    if(key === "postNum"){
      setInputPostNum(value)
    }

    if(key === "tel1"){
      setInputTel1(value)
    }

    if(key === "tel2"){
      setInputTel2(value)
    }

    if(key === "telForSearch"){
      setInputTelForSearch(value)
    }
  }


  const getLastName = (lastNameKana) => {
    const kanaToHira = (str) => {
      return str.replace(/[\u30a1-\u30f6]/g, function(match) {
        var chr = match.charCodeAt(0) - 0x60;
        return String.fromCharCode(chr);
      });
    }

    const isZenKatakana = (str) =>{
      str = (str==null)?"":str;
      if(str.match(/^[ァ-ンヴー　]*$/)){    //"ー"の後ろの文字は全角スペースです。
        return true;
      }else{
        return false;
      }
    }

    let keyword = lastNameKana
    let lastNamesList = []

    if(isZenKatakana(keyword) && keyword && keyword.length > 1){
      console.log('Searching Last Name. Page: 1')
      keyword = kanaToHira(lastNameKana)
      axios.get('https://myoji-yurai.net/resultList.htm', {
        params: {
          kind : "読み方で",
          keyword: keyword,
        }
      })
      .then((response) => {
        try {
          const $ = cheerio.load(response.data)

          $('#content > div > table > tbody tr').each((i,element)=>{
            let lastNameKana =element.children[1].children[1].children[0].data
            let lastName = element.children[1].children[0].children[0].data

            if (
              lastNameKana.indexOf('(' + keyword + ')') !== -1 ||
              lastNameKana.indexOf(',' + keyword + ',') !== -1 ||
              lastNameKana.indexOf('(' + keyword + ',') !== -1 ||
              lastNameKana.indexOf(',' + keyword + ')') !== -1 ||
              lastNameKana.indexOf('…') !== -1
            ){
              lastNamesList.push(lastName)
            }
          })

          setStoredLastNameslist(lastNamesList)
          console.log('Last Name Search done.')

        }catch(e){
          console.error(e)
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    }else{
      console.log("not Zenkakukatakana")
    }
  }


  const typedPostNum = (value) => {
    setInputPostNum(value)
    setIsTypedPostNum(true)
  }

  // inputPostNumが変更されたときに住所を検索する
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if(isTypedPostNum){
        if(inputPostNum.length === 7){
          getAddress(inputPostNum)
        }
        setIsTypedPostNum(false)
      }
    }, 1000)
    return () => clearTimeout(timeoutId);
  }, [inputPostNum, isTypedPostNum]);


  // inputLastNameKanaが変更されたときに氏の漢字とリピーターを検索する
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getLastName(inputLastNameKana)
      searchRepeatCustomerByLastNameKana()
    }, 1000)
    return () => clearTimeout(timeoutId);
  }, [inputLastNameKana, searchRepeatCustomerByLastNameKana]);


  // ひらがなをカタカナに変換
  const toKatakana = (key) => {

    if(key === 'inputLastNameKana'){
      let value = moji(inputLastNameKana).convert('HG', 'KK').toString();
      setInputLastNameKana(value)
    }

    if(key === 'inputFirstNameKana'){
      let value = moji(inputFirstNameKana).convert('HG', 'KK').toString();
      setInputFirstNameKana(value)
    }

    if(key === 'inputLastNameKanaForSearch'){
      let value = moji(inputLastNameKanaForSearch).convert('HG', 'KK').toString();
      setInputLastNameKanaForSearch(value)
    }

    if(key === 'inputFirstNameKanaForSearch'){
      let value = moji(inputFirstNameKanaForSearch).convert('HG', 'KK').toString();
      setInputFirstNameKanaForSearch(value)
    }

  }


  // プランを自動入力するための関数
  const getRoomTypeIndex = (roomTypeId) => {
    let index = 0
    if(roomPlanMasters.length > 0){
      roomPlanMasters.map((roomPlanMaster, i) => {
        if(roomPlanMaster.roomTypeId === roomTypeId){
          index = i
        }
        return null
      })
    }

    return index

  }

  const handlePlan = (storedRoomTypePlanId, v) => {

    if(v){
      handleChageStoredRoomTypePlan(storedRoomTypePlanId ,'storedPlanId', v.planId)
      handleChageStoredRoomTypePlan(storedRoomTypePlanId ,'storedPlanNameForAdmin', v.planNameForAdmin)
      handleChageStoredRoomTypePlan(storedRoomTypePlanId ,'storedPlanNameForGuest', v.planNameForGuest)

      handleChageStoredRoomTypePlan(storedRoomTypePlanId ,'inputGuestType',v.guestType)
      handleChageStoredRoomTypePlan(storedRoomTypePlanId ,'inputBed',String(v.bed))
      handleChageStoredRoomTypePlan(storedRoomTypePlanId ,'inputDinner',String(v.dinner))
      handleChageStoredRoomTypePlan(storedRoomTypePlanId ,'inputBreakfast',String(v.breakfast))
      handleChageStoredRoomTypePlan(storedRoomTypePlanId ,'inputUnitPrice',v.unitPrice)
      handleChageStoredRoomTypePlan(storedRoomTypePlanId ,'inputAmount',v.amount)
    }else{
      handleChageStoredRoomTypePlan(storedRoomTypePlanId ,'storedPlanId', null)
      handleChageStoredRoomTypePlan(storedRoomTypePlanId ,'storedPlanNameForAdmin', '')
      handleChageStoredRoomTypePlan(storedRoomTypePlanId ,'storedPlanNameForGuest', '')
    }

  }


  return (
    <Fragment>
      <Grid container spacing={2} className={classes.container}>

        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom>
            予約受付
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Paper className={classes.paper} elevation={1}>
            <Grid container spacing={2} className={classes.container}>

              <Grid item xs={12}>
                <Typography variant="h5" gutterBottom>
                  宿泊情報
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <ValidatorForm
                  onSubmit={() => {return}}
                  onError={
                    errors => {
                      console.log(errors);
                      enqueueSnackbar('入力エラーがあります。確認してください。', {variant : 'warning'});
                    }
                  }
                  className={classes.validatorForm}
                >
                  <Grid container spacing={2} className={classes.container}>

                    <Grid item xs={3}>
                      <FormControl required={true}>
                        <FormLabel>予約タイプ</FormLabel>
                        <RadioGroup aria-label="position" name="position" defaultValue="direct" value={inputReservationType} onChange={(e,v) => setInputReservationType(v)}>
                          <FormControlLabel
                            value="direct"
                            control={<Radio color="primary" size='small' />}
                            label="直接予約"
                          />
                          <FormControlLabel
                            value="jalan"
                            control={<Radio color="primary" size='small' />}
                            label="じゃらん/Web"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>

                    <Grid item xs={3}>
                      <TextValidator
                        className={classes.textField}
                        name="jalanReservationNumber"
                        label="じゃらん予約番号"
                        value={inputJalanReservationNumber}
                        margin="normal"
                        inputProps={{
                          maxLength: 8,
                          form: 'submitReservationForm'
                        }}
                        onChange={(e) => setInputJalanReservationNumber(e.target.value)}
                        required={inputReservationType === "jalan" ? true : false}
                        validators={['matchRegexp:^([0-9A-Z]{8})$']}
                        errorMessages={['じゃらん予約番号に誤りがあります']}
                        style={{marginTop: 45, display: inputReservationType === "jalan" ? "" : "none"}}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={inputIsJalanPayed}
                            onChange={(e) => setInputIsJalanPayed(e.target.checked)}
                            name="isJalanPayed"
                            color="primary"
                          />
                        }
                        label="じゃらん支払済"
                        style={{marginTop: 55, marginLeft: 10, display: inputReservationType === "jalan" ? "" : "none"}}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={inputIsHpDirect}
                            onChange={(e) => setInputIsHpDirect(e.target.checked)}
                            name="isHpDirect"
                            color="primary"
                          />
                        }
                        label="自社Web"
                        style={{marginTop: 55, marginLeft: 0, display: inputReservationType === "jalan" ? "" : "none"}}
                      />
                    </Grid>

                  </Grid>
                </ValidatorForm>
              </Grid>

              <Grid item xs={12}>
                <ValidatorForm
                  onSubmit={() => addStoredRoomTypePlans()}
                  onError={
                    errors => {
                      console.log(errors);
                      enqueueSnackbar('入力エラーがあります。確認してください。', {variant : 'warning'});
                    }
                  }
                  className={classes.validatorForm}
                >
                  <Grid container spacing={2} className={classes.container}>

                    <Grid item xs={8}>
                      <Autocomplete
                        id="combo-box"
                        options={roomPlanMasters}
                        getOptionLabel={(option) => option.roomTypeName}
                        value={inputRoomType}
                        onChange={(event, newValue) => {
                          setInputRoomType(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} required={true} label="お部屋タイプ"/>}
                        className={classes.autocomplete}
                        getOptionSelected={(option, value) => option.roomTypeId === value.roomTypeId}
                      />
                    </Grid>

                    <Grid item xs={4}/>

                    <Grid item xs={3}>
                      <TextValidator
                        className={classes.textField}
                        name="date"
                        label="宿泊開始日"
                        value={inputStartDate}
                        margin="normal"
                        onChange={(e) => setInputStartDate(e.target.value)}
                        type="date"
                        required={true}
                        validators={['required', 'matchRegexp:^\\d{4}-\\d{1,2}-\\d{1,2}$']}
                        errorMessages={['必須項目を入力してください', '日付が間違っています']}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid item xs={2}>
                      <TextValidator
                        className={classes.textField}
                        name="dateNum"
                        label="宿泊日数"
                        value={inputDateNum}
                        margin="normal"
                        onChange={(e) => setInputDateNum(e.target.value)}
                        type="number"
                        inputProps={{
                          step: 1,
                        }}
                        required={true}
                        validators={['required', 'isNumber', 'minNumber:1']}
                        errorMessages={['必須項目を入力してください', '整数を入力してください', '1以上の数を入力してください']}
                      />
                    </Grid>


                    <Grid item xs={9}/>
                    <Grid item xs={3}>
                      <Button type="submit" variant="contained" color="default" className={classes.button}>
                        部屋追加
                      </Button>
                    </Grid>

                  </Grid>
                </ValidatorForm>
              </Grid>

              <Grid item xs={12} className={classes.warningMessage} style={{display: storedRoomTypePlans.length > 0 ? 'none' : ''}}>
               <Grid container direction="row" alignItems="center">
                 <Grid item>
                   <ErrorIcon className={classes.errorIcon}  />
                 </Grid>
                 <Grid item>
                   お部屋を入力してください
                 </Grid>
               </Grid>
              </Grid>



              {storedRoomTypePlans.map(roomTypePlan => (
                <Paper className={classes.innerPaper} elevation={2} key={roomTypePlan.storedRoomTypePlanId}>
                 <Grid container spacing={4} className={classes.container}>

                   <Grid item xs={10}>
                     <Typography variant="h6" gutterBottom>
                      {roomTypePlan.roomTypeName}<br/>
                      {moment(roomTypePlan.startDate).format('YYYY年M月D日')} から {roomTypePlan.dateNum}泊
                     </Typography>
                   </Grid>

                   <Grid item xs={2}>
                    <Grid container spacing={0} className={classes.deleteInPaper} justify='flex-end'>
                       <IconButton size='small' aria-label="Delete" onClick={() => deleteStoredRoomType(roomTypePlan.storedRoomTypePlanId)}>
                         <DeleteIcon/>
                       </IconButton>
                     </Grid>
                   </Grid>

                   <Grid item xs={8}
                    style={{
                      padding:'0 16px',
                      marginTop: 0,
                      marginBottom: 32,
                      display: roomPlanMasters[getRoomTypeIndex(roomTypePlan.roomTypeId)] && roomPlanMasters[getRoomTypeIndex(roomTypePlan.roomTypeId)].plans && roomPlanMasters[getRoomTypeIndex(roomTypePlan.roomTypeId)].plans.length > 0 ? '' : 'none'
                    }}
                   >
                     <Autocomplete
                       id="combo-box"
                       options={roomPlanMasters[getRoomTypeIndex(roomTypePlan.roomTypeId)].plans}
                       getOptionLabel={(option) => option.planNameForAdmin}
                       onChange={(event, newValue) => {
                         handlePlan(roomTypePlan.storedRoomTypePlanId, newValue);
                       }}
                       renderInput={(params) => <TextField {...params} required={false} label="プラン選択"/>}
                       className={classes.autocomplete}
                       getOptionSelected={(option, value) => option.planId === value.planId}
                     />
                   </Grid>
                   <Grid item xs={4}/>

                   <ValidatorForm
                     onSubmit={() => addStoredPlan(roomTypePlan.storedRoomTypePlanId)}
                     onError={
                       errors => {
                         console.log(errors);
                         enqueueSnackbar('入力エラーがあります。確認してください。', {variant : 'warning'});
                       }
                     }
                     className={classes.validatorForm}
                   >

                     <Grid container spacing={2} className={classes.container}>

                       <Grid item xs={3}>
                         <FormControl required={true} disabled={roomTypePlan.storedPlanId ? true : false}>
                           <FormLabel>ゲストタイプ</FormLabel>
                           <RadioGroup aria-label="position" name="position" defaultValue="adult" value={roomTypePlan.inputGuestType} onChange={(e,v) => handleChageStoredRoomTypePlan(roomTypePlan.storedRoomTypePlanId ,'inputGuestType',v)}>
                             <FormControlLabel
                               value="adult"
                               control={<Radio color="primary" size='small' />}
                               label={GUEST_TYPE.adult}
                             />
                             <FormControlLabel
                               value="child"
                               control={<Radio color="primary" size='small' />}
                               label={GUEST_TYPE.child}
                             />
                             <FormControlLabel
                               value="toddler"
                               control={<Radio color="primary" size='small' />}
                               label={GUEST_TYPE.toddler}
                             />
                           </RadioGroup>
                         </FormControl>
                       </Grid>

                       <Grid item xs={3}>
                         <FormControl required={true} disabled={roomTypePlan.storedPlanId ? true : false}>
                           <FormLabel>ベッド</FormLabel>
                           <RadioGroup aria-label="position" name="position" defaultValue="true" value={roomTypePlan.inputBed} onChange={(e,v) => handleChageStoredRoomTypePlan(roomTypePlan.storedRoomTypePlanId ,'inputBed',v)}>
                             <FormControlLabel
                               value='true'
                               control={<Radio color="primary" size='small' />}
                               label="有"
                             />
                             <FormControlLabel
                               value='false'
                               control={<Radio color="primary" size='small' />}
                               label="無"
                             />
                           </RadioGroup>
                         </FormControl>
                       </Grid>

                       <Grid item xs={3}>
                         <FormControl required={true} disabled={roomTypePlan.storedPlanId ? true : false}>
                           <FormLabel>夕食</FormLabel>
                           <RadioGroup aria-label="position" name="position" defaultValue="true" value={roomTypePlan.inputDinner} onChange={(e,v) => handleChageStoredRoomTypePlan(roomTypePlan.storedRoomTypePlanId ,'inputDinner',v)}>
                             <FormControlLabel
                               value='true'
                               control={<Radio color="primary" size='small' />}
                               label="有"
                             />
                             <FormControlLabel
                               value='false'
                               control={<Radio color="primary" size='small' />}
                               label="無"
                             />
                           </RadioGroup>
                         </FormControl>
                       </Grid>

                       <Grid item xs={3}>
                         <FormControl required={true} disabled={roomTypePlan.storedPlanId ? true : false}>
                           <FormLabel>朝食</FormLabel>
                           <RadioGroup aria-label="position" name="position" defaultValue="true" value={roomTypePlan.inputBreakfast} onChange={(e,v) => handleChageStoredRoomTypePlan(roomTypePlan.storedRoomTypePlanId ,'inputBreakfast',v)}>
                             <FormControlLabel
                               value='true'
                               control={<Radio color="primary" size='small' />}
                               label="有"
                             />
                             <FormControlLabel
                               value='false'
                               control={<Radio color="primary" size='small' />}
                               label="無"
                             />
                           </RadioGroup>
                         </FormControl>
                       </Grid>

                       <Grid item xs={4}>
                         <TextValidator
                           className={classes.textField}
                           name="inputUnitPrice"
                           label="単価 (1泊1名)"
                           value={roomTypePlan.inputUnitPrice}
                           margin="normal"
                           onChange={(e) => handleChageStoredRoomTypePlan(roomTypePlan.storedRoomTypePlanId ,'inputUnitPrice', e.target.value)}
                           type="number"
                           inputProps={{
                             step: 100
                           }}
                           validators={['isNumber', 'minNumber:1']}
                           errorMessages={['整数を入力してください', '1以上の数を入力してください']}
                           required={true}
                           style={{marginTop: 10}}
                           disabled={roomTypePlan.storedPlanId ? true : false}
                         />
                       </Grid>

                       <Grid item xs={2}>
                         <TextValidator
                           className={classes.textField}
                           name="inputAmount"
                           label="人数"
                           value={roomTypePlan.inputAmount}
                           margin="normal"
                           onChange={(e) => handleChageStoredRoomTypePlan(roomTypePlan.storedRoomTypePlanId ,'inputAmount', e.target.value)}
                           type="number"
                           inputProps={{
                             step: 1
                           }}
                           validators={['isNumber', 'minNumber:1']}
                           errorMessages={['整数を入力してください', '1以上の数を入力してください']}
                           required={true}
                           style={{marginTop: 10}}
                         />
                       </Grid>
                       <Grid item xs={3}/>

                       <Grid item xs={3}>
                         <Button type="submit" variant="contained" color="default" className={classes.button} style={{marginTop: 18}}>
                           プラン追加
                         </Button>
                       </Grid>

                     </Grid>

                   </ValidatorForm>

                   <Grid item xs={12} className={classes.warningMessageInnerPaper} style={{display: roomTypePlan.storedPlans.length > 0 ? 'none' : ''}}>
                    <Grid container direction="row" alignItems="center">
                      <Grid item>
                        <ErrorIcon className={classes.errorIcon}  />
                      </Grid>
                      <Grid item>
                        プランを入力してください
                      </Grid>
                    </Grid>
                   </Grid>


                   <Grid item xs={12} style={{display: roomTypePlan.storedPlans.length > 0 ? '' : 'none'}}>
                     <Table size='small' className={classes.lightBorderTable}>
                       <TableHead>
                         <TableRow className={classes.lightColorRow}>
                           <TableCell align="center"　colSpan={4}>プラン情報</TableCell>
                           <TableCell align="right" style={{minWidth:130}}>単価 (1泊1名)</TableCell>
                           <TableCell align="right" style={{minWidth:120}}>人数</TableCell>
                           <TableCell align="center"></TableCell>
                         </TableRow>
                       </TableHead>

                       <TableBody>

                        {roomTypePlan.storedPlans.map(plan => (
                          <TableRow hover key={plan.storedPlanId}>
                            <TableCell align="left" colSpan={4}>
                              <div>{plan.planNameForAdmin ? plan.planNameForAdmin : '[プラン手動入力]'}</div>
                              <div style={{fontSize: '0.9em', marginTop:4}}>{GUEST_TYPE[plan.guestType]} (ベッド: {plan.bed ? '有' : '無' } , 夕食: {plan.dinner ? '有' : '無' } , 朝食: {plan.breakfast ? '有' : '無' })</div>
                            </TableCell>
                            <TableCell align="right">¥ {Number(plan.unitPrice).toLocaleString()}</TableCell>
                            <TableCell align="right">{plan.amount}名</TableCell>
                            <TableCell align="center">
                              <IconButton size='small' aria-label="Delete" onClick={() => deleteStoredPlan(plan.storedPlanId)}>
                                <DeleteIcon/>
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}

                        <TableRow className={classes.darkColorRow}>
                          <TableCell align="center" style={{minWidth:100}} >プラン小計</TableCell>
                          <TableCell align="right" colSpan={3} >{getRoomTypePlanTotalAmount(roomTypePlan)}名様 1泊料金 : ¥ {getRoomTypePlanUnitPriceTotal(roomTypePlan).toLocaleString()}</TableCell>
                          <TableCell align="right">× {roomTypePlan.dateNum}泊</TableCell>
                          <TableCell align="right">¥ {getRoomTypePlanTotal(roomTypePlan).toLocaleString()}</TableCell>
                          <TableCell align="center"></TableCell>
                        </TableRow>

                       </TableBody>
                     </Table>
                   </Grid>
                 </Grid>
               </Paper>
              ))}


              <Grid item xs={12} style={{display: inputReservationType === 'direct' ? '' : 'none' }}>
                <ValidatorForm
                  onSubmit={() => {return}}
                  onError={
                    errors => {
                      console.log(errors);
                      enqueueSnackbar('入力エラーがあります。確認してください。', {variant : 'warning'});
                    }
                  }
                  className={classes.validatorForm}
                >
                  <Grid container spacing={2} className={classes.container}>

                    <Grid item xs={4}>
                      <TextValidator
                        className={classes.textField}
                        name="discount"
                        label={DISCOUNTS.discount}
                        value={inputDiscount}
                        margin="normal"
                        onChange={(e) => setInputDiscount(e.target.value)}
                        type="number"
                        inputProps={{
                          step: 100,
                          form: 'submitReservationForm'
                        }}
                        validators={['isNumber', 'minNumber:0']}
                        errorMessages={['整数を入力してください', '0以上の数を入力してください']}
                        required={inputReservationType === "direct" ? true : false}
                        style={{display: inputReservationType === 'direct' ? '' : 'none' }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextValidator
                        className={classes.textField}
                        name="additionalCharge"
                        label={ADDTIONAL_CHARGES.additionalCharge}
                        value={inputAdditionalCharge}
                        margin="normal"
                        onChange={(e) => setInputAdditionalCharge(e.target.value)}
                        type="number"
                        inputProps={{
                          step: 100,
                          form: 'submitReservationForm'
                        }}
                        validators={['isNumber', 'minNumber:0']}
                        errorMessages={['整数を入力してください', '0以上の数を入力してください']}
                        required={inputReservationType === "direct" ? true : false}
                        style={{display: inputReservationType === 'direct' ? '' : 'none' }}
                      />
                    </Grid>
                    <Grid item xs={4}/>

                  </Grid>
                </ValidatorForm>
              </Grid>


              <Grid item xs={12} style={{display: inputReservationType === 'jalan' ? '' : 'none' }}>
                <ValidatorForm
                  onSubmit={() => {return}}
                  onError={
                    errors => {
                      console.log(errors);
                      enqueueSnackbar('入力エラーがあります。確認してください。', {variant : 'warning'});
                    }
                  }
                  className={classes.validatorForm}
                >
                  <Grid container spacing={2} className={classes.container}>

                    <Grid item xs={4}>
                      <TextValidator
                        className={classes.textField}
                        name="jalanPoint"
                        label={DISCOUNTS.jalanPoint}
                        value={inputJalanPoint}
                        margin="normal"
                        onChange={(e) => setInputJalanPoint(e.target.value)}
                        type="number"
                        inputProps={{
                          step: 100,
                          form: 'submitReservationForm'
                        }}
                        validators={['isNumber', 'minNumber:0']}
                        errorMessages={['整数を入力してください', '0以上の数を入力してください']}
                        required={inputReservationType === "jalan" ? true : false}
                        style={{display: inputReservationType === 'jalan' ? '' : 'none' }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextValidator
                        className={classes.textField}
                        name="jalanCoupon"
                        label={DISCOUNTS.jalanCoupon}
                        value={inputJalanCoupon}
                        margin="normal"
                        onChange={(e) => setInputJalanCoupon(e.target.value)}
                        type="number"
                        inputProps={{
                          step: 100,
                          form: 'submitReservationForm'
                        }}
                        validators={['isNumber', 'minNumber:0']}
                        errorMessages={['整数を入力してください', '0以上の数を入力してください']}
                        required={inputReservationType === "jalan" ? true : false}
                        style={{display: inputReservationType === 'jalan' ? '' : 'none' }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextValidator
                        className={classes.textField}
                        name="jalanSubsidy"
                        label={DISCOUNTS.jalanSubsidy}
                        value={inputJalanSubsidy}
                        margin="normal"
                        onChange={(e) => setInputJalanSubsidy(e.target.value)}
                        type="number"
                        inputProps={{
                          step: 100,
                          form: 'submitReservationForm'
                        }}
                        validators={['isNumber', 'minNumber:0']}
                        errorMessages={['整数を入力してください', '0以上の数を入力してください']}
                        required={inputReservationType === "jalan" ? true : false}
                        style={{display: inputReservationType === 'jalan' ? '' : 'none' }}
                      />
                    </Grid>

                  </Grid>
                </ValidatorForm>
              </Grid>

              <Grid item xs={4}/>
              <Grid item xs={8}>
                <Table size='small' className={classes.darkBorderTable + ' ' + classes.plansTable}>
                  <TableBody>

                    {getPlansTable()}

                  </TableBody>
                </Table>
              </Grid>


              <ValidatorForm
                onSubmit={() => {return}}
                onError={
                  errors => {
                    console.log(errors);
                    enqueueSnackbar('入力エラーがあります。確認してください。', {variant : 'warning'});
                  }
                }
                className={classes.validatorForm}
              >
                <Grid container spacing={2} className={classes.container}>
                  <Grid item xs={12}>
                    <TextValidator
                      className={classes.textField}
                      name="reservationNote"
                      label="宿泊情報備考"
                      value={inputReservationNote}
                      margin="normal"
                      onChange={(e) => setInputReservationNote(e.target.value)}
                      required={false}
                      multiline={true}
                      rows={4}
                    />
                  </Grid>
                </Grid>
              </ValidatorForm>


            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper className={classes.paper} elevation={1} style={{backgroundColor: Boolean(storedRepeatCustomerId)? '#E0E0E0' : ''}}>
            <Grid container spacing={2} className={classes.container}>

              <Grid item xs={12}>
                <Typography variant="h5" gutterBottom>
                  顧客情報
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <ValidatorForm
                  onSubmit={() => {return}}
                  onError={
                    errors => {
                      console.log(errors);
                      enqueueSnackbar('入力エラーがあります。確認してください。', {variant : 'warning'});
                    }
                  }
                  className={classes.validatorForm}
                >

                  <Grid item xs={12} style={{margin: '0 0 30px 0'}}>
                    <FormControl required={true}>
                      <FormLabel>入力方法</FormLabel>
                      <RadioGroup aria-label="position" name="position" defaultValue="input" value={inputCustomerInfoInputMethod} onChange={(e,v) => setInputCustomerInfoInputMethod(v)}>
                        <FormControlLabel
                          value="input"
                          control={<Radio color="primary" size='small' />}
                          label="入力"
                        />
                        <FormControlLabel
                          value="url"
                          control={<Radio color="primary" size='small' />}
                          label="URL発行"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Grid container spacing={2} className={classes.container} style={{display: inputCustomerInfoInputMethod === 'url' ? 'none' : ''}}>

                    <Grid item xs={8}>
                      <Typography variant="h6" gutterBottom>
                        お名前
                      </Typography>
                    </Grid>

                    <Grid item xs={4}>
                      <Grid container spacing={2} className={classes.container} justify='flex-end'>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={Boolean(storedRepeatCustomerId)}
                              onChange={(e) => setStoredRepeatCustomerId('')}
                              name="isRepeatCustomer"
                              color="primary"
                            />
                          }
                          label="リピーター"
                          style={{paddingTop: 10, display: Boolean(storedRepeatCustomerId) ? "" : "none"}}
                        />

                        <Button
                          variant="contained"
                          color="default"
                          className={classes.button + ' ' + classes.buttonBottom}
                          style={{paddingTop: 10, display: Boolean(storedRepeatCustomerId) ? "none" : ""}}
                          onClick={() => setIsRepeatCustomerOpen(true)}
                        >
                          リピーター設定
                        </Button>

                      </Grid>
                    </Grid>

                    <Grid item xs={5}>
                      <TextValidator
                        className={classes.textField}
                        name="lastNameKana"
                        label="氏(カナ)"
                        value={inputLastNameKana}
                        margin="normal"
                        inputProps={{
                          form: 'submitReservationForm'
                        }}
                        onChange={(e) => setInputLastNameKana(e.target.value)}
                        onFocus ={() => setLastNameKanaOnFocus(true)}
                        onBlur  ={() => {
                          setLastNameKanaOnFocus(false)
                          toKatakana('inputLastNameKana')
                        }}
                        required={inputCustomerInfoInputMethod === 'input'}
                        validators={['required', 'matchRegexp:^[ァ-ンヴー]+$']}
                        errorMessages={['必須項目を入力してください', 'カタカナで入力してください']}
                        helperText="カタカナ入力"
                      />
                      <Paper
                        square
                        style={{
                          position: "relative",
                          zIndex : 1,
                          width: "600px",
                          maxHeight: "300px",
                          marginTop: "-30px",
                          overflow : "scroll",
                          display: lastNameKanaOnFocus && searchCustomersResultsForLastNameKana.length > 0 ? "" : "none",
                        }}
                      >
                        <div
                          style={{
                            color: "#0000008a",
                            margin: "10px"
                          }}
                        >
                          リピーター候補
                        </div>
                        {searchCustomersResultsForLastNameKana.map(n => (
                          <MenuItem
                            key={n.customerId}
                            onMouseDown={() => setStoredRepeatCustomerId(n.customerId)}
                          >
                            {n.lastName} {n.firstName}
                            <span
                              style={{
                                fontSize: "0.7em",
                                marginLeft: "18px",
                                marginRight: "4px",
                                marginTop: "3px",
                                color: "#0000008a",
                                varticalAlign: "bottom"
                              }}
                            >
                              住所:
                            </span>
                            <span
                              style={{
                                fontSize: "0.8em",
                                marginLeft: "2px",
                                marginTop: "2px",
                                varticalAlign: "middle"
                              }}
                            >
                              {n.addressPrefectures} {n.addressCity}
                            </span>
                             <span
                              style={{
                                fontSize: "0.7em",
                                marginLeft: "18px",
                                marginRight: "4px",
                                marginTop: "3px",
                                color: "#0000008a",
                                varticalAlign: "bottom"
                              }}
                            >
                              前回宿泊日:
                            </span>
                            <span
                              style={{
                                fontSize: "0.8em",
                                marginLeft: "2px",
                                marginTop: "2px",
                                varticalAlign: "middle"
                              }}
                            >
                              {moment(n.reservationStartDateHistory[0]).format("YYYY年M月D日(ddd)")}
                            </span>
                          </MenuItem>
                        ))}
                      </Paper>
                    </Grid>

                    <Grid item xs={5}>
                      <TextValidator
                        className={classes.textField}
                        name="firstNameKana"
                        label="名（カナ）"
                        value={inputFirstNameKana}
                        margin="normal"
                        inputProps={{
                          form: 'submitReservationForm'
                        }}
                        onChange={(e) => setInputFirstNameKana(e.target.value)}
                        onBlur  ={() => {toKatakana('inputFirstNameKana')}}
                        required={inputCustomerInfoInputMethod === 'input'}
                        validators={['required', 'matchRegexp:^[ァ-ンヴー]+$']}
                        errorMessages={['必須項目を入力してください', 'カタカナで入力してください']}
                        helperText="カタカナ入力"
                      />
                    </Grid>

                    <Grid item xs={5}>
                      <TextValidator
                        className={classes.textField}
                        name="lastName"
                        label="氏"
                        value={inputLastName}
                        margin="normal"
                        inputProps={{
                          form: 'submitReservationForm'
                        }}
                        onChange={(e) => setInputLastName(e.target.value)}
                        onFocus ={() => setLastNameOnFocus(true)}
                        onBlur  ={() => setLastNameOnFocus(false)}
                        required={inputCustomerInfoInputMethod === 'input'}
                        validators={['required']}
                        errorMessages={['必須項目を入力してください']}
                        helperText={storedLastNameslist.length > 0 ? "氏の候補があります" : ""}
                      />
                      <Paper
                        square
                        style={{
                          position: "relative",
                          zIndex : 1,
                          width: "600px",
                          maxHeight: "300px",
                          marginTop: "-30px",
                          overflow : "scroll",
                          display: lastNameOnFocus && storedLastNameslist.length > 0 ? "" : "none",
                        }}
                      >
                      <div
                        style={{
                          color: "#0000008a",
                          margin: "10px"
                        }}
                      >
                        氏の候補
                        <span
                          style={{
                            fontSize: "0.8em",
                            marginLeft: "3px"
                          }}
                        >
                          (世帯数の多い苗字順に表示されます)
                        </span>
                      </div>
                      {storedLastNameslist.map(n => (
                        <MenuItem
                          key={n}
                          onMouseDown={() => setInputLastName(n)}
                        >
                          {n}
                        </MenuItem>
                      ))}
                      </Paper>
                    </Grid>

                    <Grid item xs={5}>
                      <TextValidator
                        className={classes.textField}
                        name="firstName"
                        label="名"
                        value={inputFirstName}
                        margin="normal"
                        inputProps={{
                          form: 'submitReservationForm'
                        }}
                        onChange={(e) => setInputFirstName(e.target.value)}
                        required={inputCustomerInfoInputMethod === 'input'}
                        validators={['required']}
                        errorMessages={['必須項目を入力してください']}
                      />
                    </Grid>


                    <Grid item xs={12}/>
                    <Grid item xs={12}>
                      <Typography variant="h6">
                        ご住所
                      </Typography>
                    </Grid>

                    <Grid item xs={4}>
                      <TextValidator
                        className={classes.textField}
                        name="postNum"
                        label="〒 郵便番号"
                        value={inputPostNum}
                        margin="normal"
                        onChange={(e) => typedPostNum(e.target.value)}
                        onBlur={(e) => changeChar(e, 'postNum')}
                        required={false}
                        validators={['matchRegexp:^\\d{7}$']}
                        errorMessages={['郵便番号が間違っています']}
                        helperText="ハイフンなし"
                      />
                    </Grid>
                    <Grid item xs={8}/>

                    <Grid item xs={5}>
                      <TextValidator
                        className={classes.textField}
                        name="addressPrefectures"
                        label="都道府県"
                        value={inputAddressPrefectures}
                        margin="normal"
                        onChange={(e) => setInputAddressPrefectures(e.target.value)}
                      />
                    </Grid>

                    <Grid item xs={5}>
                      <TextValidator
                        className={classes.textField}
                        name="addressCity"
                        label="市区町村"
                        value={inputAddressCity}
                        margin="normal"
                        onChange={(e) => setInputAddressCity(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={2}/>

                    <Grid item xs={10}>
                      <TextValidator
                        className={classes.textField}
                        name="addressOther"
                        label="以降の住所"
                        value={inputAddressOther}
                        margin="normal"
                        onChange={(e) => setInputAddressOther(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={2}/>


                    <Grid item xs={12}/>
                    <Grid item xs={12}>
                      <Typography variant="h6">
                        ご連絡先
                      </Typography>
                    </Grid>


                    <Grid item xs={5}>
                      <TextValidator
                        className={classes.textField}
                        name="tel1"
                        label="電話番号1"
                        value={inputTel1}
                        margin="normal"
                        onChange={(e) => setInputTel1(e.target.value)}
                        onBlur={(e) => changeChar(e, 'tel1')}
                        type="tel"
                        required={false}
                        validators={['matchRegexp:^(0{1}\\d{9,10})$']}
                        errorMessages={['電話番号が間違っています']}
                        helperText="ハイフンなし"
                      />
                    </Grid>


                    <Grid item xs={5}>
                      <TextValidator
                        className={classes.textField}
                        name="tel2"
                        label="電話番号2"
                        value={inputTel2}
                        margin="normal"
                        onChange={(e) => setInputTel2(e.target.value)}
                        onBlur={(e) => changeChar(e, 'tel2')}
                        type="tel"
                        required={false}
                        validators={['matchRegexp:^(0{1}\\d{9,10})$']}
                        errorMessages={['電話番号が間違っています']}
                        helperText="ハイフンなし"
                      />
                    </Grid>
                    <Grid item xs={2}/>


                    <Grid item xs={6}>
                      <TextValidator
                        className={classes.textField}
                        name="email"
                        label="email"
                        value={inputEmail}
                        margin="normal"
                        onChange={(e) => setInputEmail(e.target.value)}
                        required={false}
                        validators={['isEmail']}
                        errorMessages={['emailアドレスが間違っています']}
                      />
                    </Grid>
                    <Grid item xs={6}/>


                    <Grid item xs={12}>
                      <TextValidator
                        className={classes.textField}
                        name="customerNote"
                        label="顧客情報備考"
                        value={inputCustomerNote}
                        margin="normal"
                        onChange={(e) => setInputCustomerNote(e.target.value)}
                        required={false}
                        multiline={true}
                        rows={4}
                      />
                    </Grid>

                  </Grid>
                </ValidatorForm>
              </Grid>

            </Grid>
          </Paper>
        </Grid>


        <Grid item xs={12} className={classes.paperWidth}>
          <Grid container spacing={2} className={classes.container}>

            <Grid item xs={12}>
              <ValidatorForm
                onSubmit={() => receiveReservation()}
                onError={
                  errors => {
                    console.log(errors);
                    enqueueSnackbar('入力エラーがあります。確認してください。', {variant : 'warning'});
                  }
                }
                className={classes.validatorForm}
                id='submitReservationForm'
              >
                <Grid container spacing={2} className={classes.container}>

                  <Grid item xs={5}/>

                  <Grid item xs={4}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={inputIsNotUseSiteController}
                          onChange={(e) => setInputIsNotUseSiteController(e.target.checked)}
                          name="isNotDecreaseJalan"
                          color="primary"
                        />
                      }
                      label="じゃらん在庫変更なし"
                      style={{marginTop: 8, display: (inputReservationType === 'direct' && jalanId) ? '' : 'none'}}
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <Button type="submit" variant="contained" color="primary" className={classes.button}>
                      予約を作成
                    </Button>
                  </Grid>

                </Grid>
              </ValidatorForm>
            </Grid>

          </Grid>
        </Grid>


      </Grid>
      <div style={{fontSize:5, textAlign:'right', margin:20}}>名字候補 出典 <a style={{fontSize:5}} href='https://myoji-yurai.net' target='_blank' rel='noopener noreferrer'>名字由来net</a></div>



      <Dialog
        open={isRepeatCustomerOpen}
        onClose={() => setIsRepeatCustomerOpen(false)}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle id="scroll-dialog-title">顧客検索</DialogTitle>
        <DialogContent>

          <Grid item xs={12}>
            <ValidatorForm
              onSubmit={() => searchRepeatCustomer()}
              onError={
                errors => {
                  console.log(errors);
                  enqueueSnackbar('入力エラーがあります。確認してください。', {variant : 'warning'});
                }
              }
              className={classes.validatorForm}
            >
              <Grid container spacing={2} className={classes.container}>
                <Grid item xs={5}>
                  <TextValidator
                    className={classes.textField}
                    name="lastNameKanaForSearch"
                    label="氏(カナ)"
                    value={inputLastNameKanaForSearch}
                    margin="normal"
                    onChange={(e) => setInputLastNameKanaForSearch(e.target.value)}
                    onBlur  ={() => {toKatakana('inputLastNameKanaForSearch')}}
                    required={false}
                    validators={['matchRegexp:^[ァ-ンヴー]+$']}
                    errorMessages={['カタカナで入力してください']}
                    helperText="カタカナ入力"
                  />
                </Grid>

                <Grid item xs={5}>
                  <TextValidator
                    className={classes.textField}
                    name="firstNameKanaForSearch"
                    label="名（カナ）"
                    value={inputFirstNameKanaForSearch}
                    margin="normal"
                    onChange={(e) => setInputFirstNameKanaForSearch(e.target.value)}
                    onBlur  ={() => {toKatakana('inputFirstNameKanaForSearch')}}
                    required={false}
                    validators={['matchRegexp:^[ァ-ンヴー]+$']}
                    errorMessages={['カタカナで入力してください']}
                    helperText="カタカナ入力"
                  />
                </Grid>


                <Grid item xs={5}>
                  <TextValidator
                    className={classes.textField}
                    name="telForSearch"
                    label="電話番号"
                    value={inputTelForSearch}
                    margin="normal"
                    onChange={(e) => setInputTelForSearch(e.target.value)}
                    onBlur={(e) => changeChar(e, 'telForSearch')}
                    type="tel"
                    required={false}
                    validators={['matchRegexp:^(0{1}\\d{9,10})$']}
                    errorMessages={['電話番号が間違っています']}
                    helperText="ハイフンなし"
                  />
                </Grid>
                <Grid item xs={7}/>

                <Grid item xs={6}>
                  <TextValidator
                    className={classes.textField}
                    name="emailForSearch"
                    label="email"
                    value={inputEmailForSearch}
                    margin="normal"
                    onChange={(e) => setInputEmailForSearch(e.target.value)}
                    required={false}
                    validators={['isEmail']}
                    errorMessages={['emailアドレスが間違っています']}
                  />
                </Grid>
                <Grid item xs={6}/>

                <Grid item xs={9}/>
                <Grid item xs={3}>
                  <Button
                    type='submit'
                    variant="contained"
                    color="default"
                    className={classes.button + ' ' + classes.buttonBottom}
                  >
                    検索
                  </Button>
                </Grid>

                <Grid container spacing={0} className={classes.deleteInPaper} justify='flex-end'>
                  入力された項目の前方一致で、絞り込み検索を行います。
                </Grid>

              </Grid>
            </ValidatorForm>
          </Grid>

          <Grid container spacing={0} className={classes.deleteInPaper} justify='flex-end'>
            <Chip
              label={"該当件数:　　　" + searchCustomersResults.length}
              className={classes.chip}
              style={{display: searchCustomersResults.length > 0 ? '' : 'none'}}
            />
          </Grid>

          {searchCustomersResults.map(n =>
            {
              return (
                <Fragment key={n.customerId}>
                  <Grid item xs={9}>
                    <Card className={classes.card}>
                      <CardActionArea onClick={() => setStoredRepeatCustomerId(n.customerId)}>
                        <CardContent>
                          <Grid container spacing={0} className={classes.deleteInPaper}>

                            <Grid item xs={12}>
                              <Typography variant="subtitle2" gutterBottom>
                                {n.lastNameKana} {n.firstNameKana}
                              </Typography>
                            </Grid>

                            <Grid item xs={12}>
                              <Typography variant="h5">
                                {n.lastName} {n.firstName} <span style={{marginLeft: 10, fontSize:"0.8em"}}>様</span>
                              </Typography>
                            </Grid>

                            <Grid item xs={2} className={classes.marginTop2}>
                              <Typography variant="caption">
                                住所
                              </Typography>
                            </Grid>

                            <Grid item xs={10} className={classes.marginTop2}>
                              <Typography variant="body1">
                                〒 {n.postNum ? n.postNum.slice(0, 3) : ""}{n.postNum ? "-" : ""}{n.postNum ? n.postNum.slice(4, 7): ""}<br/>
                                {n.addressPrefectures} {n.addressCity} {n.addressOther}
                              </Typography>
                            </Grid>

                            <Grid item xs={2} className={classes.marginTop1}>
                              <Typography variant="caption">
                                電話番号1
                              </Typography>
                            </Grid>
                            <Grid item xs={10} className={classes.marginTop1}>
                              <Typography variant="body1">
                                {n.tel1}
                              </Typography>
                            </Grid>

                            <Grid item xs={2} className={classes.marginTop1}>
                              <Typography variant="caption">
                                電話番号2
                              </Typography>
                            </Grid>
                            <Grid item xs={10} className={classes.marginTop1}>
                              <Typography variant="body1">
                                {n.tel2}
                              </Typography>
                            </Grid>

                            <Grid item xs={2} className={classes.marginTop1}>
                              <Typography variant="caption">
                                email
                              </Typography>
                            </Grid>
                            <Grid item xs={10} className={classes.marginTop1}>
                              <Typography variant="body1">
                                {n.email}
                              </Typography>
                            </Grid>

                            <Grid item xs={2} className={classes.marginTop1}>
                              <Typography variant="caption">
                                備考
                              </Typography>
                            </Grid>
                            <Grid item xs={10} className={classes.marginTop1}>
                              <Typography variant="body1">
                                {n.customerNote ? nl2brJsx(n.customerNote) : ''}
                              </Typography>
                            </Grid>

                            <Grid item xs={2} className={classes.marginTop1}>
                              <Typography variant="caption">
                                宿泊履歴
                              </Typography>
                            </Grid>
                            <Grid item xs={10} className={classes.marginTop1}>
                              <Typography variant="body1">
                                {
                                  n.reservationStartDateHistory.map((date, i) => {
                                    if(i < 5){
                                      return(
                                        <Fragment key={i}>
                                          {moment(date).format("YYYY年M月D日(ddd)")}<br/>
                                        </Fragment>
                                      )
                                    }
                                    return (
                                      <Fragment key={i}></Fragment>
                                    )
                                  })
                                }
                              </Typography>
                            </Grid>

                          </Grid>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                  <Grid item xs={3}/>
                </Fragment>
              );
            }
          )}

        </DialogContent>
      </Dialog>


      <Dialog
        open={isWaitingSiteController}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          じゃらん連携中
        </DialogTitle>
        <DialogContent style={{textAlign: "center",marginTop: 20}}>
          <CircularProgress color="inherit"/>
          <DialogContentText id="alert-dialog-description" style={{textAlign: "left", marginTop: 30}}>
            ページを閉じないでください<br/><br/>
            じゃらんの在庫の確認と変更を行っています<br/>
            この処理には数分かかる場合があります<br/><br/>
            しばらくおまちください...
          </DialogContentText>
        </DialogContent>
      </Dialog>


    </Fragment>
  );
}
