import React, { Fragment } from 'react';
import moment from 'moment';

const GUEST_TYPE = {
  adult: "大人",
  child: "子供",
  toddler: "幼児",
}

export default class ReservationDataSheet extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      targetReservation: null
    };
  }

  componentDidUpdate(prevProps){

    if(this.props.targetReservation !== prevProps.targetReservation){
      this.setState({
        targetReservation: this.props.targetReservation
      },() => {
        // console.log(this.state.targetReservation)
      })
    }
  }


  nl2plusJsx = (str) => {
    let lines = [];
    let array = str.split("\n");
    for(let i in array){
      lines.push(<Fragment key={"lines" + i}>{array[i]}+++</Fragment>);
    }
    return lines;
  }


  omittedContent = (string) => {
    // 定数で宣言
    const MAX_LENGTH = 15

    // もしstringの文字数がMAX_LENGTH（今回は10）より大きかったら末尾に...を付け足して返す。
    if (string.length > MAX_LENGTH) {

      // substr(何文字目からスタートするか, 最大値);
      return string.substr(0, MAX_LENGTH) + '...';
    }
    //　文字数がオーバーしていなければそのまま返す
    return string;
  }



  getDateRange = (reservation) => {

    let dates = reservation.overnights.map(overnight => {
      return overnight.date
    })

    dates.sort((a, b) => moment(a).diff(moment(b)));

    return {
      start: moment(dates[0]).format('YYYY-MM-DD'),
      end: moment(dates[dates.length -1]).add(1, 'days').format('YYYY-MM-DD')
    }
  }


  getGuestCount = (reservation) => {

    let adultCount = 0
    let childCount = 0
    let toddlerCount = 0

    reservation.roomTypePlans.map(roomTypePlan => {
      roomTypePlan.storedPlans.map(plan => {

        if(plan.guestType === 'adult'){
          adultCount += Number(plan.amount)
        }

        if(plan.guestType === 'child'){
          childCount += Number(plan.amount)
        }

        if(plan.guestType === 'toddler'){
          toddlerCount += Number(plan.amount)
        }

        return null
      })
      return null
    })

    return {
      adult: adultCount,
      child: childCount,
      toddler: toddlerCount,
      total: adultCount + childCount + toddlerCount
    }
  }




  getReservationTotalWithoutCharge = (reservation) => {
    let plansTotal = 0

    this.state.targetReservation.roomTypePlans.map(roomTypePlan => {
      roomTypePlan.storedPlans.map(storedPlan => {
        plansTotal += storedPlan.amount * storedPlan.unitPrice
        return null
      })
      plansTotal = plansTotal * Number(roomTypePlan.dateNum)
      return null
    })

    return plansTotal
  }


  getReservationTotal = (reservation) => {
    let plansTotal = 0

    if(this.state.targetReservation.methodOfPaymentPlans === 'jalan'){
      return plansTotal
    }

    this.state.targetReservation.roomTypePlans.map(roomTypePlan => {
      roomTypePlan.storedPlans.map(storedPlan => {
        plansTotal += storedPlan.amount * storedPlan.unitPrice
        return null
      })
      plansTotal = plansTotal * Number(roomTypePlan.dateNum)
      return null
    })


    if(
      this.state.targetReservation.charges &&
      reservation.charges.additionalCharges &&
      reservation.charges.additionalCharges.additionalCharge &&
      reservation.charges.additionalCharges.additionalCharge.price !== 0
    ){
      plansTotal += reservation.charges.additionalCharges.additionalCharge.price
    }

    if(
      reservation.charges &&
      reservation.charges.discounts &&
      reservation.charges.discounts.discount &&
      reservation.charges.discounts.discount.price !== 0
    ){
      plansTotal -= reservation.charges.discounts.discount.price
    }

    if(
      reservation.charges &&
      reservation.charges.discounts &&
      reservation.charges.discounts.jalanPoint &&
      reservation.charges.discounts.jalanPoint.price !== 0
    ){
      plansTotal -= reservation.charges.discounts.jalanPoint.price
    }

    if(
      reservation.charges &&
      reservation.charges.discounts &&
      reservation.charges.discounts.jalanCoupon &&
      reservation.charges.discounts.jalanCoupon.price !== 0
    ){
      plansTotal -= reservation.charges.discounts.jalanPoint.price
    }

    if(
      reservation.charges &&
      reservation.charges.discounts &&
      reservation.charges.discounts.jalanSubsidy &&
      reservation.charges.discounts.jalanSubsidy.price !== 0
    ){
      plansTotal -= reservation.charges.discounts.jalanSubsidy.price
    }

    return plansTotal
  }


  getDiscountTotal = (reservation) => {
    let discountTotal = 0

    if(
      reservation.charges &&
      reservation.charges.discounts &&
      reservation.charges.discounts.discount &&
      reservation.charges.discounts.discount.price !== 0
    ){
      discountTotal += reservation.charges.discounts.discount.price
    }

    if(
      reservation.charges &&
      reservation.charges.discounts &&
      reservation.charges.discounts.jalanPoint &&
      reservation.charges.discounts.jalanPoint.price !== 0
    ){
      discountTotal += reservation.charges.discounts.jalanPoint.price
    }

    if(
      reservation.charges &&
      reservation.charges.discounts &&
      reservation.charges.discounts.jalanCoupon &&
      reservation.charges.discounts.jalanCoupon.price !== 0
    ){
      discountTotal += reservation.charges.discounts.jalanPoint.price
    }

    if(
      reservation.charges &&
      reservation.charges.discounts &&
      reservation.charges.discounts.jalanSubsidy &&
      reservation.charges.discounts.jalanSubsidy.price !== 0
    ){
      discountTotal += reservation.charges.discounts.jalanSubsidy.price
    }

    return discountTotal
  }


  getReservationTotalJalanPaid = (reservation) => {

    let reservationAfter = JSON.parse(JSON.stringify(reservation))

    reservationAfter.methodOfPaymentPlans = ''

    let plansTotalJalanPaid = this.getReservationTotal(reservationAfter)

    return plansTotalJalanPaid
  }


  getReservationTotalInnerTax = (reservation) => {
    if(reservation.methodOfPaymentPlans === 'jalan'){
      return 0
    }

    let taxRate = reservation.charges.taxRate

    return Math.floor(this.getReservationTotal(reservation) * taxRate / (1 + taxRate))
  }


  render() {
    if(this.state.targetReservation){
      return (
        <div className="reservationSheet">
          <link rel="stylesheet" href="../css/ReservationDataSheet.css" />
          <section className="sheet">
              <div className="row_1">
                  <h1 className="text-center">予約情報</h1>
              </div>
              <div className="row_3">
                  <div className="col_1">
                      <ul>
                          <li>
                              <h2 className="sectionTitle">宿泊者情報</h2>
                          </li>
                          <br/>
                          <li>{this.state.targetReservation.lastNameKana ? this.state.targetReservation.lastNameKana : ""} {this.state.targetReservation.firstNameKana ? this.state.targetReservation.firstNameKana : ""}</li>
                          <li className="name" >{this.state.targetReservation.lastName} {this.state.targetReservation.firstName} 様</li>
                          <br/>
                          <li>〒{this.state.targetReservation.postNum.substr(0,3)}-{this.state.targetReservation.postNum.substr(3,4)}</li>
                          <li>{this.state.targetReservation.addressPrefectures} {this.state.targetReservation.addressCity} {this.state.targetReservation.address_other}</li>
                          <li>{this.state.targetReservation.addressOther ? this.state.targetReservation.addressOther : ""}</li>
                          <br/>
                          <li>電話番号1: {this.state.targetReservation.tel1 ? this.state.targetReservation.tel1 : ""}</li>
                          {this.state.targetReservation.tel2 && <li>電話番号2: {this.state.targetReservation.tel2}</li>}
                          {this.state.targetReservation.email && <br/>}
                          {this.state.targetReservation.email && <li>mail: {this.state.targetReservation.email}</li>}
                          {(this.state.targetReservation.reservationDateHistory && this.state.targetReservation.reservationDateHistory[1]) && <br/>}
                          {(this.state.targetReservation.reservationDateHistory && this.state.targetReservation.reservationDateHistory[1]) && <li>前回宿泊日: {moment(this.state.targetReservation.reservationDateHistory[1]).format('YYYY年M月D日')}</li>}
                          {this.state.targetReservation.customerNote && <br/>}
                          {this.state.targetReservation.customerNote && <li>備考: {this.nl2plusJsx(this.state.targetReservation.customerNote)}</li>}
                      </ul>
                  </div>
                  <div className="col_2">
                    <ul>
                      <li
                        style={{
                          display:'flex',
                          alignItems: 'flex-end',
                          justifyContent: 'space-between'
                        }}
                      >
                        <h2 className="sectionTitle" style={{width:200}}>プラン情報</h2>
                        <p className="caution">
                          ※すべての部屋ごとの予約内容が表示されない場合がございます(最大2列まで表示)
                        </p>
                      </li>


                      <div className="reservationSummary" style={{marginTop:16}}>
                        <div className="reservationSummaryItem"><span className="reservationSummaryItemLabel">IN:</span> {moment(this.getDateRange(this.state.targetReservation).start).format('YYYY年M月D日')}</div>
                        <div className="reservationSummaryItem"><span className="reservationSummaryItemLabel">OUT:</span> {moment(this.getDateRange(this.state.targetReservation).end).format('YYYY年M月D日')}</div>
                      </div>

                      <div className="reservationSummary" style={{marginTop:2}}>
                        <div className="reservationSummaryItem"><span className="reservationSummaryItemLabel">合計人数:</span>{this.getGuestCount(this.state.targetReservation).total}名</div>
                        <div className="reservationSummaryItem" style={{marginLeft:24}}><span className="reservationSummaryItemLabel">大人:</span>{this.getGuestCount(this.state.targetReservation).adult}名</div>
                        <div className="reservationSummaryItem"><span className="reservationSummaryItemLabel">子供:</span>{this.getGuestCount(this.state.targetReservation).child}名</div>
                        <div className="reservationSummaryItem"><span className="reservationSummaryItemLabel">幼児:</span>{this.getGuestCount(this.state.targetReservation).toddler}名</div>
                      </div>


                      <div className="tables">

                        <table className="mainTable">

                          <tbody key={this.state.targetReservation.reservation_id}>

                            <tr className="tableGrayBackgroundTr">
                              <td colSpan="5">宿泊料小計</td>
                              <td>￥ {this.getReservationTotalWithoutCharge(this.state.targetReservation).toLocaleString()}</td>
                            </tr>

                            {
                              this.state.targetReservation.charges &&
                              this.state.targetReservation.charges.additionalCharges &&
                              this.state.targetReservation.charges.additionalCharges.additionalCharge &&
                              this.state.targetReservation.charges.additionalCharges.additionalCharge.price !== 0 &&
                              (
                                <tr>
                                  <td colSpan="5">{this.state.targetReservation.charges.additionalCharges.additionalCharge.name}</td>
                                  <td>¥ {this.state.targetReservation.charges.additionalCharges.additionalCharge.price.toLocaleString()}</td>
                                </tr>
                              )
                            }


                            {
                              this.state.targetReservation.charges &&
                              this.state.targetReservation.charges.discounts &&
                              this.state.targetReservation.charges.discounts.discount &&
                              this.state.targetReservation.charges.discounts.discount.price !== 0 &&
                              (
                                <tr>
                                  <td colSpan="5">{this.state.targetReservation.charges.discounts.discount.name}</td>
                                  <td>- ¥ {this.state.targetReservation.charges.discounts.discount.price.toLocaleString()}</td>
                                </tr>
                              )
                            }


                            {
                              this.state.targetReservation.charges &&
                              this.state.targetReservation.charges.discounts &&
                              this.state.targetReservation.charges.discounts.jalanPoint &&
                              this.state.targetReservation.charges.discounts.jalanPoint.price !== 0 &&
                              (
                                <tr>
                                  <td colSpan="5">{this.state.targetReservation.charges.discounts.jalanPoint.name}</td>
                                  <td>- ¥ {this.state.targetReservation.charges.discounts.jalanPoint.price.toLocaleString()}</td>
                                </tr>
                              )
                            }

                            {
                              this.state.targetReservation.charges &&
                              this.state.targetReservation.charges.discounts &&
                              this.state.targetReservation.charges.discounts.jalanCoupon &&
                              this.state.targetReservation.charges.discounts.jalanCoupon.price !== 0 &&
                              (
                                <tr>
                                  <td colSpan="5">{this.state.targetReservation.charges.discounts.jalanCoupon.name}</td>
                                  <td>- ¥ {this.state.targetReservation.charges.discounts.jalanCoupon.price.toLocaleString()}</td>
                                </tr>
                              )
                            }

                            {
                              this.state.targetReservation.charges &&
                              this.state.targetReservation.charges.discounts &&
                              this.state.targetReservation.charges.discounts.jalanSubsidy &&
                              this.state.targetReservation.charges.discounts.jalanSubsidy.price !== 0 &&
                              (
                                <tr>
                                  <td colSpan="5">{this.state.targetReservation.charges.discounts.jalanSubsidy.name}</td>
                                  <td>- ¥ {this.state.targetReservation.charges.discounts.jalanSubsidy.price.toLocaleString()}</td>
                                </tr>
                              )
                            }

                            {
                              this.getDiscountTotal(this.state.targetReservation) !== 0 &&
                              (
                                <tr className="tableGrayBackgroundTr">
                                  <td colSpan="5">割引額小計</td>
                                  <td>- ￥ {this.getDiscountTotal(this.state.targetReservation).toLocaleString()}</td>
                                </tr>
                              )
                            }


                            {
                              this.state.targetReservation.methodOfPaymentPlans === 'jalan' &&
                              (
                                <tr>
                                  <td colSpan="5">じゃらんお支払済み金額</td>
                                  <td>- ￥ {this.getReservationTotalJalanPaid(this.state.targetReservation).toLocaleString()}</td>
                                </tr>
                              )
                            }


                            <tr className="tableGrayBackgroundTr">
                              <td colSpan="5"  className="tableBoldTd">合計 (税込)</td>
                              <td className="tableBoldTd">¥ {this.getReservationTotal(this.state.targetReservation).toLocaleString()}</td>
                            </tr>

                            <tr>
                              <td colSpan="5">( 内消費税等	</td>
                              <td>¥ {this.getReservationTotalInnerTax(this.state.targetReservation).toLocaleString()} )</td>
                            </tr>

                          </tbody>
                        </table>


                        {this.state.targetReservation.roomTypePlans.map(roomTypePlan => (

                          <table className="planTable" key={roomTypePlan.storedRoomTypePlanId}>

                            <thead>

                              <tr>
                                <th colSpan="3">{roomTypePlan.roomTypeName} {moment(roomTypePlan.startDate).format('YYYY年M月D月')} から {roomTypePlan.dateNum}泊</th>
                              </tr>

                            </thead>

                            <tbody>

                              {roomTypePlan.storedPlans.map(plan => (
                                <tr key={plan.storedPlanId}>
                                 <td className="tablePlan" >
                                  <div>
                                    {!plan.planNameForAdmin && <Fragment>[プラン手動入力]</Fragment>}
                                    {plan.planNameForAdmin && this.omittedContent(plan.planNameForAdmin)}
                                    <br/>
                                    {GUEST_TYPE[plan.guestType]} (ベッド: {plan.bed ? '有' : '無' } , 夕食: {plan.dinner ? '有' : '無' } , 朝食: {plan.breakfast ? '有' : '無' })
                                  </div>
                                 </td>
                                 <td>¥ {plan.unitPrice.toLocaleString()}</td>
                                 <td>{plan.amount}名</td>
                                </tr>
                              ))}

                              <tr>
                               <td colSpan="2">プラン小計</td>
                               <td>¥ {this.getReservationTotalWithoutCharge(roomTypePlan).toLocaleString()}</td>
                              </tr>

                            </tbody>

                          </table>


                        ))}

                      </div>

                      {this.state.targetReservation.reservationNote && <Fragment>備考: {this.nl2plusJsx(this.state.targetReservation.reservationNote)}</Fragment>}

                    </ul>
                  </div>
                </div>
          </section>
        </div>
      );
    }else{
      return (
        <div>
          印刷する予約が選択されていません
        </div>
      )
    }
  }

}
