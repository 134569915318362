import React, { useState, useEffect } from 'react'
import { Route, Redirect } from "react-router-dom"
import LinearProgress from '@material-ui/core/LinearProgress';

import AuthService from '../services/AuthService'  // ログインチェックを行うサービス.

export default function PrivateRoute(props){

  // ログインチェックが終わるまで、ローディングを表示.
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    let unmounted = false;

    //非同期無名関数の即時呼び出し
    (async()=>{
      const authApi = new AuthService()
      const result = await authApi.isLogin()

      //アンマウントされていなければステートを更新
      if(!unmounted) {
        setIsAuthenticated(result)
        setLoading(false)
      };

    })();

    //クリーンアップ関数を返す
    return ()=>{ unmounted = true; };
  },[]);

  // ルーティング情報を取得.
  const { component : Component, ...rest } = props

  // ログインチェック前なら、ローディングを表示.
  if (loading) {
    return (
      <div>
        <br/>
        <LinearProgress/>
      </div>
    );
  }

  //ニックネーム（ユーザ名）のセット 直接遷移にも対応するためにここでやる
  props.handleSetNickname()

  // ログインチェック後はルーティング処理を行う.
  return (
    <Route {...rest} render={() => {

      if (!isAuthenticated) {
        // 未ログインなら、ログイン画面にリダイレクト.
        return <Redirect to={{ pathname: '/login', state: { from: rest.location } }} />

      }else{
        // ログイン済なら、指定されたコンポーネントを表示.
        return <Component {...rest} />
      }

    }}/>
  )
}
