import { createMuiTheme } from '@material-ui/core/styles'
import {jaJP} from '@material-ui/core/locale';

export const theme = createMuiTheme({
  "palette": {
    "common": {
      "black": "#000",
      "white": "#fff"
    },
    "background": {
      "paper": "#fff",
      "default": "#fafafa"
    },
    "primary": {
      "light": "#80D1D1",
      "main": "#00a2a2",
      "dark": "#009090",
      "contrastText": "#fff"
    },
    "secondary": {
      "light1": "#f1f1f1",
      "light2": "#c7c7c7",
      "light": "#E0E0E0",
      "main": "#969696",
      "dark": "#686868",
      "contrastText": "#fff"
    },
  }
}, jaJP);

export default theme;
