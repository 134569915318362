import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";

import Icon from 'react-icons-kit';
import { eye, eyeSlash} from 'react-icons-kit/fa/'

import AuthService from '../services/AuthService'


export default function Login(props){

  const authApi = new AuthService()
  const history = useHistory();

  const [user, setUser] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [visiblePassword, setVisiblePassword] = useState(false)
  const [newPassword, setNewPassword] = useState("")
  const [visibleNewPassword, setVisibleNewPassword] = useState(false)
  const [retypeNewPassword, setRetypeNewPassword] = useState("")
  const [visibleRetypeNewPassword, setVisibleRetypeNewPassword] = useState(false)
  const [nickname, setNickname] = useState("")
  const [code, setCode] = useState("")
  const [error, setError] = useState("")
  const [loginFllowState, setLoginFllowState] = useState("")
  const [completeMessage, setCompleteMessage] = useState("")

  const mailCode = props.match.params.mailCode

  useEffect(() => {
    let unmounted = false;

    //アンマウントされていなければステートを更新
    if(!unmounted) {
      if(mailCode){

        const mailCodeArr = mailCode.split('.')
        const mail = Buffer.from(mailCodeArr[0], 'base64').toString('ascii')
        const vcode = mailCodeArr[1]

        setLoginFllowState("submit")
        setEmail(mail)
        setCode(vcode)
      }
    };

    //クリーンアップ関数を返す
    return ()=>{ unmounted = true; };
  },[mailCode]);

  useEffect(() => {
    let unmounted = false;

    //アンマウントされていなければステートを更新
    if(!unmounted) {
      setError("")
    };

    //クリーンアップ関数を返す
    return ()=>{ unmounted = true; };
  },[loginFllowState]);

  const handleLoginSubmit = (event) => {
    //formのデフォルトの動作を抑制（抑制しないとページリフレッシュされる）
    event.preventDefault();

    authApi.signIn(email, password)
      .then(
        (user) => {
          if(typeof user !== 'undefined'){
            if(user.challengeName === "NEW_PASSWORD_REQUIRED"){
              setLoginFllowState("completeNewPassword")
              setUser(user)
            }else{
              history.push('/');
            }

          }else{
            setError("ログインに失敗しました")
          }
        }
      );
    setPassword("")
  }

  const handleCompleteSubmit = (event) => {
    //formのデフォルトの動作を抑制（抑制しないとページリフレッシュされる）
    event.preventDefault();

    let attributes = {nickname: nickname}

    if(newPassword === retypeNewPassword){
      authApi.completeNewPassword(user,newPassword, attributes)
      .then(
        user => {

          if(typeof user.username !== 'undefined'){

            history.push('/');

          }else{

            if(user.code === "InvalidPasswordException"){
              setError("パスワードは８文字以上で、大文字、小文字を含んで設定してください")
            }

            if(user.code === "InvalidParameterException"){
              setError("ニックネームを入力してください")
            }

            if(user.toString() === "AuthError: Password cannot be empty"){
              setError("パスワードを入力してください")
            }

            if(user.code === "NotAuthorizedException"){
              setCompleteMessage("仮パスワードでログインしなおしてください")
              setLoginFllowState("complete")
              setUser("")
            }

          }

        }
      )
    }else{
      setError("パスワードが一致しません")
    }
  }

  const handleForgetPassword = () => {
    if(loginFllowState === ""){
      setLoginFllowState("send")
      setError("")
    }

    if(loginFllowState === "send"){
      setLoginFllowState("")
      setError("")
    }

    if(loginFllowState === "submit"){
      setLoginFllowState("send")
      setError("")
    }
  }


  const handleSendResetCode = (event) => {
    //formのデフォルトの動作を抑制（抑制しないとページリフレッシュされる）
    event.preventDefault();
    authApi.forgotPassword(email)
      .then(
        data => {
          if(data.code === "InvalidParameterException"){
            setError("Emailアドレスが未検証です")
          }else if(data.code === "UserNotFoundException"){
            setError("登録されていないEmailアドレスです")
          }else if(data.code === "NotAuthorizedException"){
            setError("初回ログインを完了させてください")
          }else{
            setCompleteMessage("メールが送信されました")
            setLoginFllowState("complete")
          }
        }
      )

  }

  const handleSetPassword = (event) => {
    event.preventDefault();

    let username = email;

    if(newPassword === retypeNewPassword){
      authApi.forgotPasswordSubmit(username, code, newPassword)
      .then(
        result => {

          console.log(result)

          if(result === 'succeed'){

            setCompleteMessage("パスワードの設定が完了しました")
            setLoginFllowState("complete")

          }else{

            if(
              result.code === "CodeMismatchException" ||
              result.code === "ExpiredCodeException" ||
              result.toString() === "AuthError: Confirmation code cannot be empty"
            ){
              setError("無効なリンクです。パスワード再設定用リンクを取得しなおしてください。")
            }

            if(result.code === "LimitExceededException"){
              setError("リクエストが制限されています。しばらく待ってから実行してください。")
            }

            if(result.code === "InvalidParameterException" || result.code === "InvalidPasswordException"){
              setError("パスワードは８文字以上で、大文字、小文字を含んで設定してください")
            }

            if(result.toString() === "AuthError: Password cannot be empty"){
              setError("パスワードを入力してください")
            }

          }

        }
      )
    }else{
      setError("パスワードが一致しません")
    }
  }

  const handleCompleteDomBack = () => {
    setCompleteMessage("")
    setLoginFllowState("")
    setEmail("")
    setCode("")
  }

  //パスワードリセット完了の画面かメッセージを追加する


  const loginDom =  (
    <div className="Login">
      <div className="limiter">
        <div className="container-login100">
          <div className="wrap-login100">
            <div className="login100-form validate-form">

              <span className="login100-form-title p-b-0">
                <img id="logo" src="../images/manekineko.png" alt="" />
              </span>
              <span className="login100-form-title p-b-0">
                Manekineko
              </span>
              <span className="login100-form-title p-t-10 p-b-40">
                <img id="bynanakumi" src="../images/nanakumi.png" alt="" />
              </span>

              <form onSubmit={(event) => handleLoginSubmit(event)}>
                <div className="wrap-input100">
                  <input
                    className={['input100', email === "" ? "" : "has-val"].join(' ')}
                    type="text"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <span className="focus-input100" data-placeholder="Email"></span>
                </div>

                <div className="wrap-input100">
                    <span className="btn-show-pass" onClick={(e) => setVisiblePassword(!visiblePassword)}>
                      <i className="zmdi zmdi-eye"><Icon size={20} icon={visiblePassword ? eyeSlash : eye}/></i>
        						</span>
                  <input
                    className={['input100', password === "" ? "" : "has-val"].join(' ')}
                    type={visiblePassword ? 'text' : 'password'}
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <span className="focus-input100" data-placeholder="Password"></span>
                </div>

                <div className="container-login100-form-btn">
                  <div className="wrap-login100-form-btn">
                    <div className="login100-form-bgbtn"></div>
                    <button className="login100-form-btn">
                      Login
                    </button>
                  </div>
                </div>
              </form>

              <div className="text-center p-t-30">
                <span className="txt1">{error}</span>
              </div>

              <div className="text-center p-t-30">
                <button className="txt2" onClick={() => handleForgetPassword()}>
                  パスワードをお忘れですか？
                </button>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  )

  const completeNewPasswordDom = (
    <div className="Login">
      <div className="limiter">
        <div className="container-login100">
          <div className="wrap-login100">
            <div className="login100-form validate-form">

              <span className="login100-form-title p-b-0">
                <img id="logo" src="../images/manekineko.png" alt="" />
              </span>
              <span className="login100-form-title p-b-0">
                Manekineko
              </span>
              <span className="login100-form-title p-t-10 p-b-40">
                <img id="bynanakumi" src="../images/nanakumi.png" alt="" />
              </span>

              <div className="text-center p-b-15">
                <span className="txt">manekinekoへようこそ！</span>
              </div>
              <div className="text-center p-b-30">
                <span className="txt">ユーザ名と新しいパスワードを設定してください</span>
              </div>

              <form onSubmit={(event) => handleCompleteSubmit(event)}>
                <div className="wrap-input100">
                  <input
                    className={['input100', nickname === "" ? "" : "has-val"].join(' ')}
                    type="text"
                    name="nickname"
                    value={nickname}
                    onChange={(e) => setNickname(e.target.value)}
                  />
                  <span className="focus-input100" data-placeholder="ユーザー名"></span>
                </div>

                <div className="wrap-input100">
                  <span className="btn-show-pass" onClick={(e) => setVisibleNewPassword(!visibleNewPassword)}>
                    <i className="zmdi zmdi-eye"><Icon size={20} icon={visibleNewPassword ? eyeSlash : eye}/></i>
                  </span>
                  <input
                    className={['input100', newPassword === "" ? "" : "has-val"].join(' ')}
                    type={visibleNewPassword ? 'text' : 'password'}
                    name="newPassword"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                  <span className="focus-input100" data-placeholder="新しいパスワード"></span>
                </div>

                <div className="wrap-input100">
                  <span className="btn-show-pass" onClick={(e) => setVisibleRetypeNewPassword(!visibleRetypeNewPassword)}>
                    <i className="zmdi zmdi-eye"><Icon size={20} icon={visibleRetypeNewPassword ? eyeSlash : eye}/></i>
                  </span>
                  <input
                    className={['input100', retypeNewPassword === "" ? "" : "has-val"].join(' ')}
                    type={visibleRetypeNewPassword ? 'text' : 'password'}
                    name="retypeNewPassword"
                    value={retypeNewPassword}
                    onChange={(e) => setRetypeNewPassword(e.target.value)}
                  />
                  <span className="focus-input100" data-placeholder="新しいパスワード (再入力)"></span>
                </div>

                <div className="container-login100-form-btn">
                  <div className="wrap-login100-form-btn">
                    <div className="login100-form-bgbtn"></div>
                    <button className="login100-form-btn">
                      Login
                    </button>
                  </div>
                </div>
              </form>

              <div className="text-center p-t-30">
                <span className="txt1">{error}</span>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  )

  const forgetPasswordDom = (
    <div className="Login">
      <div className="limiter">
        <div className="container-login100">
          <div className="wrap-login100">
            <div className="login100-form validate-form">

              <span className="login100-form-title p-b-0">
                <img id="logo" src="../images/manekineko.png" alt="" />
              </span>
              <span className="login100-form-title p-b-0">
                Manekineko
              </span>
              <span className="login100-form-title p-t-10 p-b-40">
                <img id="bynanakumi" src="../images/nanakumi.png" alt="" />
              </span>

              <div className="text-center p-b-30">
                <span className="txt">メールを送信し、<br/>パスワード再設定用リンクを<br/>取得してください</span>
              </div>

              <form onSubmit={(event) => handleSendResetCode(event)}>
                <div className="wrap-input100">
                  <input
                    className={['input100', email === "" ? "" : "has-val"].join(' ')}
                    type="text"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <span className="focus-input100" data-placeholder="Email"></span>
                </div>

                <div className="container-login100-form-btn">
                  <div className="wrap-login100-form-btn">
                    <div className="login100-form-bgbtn"></div>
                    <button className="login100-form-btn">
                      メールを送信
                    </button>
                  </div>
                </div>
              </form>

              <div className="text-center p-t-30">
                <span className="txt1">{error}</span>
              </div>

              <div className="text-center p-t-30">
                <button className="txt2" onClick={() => handleForgetPassword()}>
                  ログインページへ戻る
                </button>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  )

  const forgetPasswordSubmitDom = (
    <div className="Login">
      <div className="limiter">
        <div className="container-login100">
          <div className="wrap-login100">
            <div className="login100-form validate-form">

              <span className="login100-form-title p-b-0">
                <img id="logo" src="../images/manekineko.png" alt="" />
              </span>
              <span className="login100-form-title p-b-0">
                Manekineko
              </span>
              <span className="login100-form-title p-t-10 p-b-40">
                <img id="bynanakumi" src="../images/nanakumi.png" alt="" />
              </span>

              <div className="text-center p-b-40">
                <span className="txt">新しいパスワードを設定してください</span>
              </div>

              <form onSubmit={(event) => handleSetPassword(event)}>

                <div className="wrap-input100">
                  <span className="btn-show-pass" onClick={(e) => setVisibleNewPassword(!visibleNewPassword)}>
                    <i className="zmdi zmdi-eye"><Icon size={20} icon={visibleNewPassword ? eyeSlash : eye}/></i>
                  </span>
                  <input
                    className={['input100', newPassword === "" ? "" : "has-val"].join(' ')}
                    type={visibleNewPassword ? 'text' : 'password'}
                    name="newPassword"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                  <span className="focus-input100" data-placeholder="新しいパスワード"></span>
                </div>

                <div className="wrap-input100">
                  <span className="btn-show-pass" onClick={(e) => setVisibleRetypeNewPassword(!visibleRetypeNewPassword)}>
                    <i className="zmdi zmdi-eye"><Icon size={20} icon={visibleRetypeNewPassword ? eyeSlash : eye}/></i>
                  </span>
                  <input
                    className={['input100', retypeNewPassword === "" ? "" : "has-val"].join(' ')}
                    type={visibleRetypeNewPassword ? 'text' : 'password'}
                    name="retypeNewPassword"
                    value={retypeNewPassword}
                    onChange={(e) => setRetypeNewPassword(e.target.value)}
                  />
                  <span className="focus-input100" data-placeholder="新しいパスワード (再入力)"></span>
                </div>

                <div className="container-login100-form-btn">
                  <div className="wrap-login100-form-btn">
                    <div className="login100-form-bgbtn"></div>
                    <button className="login100-form-btn">
                      パスワードを設定する
                    </button>
                  </div>
                </div>
              </form>

              <div className="text-center p-t-30">
                <span className="txt1">{error}</span>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  )

  const completeDom = (
    <div className="Login">
      <div className="limiter">
        <div className="container-login100">
          <div className="wrap-login100">
            <div className="login100-form validate-form">

              <span className="login100-form-title p-b-0">
                <img id="logo" src="../images/manekineko.png" alt="" />
              </span>
              <span className="login100-form-title p-b-0">
                Manekineko
              </span>
              <span className="login100-form-title p-t-10 p-b-40">
                <img id="bynanakumi" src="../images/nanakumi.png" alt="" />
              </span>

              <div className="text-center p-b-20">
                <span className="txt">{completeMessage}</span>
              </div>

              <div className="text-center p-t-30">
                <button className="txt2" onClick={() => handleCompleteDomBack()}>
                  ログインページへ戻る
                </button>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  )

  //Domの出し分け条件
  if(loginFllowState === "completeNewPassword"){
    // console.log("completeNewPasswordDom")
    return completeNewPasswordDom
  }else if(loginFllowState === "send") {
    // console.log("forgetPasswordDom")
    return forgetPasswordDom
  }else if(loginFllowState === "submit") {
    // console.log("forgetPasswordSubmitDom")
    return forgetPasswordSubmitDom
  }else if(loginFllowState === "complete") {
    // console.log("completeDom")
    return completeDom
  }else{
    // console.log("loginDom")
    return loginDom
  }
}
