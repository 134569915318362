import React, { useState, useEffect, Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Switch from '@material-ui/core/Switch';


import { useSnackbar } from 'notistack';
import { API } from 'aws-amplify';
import moment from 'moment';

import { style } from '../services/Settings'

const useStyles = style

export default function CheckinManage(props) {

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [storedActiveReservations, setStoredActiveReservations] = useState([]);
  const [storedActiveReservationsBeforeToday, setStoredActiveReservationsBeforeToday] = useState([]);

  // テナント情報と予約情報の取得
  useEffect(() => {

    // ページのトップにフォーカス
    window.scrollTo(0, 0)

    // チェックイン対象の予約の取得
    let apiName = 'MyAPIGatewayAPI'
    let path = '/checkin-receiver'

    API.get(apiName, path).then(response => {
      console.log(response);

      let activeReservations = JSON.parse(JSON.stringify(response.reservations))

      activeReservations.sort((a,b) => {
        if(a.lastNameKana > b.lastNameKana) {
          return 1
        }else {
          return -1
        }
      })

      let activeReservationsBeforeToday = JSON.parse(JSON.stringify(response.reservationsBeforeToday))

      activeReservationsBeforeToday.sort((a,b) => {
        if(a.lastNameKana > b.lastNameKana) {
          return 1
        }else {
          return -1
        }
      })

      setStoredActiveReservations(activeReservations)
      setStoredActiveReservationsBeforeToday(activeReservationsBeforeToday)

    }).catch(error => {
      console.log(error);
      enqueueSnackbar('エラーが発生しました。', {variant : 'error'})
    })

  },[enqueueSnackbar])



  const getDateRange = (overnights) => {

    if(overnights.length === 1){
      return {
        start: moment(overnights[0].date).format('YYYY年M月D日'),
        end: null
      }
    }

    let dates = overnights.map(overnight => {
      return overnight.date
    })

    dates.sort((a, b) => moment(a).diff(moment(b)));

    return {
      start: moment(dates[0]).format('YYYY年M月D日'),
      end: moment(dates[dates.length -1]).format('YYYY年M月D日')
    }
  }


  const handleChangeReservationStatus = (reservationId, reservationStatus) => {

    let apiName = 'MyAPIGatewayAPI'; // replace this with your api name.
    let path = '/reservations/' + reservationId; //replace this with the path you have configured on your API

    let reservationStatusAfter = ''

    if(reservationStatus === 'active_checked_in'){
      reservationStatusAfter = 'active_reserved'
    }

    if(reservationStatus === 'active_reserved'){
      reservationStatusAfter = 'active_checked_in'
    }

    let myInit = {
      body: {
        status: reservationStatusAfter
      }
    }

    // 一旦クライアント側のステータスを変更
    let storedActiveReservationsAfter = JSON.parse(JSON.stringify(storedActiveReservations))

    // APIエラー時のバックアップ用
    let storedActiveReservationsBefore = JSON.parse(JSON.stringify(storedActiveReservations))

    storedActiveReservations.map((reservation, i) => {
      if(reservation.reservationId === reservationId){
        storedActiveReservationsAfter[i].reservationStatus = reservationStatusAfter
      }
      return null
    })

    setStoredActiveReservations(storedActiveReservationsAfter)


    // 一旦クライアント側のステータスを変更
    let storedActiveReservationsBeforeTodayAfter = JSON.parse(JSON.stringify(storedActiveReservationsBeforeToday))

    // APIエラー時のバックアップ用
    let storedActiveReservationsBeforeTodayBefore = JSON.parse(JSON.stringify(storedActiveReservationsBeforeToday))

    storedActiveReservationsBeforeToday.map((reservation, i) => {
      if(reservation.reservationId === reservationId){
        storedActiveReservationsBeforeTodayAfter[i].reservationStatus = reservationStatusAfter
      }
      return null
    })

    setStoredActiveReservationsBeforeToday(storedActiveReservationsBeforeTodayAfter)


    API.put(apiName, path, myInit).then(response => {

      console.log(response)
      enqueueSnackbar(response.message, {variant : 'success'});

    }).catch(error => {
      console.log(error)

      if(error.message){
        enqueueSnackbar(error.message, {variant : 'error'})
        return
      }

      // StoredActiveReservationsをもとに戻す
      setStoredActiveReservations(storedActiveReservationsBefore)
      setStoredActiveReservationsBeforeToday(storedActiveReservationsBeforeTodayBefore)

      enqueueSnackbar('エラーが発生しました。', {variant : 'error'})
    });

  }



  return (
    <Fragment>
      <Grid container spacing={2} className={classes.container}>
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom>
            チェックイン管理
          </Typography>
        </Grid>

        <Grid item xs={12}　style={{display: storedActiveReservations.length === 0 && storedActiveReservationsBeforeToday.length === 0 ? '' : 'none'}}>
          <Typography
            variant="h5"
            gutterBottom
            style={{marginTop: 20, marginLeft: 30}}
          >
            予約がありません
          </Typography>
        </Grid>

        <Grid item xs={12}　style={{display: storedActiveReservations.length > 0 ? '' : 'none'}}>
          <Paper className={classes.paper} elevation={1}>
            <Grid container spacing={2} className={classes.container}>
              <Grid item xs={12}>
                <Typography variant="h5" gutterBottom>
                  本日のご予約
                </Typography>
              </Grid>

              <Table size='small'>
                <TableHead>
                  <TableRow className={classes.lightColorRow}>
                    <TableCell align="center">宿泊日</TableCell>
                    <TableCell align="center">お客様情報</TableCell>
                    <TableCell align="center">チェックイン済</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>

                  {storedActiveReservations.map((reservation, i) => {
                    return (
                      <TableRow hover key={reservation.reservationId}>

                        <TableCell align="center">
                          {getDateRange(reservation.overnights).start}<br/>
                          <div style={{transform: 'rotate(90deg)', margin: 5, display: getDateRange(reservation.overnights).end ? '' : 'none'}}>〜</div>
                          {getDateRange(reservation.overnights).end}
                        </TableCell>

                        <TableCell align="left" style={{paddingLeft: 50}}>
                          <Typography gutterBottom variant="caption">
                            {reservation.lastNameKana} {reservation.firstNameKana}
                          </Typography>
                          <Typography variant="h6" style={{marginTop: '-5px'}}>
                            {reservation.lastName} {reservation.firstName} 様
                          </Typography>
                          <br/>
                          <Typography variant="subtitle2">
                            {reservation.addressPrefectures} {reservation.addressCity}
                          </Typography>
                          <Typography variant="subtitle2">
                            {reservation.addressOther}
                          </Typography>
                        </TableCell>

                        <TableCell align="center" size='small' style={{padding:16}}>
                          <Switch
                            checked={reservation.reservationStatus === 'active_checked_in' ? true : false}
                            onChange={() => handleChangeReservationStatus(reservation.reservationId, reservation.reservationStatus)}
                            name="checkin"
                            color="primary"
                          />
                        </TableCell>

                      </TableRow>
                    )
                  })}

                </TableBody>
              </Table>

            </Grid>
          </Paper>
        </Grid>



        <Grid item xs={12}　style={{display: storedActiveReservationsBeforeToday.length > 0 ? '' : 'none'}}>
          <Paper className={classes.paper} elevation={1}>
            <Grid container spacing={2} className={classes.container}>
              <Grid item xs={12}>
                <Typography variant="h5" gutterBottom>
                  本日以前のご予約
                </Typography>
              </Grid>

              <Table size='small'>
                <TableHead>
                  <TableRow className={classes.lightColorRow}>
                    <TableCell align="center">宿泊日</TableCell>
                    <TableCell align="center">お客様情報</TableCell>
                    <TableCell align="center">チェックイン済</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>

                  {storedActiveReservationsBeforeToday.map((reservation, i) => {
                    return (
                      <TableRow hover key={reservation.reservationId}>

                        <TableCell align="center">
                          {getDateRange(reservation.overnights).start}<br/>
                          <div style={{transform: 'rotate(90deg)', margin: 5, display: getDateRange(reservation.overnights).end ? '' : 'none'}}>〜</div>
                          {getDateRange(reservation.overnights).end}
                        </TableCell>

                        <TableCell align="left" style={{paddingLeft: 50}}>
                          <Typography gutterBottom variant="caption">
                            {reservation.lastNameKana} {reservation.firstNameKana}
                          </Typography>
                          <Typography variant="h6" style={{marginTop: '-5px'}}>
                            {reservation.lastName} {reservation.firstName} 様
                          </Typography>
                          <br/>
                          <Typography variant="subtitle2">
                            {reservation.addressPrefectures} {reservation.addressCity}
                          </Typography>
                          <Typography variant="subtitle2">
                            {reservation.addressOther}
                          </Typography>
                        </TableCell>

                        <TableCell align="center" size='small' style={{padding:16}}>
                          <Switch
                            checked={reservation.reservationStatus === 'active_checked_in' ? true : false}
                            onChange={() => handleChangeReservationStatus(reservation.reservationId, reservation.reservationStatus)}
                            name="checkin"
                            color="primary"
                          />
                        </TableCell>

                      </TableRow>
                    )
                  })}

                </TableBody>
              </Table>


            </Grid>
          </Paper>
        </Grid>


      </Grid>
    </Fragment>
  );
}
