import { makeStyles } from '@material-ui/core/styles';

export const style = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: '#009090'
  },
  appBarShift: {
    marginLeft: 240,
    width: `calc(100% - 240px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: 240,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: 240,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    background: 'linear-gradient(top, #d2d2d2 50%, #fff)',
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(5) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7) + 1,
    },
    background: 'linear-gradient(top, #d2d2d2 50%, #fff)',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(4),
  },
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    maxWidth: 800,
  },
  acordion: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    maxWidth: 800,
  },
  innerPaper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(5),
    margin: theme.spacing(2),
    marginBottom: theme.spacing(5),
  },
  innerPaperPreReservation: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    margin: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  validatorForm: {
    padding: theme.spacing(2),
    paddingTop: 0,
    width: '100%',
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    marginTop: 0,
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(1),
    width: '100%',
  },
  buttonBottom:{
    marginBottom: theme.spacing(2),
  },
  buttonForDay: {
    marginTop: -20,
    width: '100%',
  },
  autocomplete: {
    width: '100%',
  },
  lightBorderTable:{
    border: 'solid 2px ' + theme.palette.secondary.light,
    marginTop: theme.spacing(2),
  },
  darkBorderTable:{
    border: 'solid 2px ' + theme.palette.secondary.main,
  },
  deleteInPaper: {
    margin: theme.spacing(),
  },
  lightColorRow: {
    backgroundColor: theme.palette.secondary.light1,
  },
  darkColorRow: {
    backgroundColor: theme.palette.secondary.light2,
  },
  plansTable: {
    marginTop : theme.spacing(2),
    marginBottom : theme.spacing(3)
  },
  primaryColorRow: {
    backgroundColor: theme.palette.primary.light,
  },
  errorIcon:{
    marginRight: theme.spacing()
  },
  warningMessage:{
    color: theme.palette.primary.main,
    fontSize: '1.2em',
    marginLeft: theme.spacing(3),
    marginBottom: theme.spacing(4)
  },
  warningMessageInnerPaper:{
    color: theme.palette.primary.main,
    fontSize: '1.2em',
    marginLeft: theme.spacing(0),
    marginBottom: theme.spacing(4)
  },
  paperWidth: {
    maxWidth: 800
  },
  chip: {
    padding: theme.spacing(1),
  },
  card: {
    background: theme.palette.secondary.light1,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  CardContent: {
    padding: 12,
    paddingBottom: '12px !important'
  },
  marginTop1: {
    marginTop: theme.spacing(1)
  },
  marginTop2: {
    marginTop: theme.spacing(2)
  },
  marginTop3: {
    marginTop: theme.spacing(3)
  },
  marginTop4: {
    marginTop: theme.spacing(4)
  },
  droppablePaper: {
    margin: theme.spacing(1),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.secondary.light1,
  },
  droppablePaperOutline: {
    margin: theme.spacing(1),
    padding: theme.spacing(2),

  },
  droppablePaperNotAssigned: {
    margin: theme.spacing(1),
    padding: theme.spacing(2),
  },
  centerTypography: {
    marginLeft: theme.spacing(1)
  },
  dragDropContextContainer: {
    marginLeft: theme.spacing(2)
  },
  roomCardContentTypo: {
    textDecoration: 'underline',
    color: 'black',
    fontWeight: 'bold'
  },
  tableButton: {
    margin: 4,
    padding: 6,
    width: 150
  },
  tableButtonNarrow : {
    margin: 4,
    padding: 6,
    width: 100
  },
  darkBackgroundDialog: {
    backgroundColor: theme.palette.background.default,
  },
  menuButton: {
    margin: 4,
    padding: 6,
  },
  calendarDay : {
    '&:hover': {
      opacity:0.4,
      transition: '0.2s',
      cursor : 'pointer',
    }
  },
  clickableTableCell : {
    width: 160,
    '&:hover': {
      color: theme.palette.primary.main,
      transition: '0.5s',
      cursor : 'pointer',
    }
  },
  ClikableForAllocationCard : {
    maxWidth: 85,
    margin: '0 0 0 auto',
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: 8,
    marginRight: 4,
    color: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.primary.main,
      transition: '0.2s',
      cursor : 'pointer',
    }
  },
  targetReservation : {
    outline: theme.palette.primary.light + ' solid !important'
  },
  buttonIcon : {
    padding:0,
    margin:'0 3px',
    color: '#3c3c3c'
  },
  toggleButtonRoot : {
    marginTop:6,
    height: 20
  },
  toggleButtonSelected : {
    backgroundColor: '#ff00003d !important'
  }
}));

export const taxRate = 0.1
export const reducedTaxRate = 0.08
