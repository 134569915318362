import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { useHistory } from "react-router";
import Grid from '@material-ui/core/Grid';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { useLocation } from 'react-router-dom'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import CircularProgress from '@material-ui/core/CircularProgress';
import { list } from 'react-icons-kit/fa/'
import SvgIcon from 'react-icons-kit';

import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import { useSnackbar } from 'notistack';
import { API } from 'aws-amplify';
import moment from 'moment';
import Color from 'color';

import { style } from '../services/Settings'

const useStyles = style

function useQuery() {
  return new URLSearchParams(useLocation().search);
}


export default function RoomAllocation(props) {

  const classes = useStyles();
  const history = useHistory();
  const query = useQuery();

  const { enqueueSnackbar } = useSnackbar();

  const [inputDate, setInputDate] = useState(query.get("date") ? query.get("date") : moment().format('YYYY-MM-DD'));

  const [storedRoomAllocations, setStoredRoomAllocations] = useState([])

  const [isWaitingSiteController, setIsWaitingSiteController] = useState(false);
  const [isSiteControllerDialogOpen, setIsSiteControllerDialogOpen] = useState(false);

  const [tempAllocationsInfoForUpdate, setTempAllocationsInfoForUpdate] = useState(null);
  const [tempRoomAllocationsBackup, setTempRoomAllocationsBackup] = useState([]);

  const [jalanId, setJalanId] = useState('');


  useEffect(() => {
    // ページのトップにフォーカス
    window.scrollTo(0, 0)

    props.handleChangePath()

  },[props])


  useEffect(() => {

    let unmounted = false;

    //アンマウントされていなければステートを更新
    if(!unmounted) {

      setStoredRoomAllocations([])

      let apiName = 'MyAPIGatewayAPI'; // replace this with your api name.
      let path = '/allocations'; //replace this with the path you have configured on your API
      const myInit = {
        queryStringParameters: {
          date: inputDate
        },
      };

      API.get(apiName, path, myInit).then(response => {
        console.log(response)


        let roomTypeAllocations = []
        let roomAllocations = []

        if(!response.roomTypeAllocations){
          // enqueueSnackbar(response.message, {variant : 'warning'});
          return
        }
        // enqueueSnackbar('部屋割を取得しました', {variant : 'success'});

        roomTypeAllocations = JSON.parse(JSON.stringify(response.roomTypeAllocations))
        roomAllocations = JSON.parse(JSON.stringify(response.roomAllocations))

        let allOvernightsNotAssigned = []

        roomTypeAllocations.map(roomTypeAllocation => {
          roomTypeAllocation.overnights.map(overnight => {
            allOvernightsNotAssigned.push(overnight)
            return null
          })
          return null
        })

        // console.log('allOvernightsNotAssigned: ', allOvernightsNotAssigned)

        let allOvernightsAssigned = []

        roomAllocations.map(roomAllocation => {
          roomAllocation.rooms.map(room => {
            if(room.overnight.length > 0){
              allOvernightsAssigned.push(room.overnight[0])
            }
            return null
          })
          return null
        })

        // console.log('allOvernightsAssigned: ', allOvernightsAssigned)

        let allOvernightsNotAssignedForPush = JSON.parse(JSON.stringify(allOvernightsNotAssigned))

        allOvernightsNotAssigned.map((overnightNotAssigned, i) => {
          allOvernightsAssigned.map(overnightAssigned => {
            if(overnightNotAssigned.overnightId === overnightAssigned.overnightId){
              allOvernightsNotAssignedForPush.map( (overnightNotAssignedForPush, j) => {
                if(overnightNotAssigned.overnightId === overnightNotAssignedForPush.overnightId){
                  allOvernightsNotAssignedForPush.splice(j, 1)
                }
                return null
              } )
              return null
            }
            return null
          })
          return null
        })

        allOvernightsAssigned.map((overnightAssigned) => {
          roomAllocations.map((roomAllocation, i) => {
            roomAllocation.rooms.map((room, j) => {
              room.overnight.map((overnight, k) => {
                if(overnight.overnightId === overnightAssigned.overnightId){
                  roomAllocations[i].rooms[j].overnight[k] = overnightAssigned
                }
                return null
              })
              return null
            })
            return null
          })
          return null
        })

        roomAllocations.push({
          roomTypeId: '0',
          roomTypeName: '未割り当て',
          rooms: [
            {
              roomName: '未割り当て',
              roomId: '0',
              overnight: allOvernightsNotAssignedForPush
            }
          ]
        })

        console.log('roomAllocations: ', roomAllocations)

        setStoredRoomAllocations(roomAllocations)

      }).catch(error => {
        console.log(error)

        if(error.message){
          enqueueSnackbar(error.message, {variant : 'error'})
          return
        }

        enqueueSnackbar('エラーが発生しました。', {variant : 'error'})
      });

      // jalanIdの取得
      apiName = 'MyAPIGatewayAPI'; // replace this with your api name.
      path = '/tenant'; //replace this with the path you have configured on your API

      API.get(apiName, path).then(response => {
        console.log(response);
        setJalanId(response.tenant.jalanId)
      }).catch(error => {
        console.log(error);
        enqueueSnackbar('エラーが発生しました。', {variant : 'error'})
      })

    };

    //クリーンアップ関数を返す
    return ()=>{ unmounted = true; };

  },[inputDate, enqueueSnackbar])



  const getColor = (roomTypeId) => {

    let num = 0

    storedRoomAllocations.map((roomTypes, i) => {
      if(roomTypes.roomTypeId === roomTypeId){
        num = i
      }
      return null
    })

    return Color('#ffdede').rotate(60 * num).hex()
  }


  const getListStyle = isDraggingOver => {
    return {
      background: isDraggingOver ? Color('#f1f1f1').darken(0.1).hex() : '#f1f1f1',
      padding: 8,
      width: 220,
      minHeight: 60
    }
  };

  const getItemStyle = (isDragging, draggableStyle, roomTypeId) => ({
      // some basic styles to make the items look a bit nicer
      userSelect: 'none',

      // change background colour if dragging
      background: isDragging ? Color(getColor(roomTypeId)).darken(0.1).hex() : getColor(roomTypeId),

      // styles we need to apply on draggables
      ...draggableStyle
  });

  const getNotAssignedListStyle = isDraggingOver => {
    return {
      background: isDraggingOver ? Color('#f1f1f1').darken(0.1).hex() : '#f1f1f1',
      display: 'flex',
      flexWrap: 'wrap',
      padding: 8,
      minHeight: 60,
    }
  };

  const getNotAssignedItemStyle = (isDragging, draggableStyle, roomTypeId) => ({
      // some basic styles to make the items look a bit nicer
      userSelect: 'none',
      margin: 8,
      width: 204,

      // change background colour if dragging
      background: isDragging ? Color(getColor(roomTypeId)).darken(0.1).hex() : getColor(roomTypeId),

      // styles we need to apply on draggables
      ...draggableStyle
  });


  const getGuestNum = (plans) => {
    let adult = 0
    let child = 0
    let toddler = 0

    plans.map( plan => {
      if(plan.guestType === 'adult'){
        adult += plan.amount
      }
      if(plan.guestType === 'child'){
        child += plan.amount
      }
      if(plan.guestType === 'toddler'){
        toddler += plan.amount
      }
      return null
    })

    return {
      adult: adult,
      child: child,
      toddler: toddler
    }

  }


  const onDragEnd = result => {

    const { source, destination } = result;

    if(!destination){
      return
    }

    if(source.droppableId !== destination.droppableId){
      // 違うリストへの移動

      let roomAllocationsAfter = JSON.parse(JSON.stringify(storedRoomAllocations))

      let targetOvernight = []

      let indexOfRoomTypeSource = 0
      // let indexOfRoomNameSource = 0

      let indexOfRoomTypeDestination = 0
      let indexOfRoomNameDestination = 0

      roomAllocationsAfter.map((roomAllocation, i) => {
        roomAllocation.rooms.map((room, j) => {
          if(room.roomId === source.droppableId){
            indexOfRoomTypeSource = i
            // indexOfRoomNameSource = j
            targetOvernight = room.overnight[source.index]
            roomAllocationsAfter[i].rooms[j].overnight.splice(source.index,1)
          }
          return null
        })
        return null
      })

      roomAllocationsAfter.map((roomAllocation, i) => {
        roomAllocation.rooms.map((room, j) => {
          if(room.roomId === destination.droppableId){
            indexOfRoomTypeDestination = i
            indexOfRoomNameDestination = j
            roomAllocationsAfter[i].rooms[j].overnight.splice(destination.index, 0 , targetOvernight)
          }
          return null
        })
        return null
      })

      let roomTypeIdForIncreaseStocks = ''
      let roomTypeIdForDecreaseStocks = ''

      // 在庫の増減を設定する組み合わせ
      // 0
      // 0
      // ↓
      // none
      // none
      //
      // 0
      // same
      // ↓
      // none
      // none
      //
      // 0
      // other
      // ↓
      // +1 origin
      // -1 other
      //
      // same
      // 0
      // ↓
      // none
      // none
      //
      // same
      // same
      // ↓
      // none
      // none
      //
      // same
      // other
      // ↓
      // +1 origin
      // -1 other
      //
      // other
      // 0
      // ↓
      // +1 other
      // -1 origin
      //
      // other
      // same
      // ↓
      // +1 other
      // -1 origin
      //
      // other1
      // other2
      // ↓
      // +1 other1
      // -1 other2


      // 0
      // other
      // ↓
      // +1 origin
      // -1 other
      if(
        storedRoomAllocations[indexOfRoomTypeSource].roomTypeId === '0' &&
        storedRoomAllocations[indexOfRoomTypeDestination].roomTypeId !== '0' &&
        storedRoomAllocations[indexOfRoomTypeDestination].roomTypeId !== targetOvernight.roomTypeId
      ){
        roomTypeIdForIncreaseStocks = targetOvernight.roomTypeId
        roomTypeIdForDecreaseStocks = storedRoomAllocations[indexOfRoomTypeDestination].roomTypeId
      }


      // same
      // other
      // ↓
      // +1 origin
      // -1 other
      if(
        storedRoomAllocations[indexOfRoomTypeSource].roomTypeId === targetOvernight.roomTypeId &&
        storedRoomAllocations[indexOfRoomTypeDestination].roomTypeId !== '0' &&
        storedRoomAllocations[indexOfRoomTypeDestination].roomTypeId !== targetOvernight.roomTypeId
      ){
        roomTypeIdForIncreaseStocks = targetOvernight.roomTypeId
        roomTypeIdForDecreaseStocks = storedRoomAllocations[indexOfRoomTypeDestination].roomTypeId
      }


      // other
      // 0
      // ↓
      // +1 other
      // -1 origin
      if(
        storedRoomAllocations[indexOfRoomTypeSource].roomTypeId !== '0' &&
        storedRoomAllocations[indexOfRoomTypeSource].roomTypeId !== targetOvernight.roomTypeId &&
        storedRoomAllocations[indexOfRoomTypeDestination].roomTypeId === '0'
      ){
        roomTypeIdForIncreaseStocks = storedRoomAllocations[indexOfRoomTypeSource].roomTypeId
        roomTypeIdForDecreaseStocks = targetOvernight.roomTypeId
      }


      // other
      // same
      // ↓
      // +1 other
      // -1 origin
      if(
        storedRoomAllocations[indexOfRoomTypeSource].roomTypeId !== '0' &&
        storedRoomAllocations[indexOfRoomTypeSource].roomTypeId !== targetOvernight.roomTypeId &&
        storedRoomAllocations[indexOfRoomTypeDestination].roomTypeId === targetOvernight.roomTypeId
      ){
        roomTypeIdForIncreaseStocks = storedRoomAllocations[indexOfRoomTypeSource].roomTypeId
        roomTypeIdForDecreaseStocks = targetOvernight.roomTypeId
      }


      // other1
      // other2
      // ↓
      // +1 other1
      // -1 other2
      if(
        storedRoomAllocations[indexOfRoomTypeSource].roomTypeId !== '0' &&
        storedRoomAllocations[indexOfRoomTypeSource].roomTypeId !== targetOvernight.roomTypeId &&
        storedRoomAllocations[indexOfRoomTypeDestination].roomTypeId !== '0' &&
        storedRoomAllocations[indexOfRoomTypeDestination].roomTypeId !== targetOvernight.roomTypeId
      ){
        roomTypeIdForIncreaseStocks = storedRoomAllocations[indexOfRoomTypeSource].roomTypeId
        roomTypeIdForDecreaseStocks = storedRoomAllocations[indexOfRoomTypeDestination].roomTypeId
      }

      let set = false
      let roomAllocationsResult = JSON.parse(JSON.stringify(storedRoomAllocations))

      if(storedRoomAllocations[indexOfRoomTypeDestination].roomTypeId === '0'){
        set = true
        roomAllocationsResult = JSON.parse(JSON.stringify(roomAllocationsAfter))
      }

      if(storedRoomAllocations[indexOfRoomTypeDestination].rooms[indexOfRoomNameDestination].overnight.length === 0){
        set = true
        roomAllocationsResult = JSON.parse(JSON.stringify(roomAllocationsAfter))
      }

      setTempRoomAllocationsBackup(JSON.parse(JSON.stringify(storedRoomAllocations)))
      setStoredRoomAllocations(roomAllocationsResult)

      if(set){

        // 未割り当てを削除
        let roomAllocationsResultForPut = JSON.parse(JSON.stringify(roomAllocationsResult))
        roomAllocationsResultForPut.pop()

        // console.log(roomAllocationsResultForPut)

        setTempAllocationsInfoForUpdate(null)

        if(roomTypeIdForIncreaseStocks !== '' || roomTypeIdForDecreaseStocks !== ''){

          setTempAllocationsInfoForUpdate({
            date: inputDate,
            roomAllocations: roomAllocationsResultForPut,
            roomTypeIdForIncreaseStocks: roomTypeIdForIncreaseStocks,
            roomTypeIdForDecreaseStocks: roomTypeIdForDecreaseStocks
          })

        }else{
          updateAllocations(
            {
              date: inputDate,
              roomAllocations: roomAllocationsResultForPut,
              roomTypeIdForIncreaseStocks: roomTypeIdForIncreaseStocks,
              roomTypeIdForDecreaseStocks: roomTypeIdForDecreaseStocks
            },
            false
          )
        }

      }
    }
  };



  const updateAllocations = useCallback((allocationsInfoForUpdate, isUseSiteController) => {


    const changeAllocations = (allocationsInfoForUpdate) => {

      let apiName = 'MyAPIGatewayAPI'; // replace this with your api name.
      let path = '/allocations'; //replace this with the path you have configured on your API
      let myInit = {
        body: allocationsInfoForUpdate
      }

      API.put(apiName, path, myInit).then(response => {
        console.log(response)
        enqueueSnackbar(response.message, {variant : 'success'});

      }).catch(error => {
        console.log(error)

        setStoredRoomAllocations(tempRoomAllocationsBackup)
        setIsSiteControllerDialogOpen(false)

        if(error.message){
          enqueueSnackbar(error.message, {variant : 'error'})
          return
        }

        enqueueSnackbar('エラーが発生しました。', {variant : 'error'})
      });

      setIsSiteControllerDialogOpen(false)

    }

    if(isUseSiteController){
      setIsWaitingSiteController(true)

      let apiName = 'MyAPIGatewayAPI'
      let path = '/jalan/change-for-allocations'

      let body = {
        allocationsInfoForUpdate: allocationsInfoForUpdate
      }

      let myInit = {
        body: body
      }

      API.post(apiName, path, myInit).then(async (response) => {

        let executionArn = response.executionArn

        console.log(response)

        let path = '/jalan/change/status'

        let body = {
          executionArn : executionArn
        }

        let myInit = {
          body: body
        }

        //結果をポーリング

        function sleep(waitSec) {
          return new Promise(function (resolve) {
            setTimeout(function() { resolve() }, waitSec);
          });
        }

        let res

        let count = 0
        while (count < 40) {

          try{
            res = await API.post(apiName, path, myInit)
            console.log(res)

            if(res.status === "RUNNING"){
              count++
              await sleep(3000)
            }else{
              break
            }

          }catch(error){

            console.log(error)
            if(error.message){
              enqueueSnackbar(error.message, {variant : 'error'})
              setIsWaitingSiteController(false)
            }else{
              enqueueSnackbar('エラーが発生しました。', {variant : 'error'})
              setStoredRoomAllocations(tempRoomAllocationsBackup)
              setIsSiteControllerDialogOpen(false)
              setIsWaitingSiteController(false)
            }
            break
          }
        }

        if(res.status === "SUCCEEDED"){

          if(res.output.message !== "success"){
            enqueueSnackbar(res.output.message, {variant : 'error', autoHideDuration : 20000})
            setStoredRoomAllocations(tempRoomAllocationsBackup)
            setIsSiteControllerDialogOpen(false)
            setIsWaitingSiteController(false)
          }else{
            if(res.output.beforeSale.length > 0){
              res.output.beforeSale.map(item => {
                let message = moment(item.date).format('YYYY年M月D日') + ' の ' + item.roomName + ' はじゃらんで販売開始前のため、じゃらんの在庫は変更されませんでした。'
                enqueueSnackbar(message, {variant : 'warning', autoHideDuration : 20000})
                return null
              })
            }

            if(res.output.stopSale.length > 0){
              res.output.stopSale.map(item => {
                let message = moment(item.date).format('YYYY年M月D日') + ' の ' + item.roomName + ' はじゃらんで販売停止中のため、じゃらんの在庫は変更されませんでした。'
                enqueueSnackbar(message, {variant : 'warning', autoHideDuration : 20000})
                return null
              })

            }

            changeAllocations(allocationsInfoForUpdate)
            setIsWaitingSiteController(false)
          }

        }else{
          enqueueSnackbar('処理中にエラーが発生しました。念の為、じゃらんの在庫状況を確認してください。', {variant : 'error'})
          setStoredRoomAllocations(tempRoomAllocationsBackup)
          setIsSiteControllerDialogOpen(false)
          setIsWaitingSiteController(false)
        }

      }).catch(error => {

        setStoredRoomAllocations(tempRoomAllocationsBackup)
        setIsSiteControllerDialogOpen(false)
        setIsWaitingSiteController(false)

        console.log(error)
        if(error.message){
          enqueueSnackbar(error.message, {variant : 'error'})
        }else{
          enqueueSnackbar('エラーが発生しました。', {variant : 'error'})
        }

      });

    }else{
      changeAllocations(allocationsInfoForUpdate)
    }

  }, [enqueueSnackbar, tempRoomAllocationsBackup]);


  // tempAllocationsInfoForUpdateの変更の契機で変更処理を発火
  useEffect(() => {

    let unmounted = false;
    //アンマウントされていなければステートを更新
    if(!unmounted){
      if(jalanId !== ''){

        if(tempAllocationsInfoForUpdate){
          if(jalanId){
            setIsSiteControllerDialogOpen(true)
          }else{
            updateAllocations(tempAllocationsInfoForUpdate, false)
          }
        }
      }


    };
    //クリーンアップ関数を返す
    return ()=>{ unmounted = true; };

  },[tempAllocationsInfoForUpdate, jalanId, updateAllocations])


  const getAllOvernightsLength = (storedRoomAllocations) => {

    let length = 0

    storedRoomAllocations.map(storedRoomAllocation => {
      storedRoomAllocation.rooms.map(room => {
        room.overnight.map(overnight => {
          length++
          return null
        })
        return null
      })
      return null
    })

    return length
  }


  // カードの部屋タイプ横のクリックで予約管理に移動
  const handleClick = (reservationId) => {
    history.push('/main/reservation-manage?reservationId=' + reservationId);
  }



  return (
    <Fragment>
      <Grid container spacing={2} className={classes.container}>

        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom>
            部屋割
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <ValidatorForm
            onSubmit={() => console.log()}
            onError={
              errors => {
                console.log(errors);
                enqueueSnackbar('入力エラーがあります。確認してください。', {variant : 'warning'});
              }
            }
            className={classes.validatorForm}
          >
            <Grid container spacing={2} className={classes.container}>

              <Grid item xs={2}>
                <TextValidator
                  className={classes.textField}
                  name="date"
                  label="対象日"
                  value={inputDate}
                  margin="normal"
                  onChange={(e) => setInputDate(e.target.value)}
                  type="date"
                  required={true}
                  validators={['required', 'matchRegexp:^\\d{4}-\\d{1,2}-\\d{1,2}$']}
                  errorMessages={['必須項目を入力してください', '日付が間違っています']}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={10}/>

              <Grid item xs={1}>
                <Button
                  variant="outlined"
                  disableElevation
                  color="default"
                  className={classes.buttonForDay}
                  onClick={() => setInputDate(moment(inputDate).add(-1,'days').format('YYYY-MM-DD'))}
                >
                  前日
                </Button>
              </Grid>
              <Grid item xs={1}>
                <Button
                  variant="outlined"
                  disableElevation
                  color="default"
                  className={classes.buttonForDay}
                  onClick={() => setInputDate(moment(inputDate).add(1,'days').format('YYYY-MM-DD'))}
                >
                  翌日
                </Button>
              </Grid>

              <Grid item xs={10}/>

            </Grid>
          </ValidatorForm>
        </Grid>

        <Typography
          variant="h5"
          gutterBottom
          style={{display: (storedRoomAllocations.length === 0 || getAllOvernightsLength(storedRoomAllocations) === 0) ? '' : 'none' , marginTop: 20, marginLeft: 30}}
        >
          予約がありません
        </Typography>

        <DragDropContext onDragEnd={onDragEnd}>
          <Grid container spacing={2} className={classes.dragDropContextContainer} justify='flex-start' style={{display: getAllOvernightsLength(storedRoomAllocations) === 0 ? 'none' : ''}}>

            {storedRoomAllocations.map((roomType, i) => {
              if(!(i === (storedRoomAllocations.length - 1))){
                return(
                  <Paper
                    className={classes.droppablePaperOutline}
                    elevation={1}
                    key={roomType.roomTypeId}
                    style={{width: 260, backgroundColor: getColor(roomType.roomTypeId)}}
                  >
                    <Typography variant="h6" gutterBottom className={classes.centerTypography}>
                      {roomType.roomTypeName}
                    </Typography>

                    {roomType.rooms.map((room, i) => (

                       <Droppable droppableId={room.roomId} key={room.roomId}>
                        {(provided, snapshot) => (
                          <Paper
                            className={classes.droppablePaper}
                            elevation={2}
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver, roomType.roomTypeId)}
                          >
                            <Typography variant="subtitle1" gutterBottom className={classes.centerTypography}>
                              {room.roomName}
                            </Typography>
                            {room.overnight.map((item, index) => (
                                <Draggable
                                    key={item.overnightId}
                                    draggableId={item.overnightId}
                                    index={index}>
                                    {(provided, snapshot) => (
                                        <Card
                                          className={classes.root}
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={getItemStyle(
                                              snapshot.isDragging,
                                              provided.draggableProps.style,
                                              item.roomTypeId
                                          )}
                                        >
                                          <CardContent className={classes.CardContent}>
                                            <Typography variant="body1" className={classes.roomCardContentTypo}>
                                              {item.roomTypeName}
                                            </Typography>
                                            <Typography gutterBottom variant="caption">
                                              {item.lastNameKana} {item.firstNameKana}
                                            </Typography>
                                            <Typography variant="subtitle1" style={{marginTop: '-5px'}}>
                                              {item.lastName} {item.firstName} 様
                                            </Typography>
                                            <Typography variant="caption">
                                              {item.addressPrefectures} {item.addressCity}
                                            </Typography>
                                            <br/>
                                            <Typography variant="caption">
                                              大人: {getGuestNum(item.plans).adult}名 子供: {getGuestNum(item.plans).child}名 幼児: {getGuestNum(item.plans).toddler}名
                                            </Typography>
                                            <br/>
                                            <Typography variant="caption">
                                              <div className={classes.ClikableForAllocationCard} onClick={() => handleClick(item.reservationId)}>
                                                <SvgIcon
                                                  style={{padding:0, margin:'-2px 6px 0 0'}}
                                                  size={14}
                                                  icon={list}
                                                />
                                                <div>予約管理へ</div>
                                              </div>
                                            </Typography>
                                          </CardContent>
                                        </Card>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                          </Paper>
                        )}
                      </Droppable>

                    ))}

                  </Paper>
                )
              }else{
                return(
                  <div style={{width: '100%', marginTop: 20}} key={roomType.roomTypeId}>

                    {roomType.rooms.map((room, i) => (

                       <Droppable droppableId={room.roomId} key={room.roomId} direction="horizontal">
                        {(provided, snapshot) => (
                          <Paper
                            className={classes.droppablePaperNotAssigned}
                            elevation={1}
                            ref={provided.innerRef}
                            style={getNotAssignedListStyle(snapshot.isDraggingOver)}
                          >
                            <Grid item xs={12}>
                              <Typography variant="h6" gutterBottom className={classes.centerTypography}>
                                {room.roomName}
                              </Typography>
                            </Grid>
                            {room.overnight.map((item, index) => (
                                <Draggable
                                    key={item.overnightId}
                                    draggableId={item.overnightId}
                                    index={index}>
                                    {(provided, snapshot) => (
                                      <Card
                                        className={classes.root}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getNotAssignedItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style,
                                            item.roomTypeId
                                        )}
                                      >
                                        <CardContent className={classes.CardContent}>
                                          <Typography variant="body1" className={classes.roomCardContentTypo}>
                                            {item.roomTypeName}
                                          </Typography>
                                          <Typography gutterBottom variant="caption">
                                            {item.lastNameKana} {item.firstNameKana}
                                          </Typography>
                                          <Typography variant="subtitle1" style={{marginTop: '-5px'}}>
                                            {item.lastName} {item.firstName} 様
                                          </Typography>
                                          <Typography variant="caption">
                                            {item.addressPrefectures} {item.addressCity}
                                          </Typography>
                                          <br/>
                                          <Typography variant="caption">
                                            大人: {getGuestNum(item.plans).adult}名 子供: {getGuestNum(item.plans).child}名 幼児: {getGuestNum(item.plans).toddler}名
                                          </Typography>
                                          <br/>
                                          <Typography variant="caption">
                                            <div className={classes.ClikableForAllocationCard} onClick={() => handleClick(item.reservationId)}>
                                              <SvgIcon
                                                style={{padding:0, margin:'-2px 6px 0 0'}}
                                                size={14}
                                                icon={list}
                                              />
                                              <div>予約管理へ</div>
                                            </div>
                                          </Typography>
                                        </CardContent>
                                      </Card>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                          </Paper>
                        )}
                      </Droppable>

                    ))}

                  </div>
                )
              }

            })}

          </Grid>
        </DragDropContext>
      </Grid>


      <Dialog
        open={isSiteControllerDialogOpen ? true : false}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          じゃらんの在庫を変更しますか？
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            異なる部屋タイプに部屋割を行ったことにより、<br/>
            じゃらんの在庫を変更する場合は、「はい」を選択してください。
          </DialogContentText>

        </DialogContent>
        <DialogActions>
          <Button onClick={() => updateAllocations(tempAllocationsInfoForUpdate, false)} color="primary">
            いいえ
          </Button>
          <Button onClick={() => updateAllocations(tempAllocationsInfoForUpdate, true)} color="primary">
            はい
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog
        open={isWaitingSiteController}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          じゃらん連携中
        </DialogTitle>
        <DialogContent style={{textAlign: "center",marginTop: 20}}>
          <CircularProgress color="inherit"/>
          <DialogContentText id="alert-dialog-description" style={{textAlign: "left", marginTop: 30}}>
            ページを閉じないでください<br/><br/>
            じゃらんの在庫の確認と変更を行っています<br/>
            この処理には数分かかる場合があります<br/><br/>
            しばらくおまちください...
          </DialogContentText>
        </DialogContent>
      </Dialog>

    </Fragment>
  );
}
