import React, { useState, Fragment } from 'react';
import { BrowserRouter, Route, Switch, Redirect, Link } from 'react-router-dom'
import { useHistory } from "react-router-dom";

import clsx from 'clsx';
import { useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import SvgIcon from 'react-icons-kit';
import { calendar, phone, pencil, list, th, bell, glass, fileText, checkCircle, gear, user} from 'react-icons-kit/fa/'
import ExitToApp from '@material-ui/icons/ExitToApp';

import AuthService from '../services/AuthService'
import PrivateRoute from './PrivateRoute'
import Login from './Login';
import Calendar from '../main/Calendar';
import Reservation from '../main/Reservation';
import PreReservation from '../main/PreReservation';
import ReservationManage from '../main/ReservationManage';
import RoomAllocation from '../main/RoomAllocation';
import CheckinManage from '../main/CheckinManage';
import OrderManage from '../main/OrderManage';
import ReceiptManage from '../main/ReceiptManage';
import CheckoutManage from '../main/CheckoutManage';
import UserConfig from '../config/UserConfig';
import TenantConfig from '../config/TenantConfig';

import CustomerPage from '../customer/CustomerPage';

import { style } from '../services/Settings'

const useStyles = style


export default function Main(props) {

  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  const authApi = new AuthService()

  const [open, setOpen] = useState(false);
  const [nickname, setNickname] = useState('');
  const [path, setPath] = useState('');

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleChangePath = () => {
    setPath(window.location.pathname)
  }

  const handleSetNickname =() => {
    authApi.getNickname()
      .then(
        nickname => {
          setNickname(nickname)
        }
      )
  }

  const handleSineOut = () => {
    authApi.signOut()
    .then(
      () => {
        history.push('/login');
      }
    );
  }

  const handleClickListItem = (path) => {
    console.log("path: ", path);
    setPath(path)
  }

  return (
    <BrowserRouter>
      <Switch>

        <Route exact path='/login/' component={Login} />
        <Route exact path='/login/:mailCode' component={Login} />
        <Route exact path="/customer/:pathParam" component={CustomerPage} />

        <Fragment>

          {/* Drawer部分 */}
          <div className={classes.root}>
            <CssBaseline />
            <AppBar
              position="fixed"
              className={clsx(classes.appBar, {
                [classes.appBarShift]: open,
              })}
            >
              <Toolbar>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  className={clsx(classes.menuButton, {
                    [classes.hide]: open,
                  })}
                >
                  <MenuIcon />
                </IconButton>
                <img border="0" src="../images/manekineko.png" style={{marginRight:10, textAlign:'center', width: 32, height: 32, filter:"invert(100%)"}} alt="logo" />
                <Typography variant="h6" color="inherit" noWrap style={{fontFamily:'Nico Moji'}}>
                  Manekineko
                </Typography>
                <div style={{display: nickname ? 'block' : 'none', margin: '0 0 0 auto', paddingRight: 20}}>
                  <span style={{fontSize: '1.3em',marginRight: 5}}>{nickname}</span> {'さんでログイン中'  }
                </div>
              </Toolbar>
            </AppBar>

            <Drawer
              variant="permanent"
              className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              })}
              classes={{
                paper: clsx({
                  [classes.drawerOpen]: open,
                  [classes.drawerClose]: !open,
                }),
              }}
            >
              <div className={classes.toolbar}>
                <IconButton onClick={handleDrawerClose}>
                  {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                </IconButton>
              </div>
              <Divider />
              <List>
              <ListItem button component={Link} to='/main/calendar' onClick={() => handleClickListItem('/main/calendar')} selected={path === '/main/calendar' ? true : false}>
                <ListItemIcon><SvgIcon size={20} icon={calendar}/></ListItemIcon>
                <ListItemText primary="予約カレンダー" />
              </ListItem>
                <ListItem button component={Link} to='/main/reservation' onClick={() => handleClickListItem('/main/reservation')} selected={path === '/main/reservation' ? true : false}>
                  <ListItemIcon><SvgIcon size={20} icon={phone}/></ListItemIcon>
                  <ListItemText primary="予約受付" />
                </ListItem>
                <ListItem button component={Link} to='/main/pre-reservation' onClick={() => handleClickListItem('/main/pre-reservation')} selected={path === '/main/pre-reservation' ? true : false}>
                  <ListItemIcon><SvgIcon size={20} icon={pencil}/></ListItemIcon>
                  <ListItemText primary="簡易予約受付" />
                </ListItem>
                <ListItem button component={Link} to='/main/reservation-manage' onClick={() => handleClickListItem('/main/reservation-manage')} selected={path === '/main/reservation-manage' ? true : false}>
                  <ListItemIcon><SvgIcon size={20} icon={list}/></ListItemIcon>
                  <ListItemText primary="予約管理" />
                </ListItem>
                <ListItem button component={Link} to='/main/room-allocation' onClick={() => handleClickListItem('/main/room-allocation')} selected={path === '/main/room-allocation' ? true : false}>
                  <ListItemIcon><SvgIcon size={20} icon={th}/></ListItemIcon>
                  <ListItemText primary="部屋割" />
                </ListItem>
                <ListItem button component={Link} to='/main/checkin-manage' onClick={() => handleClickListItem('/main/checkin-manage')} selected={path === '/main/checkin-manage' ? true : false}>
                  <ListItemIcon><SvgIcon size={20} icon={bell}/></ListItemIcon>
                  <ListItemText primary="チェックイン管理" />
                </ListItem>
                <ListItem button component={Link} to='/main/order-manage' onClick={() => handleClickListItem('/main/order-manage')} selected={path === '/main/order-manage' ? true : false}>
                  <ListItemIcon><SvgIcon size={20} icon={glass}/></ListItemIcon>
                  <ListItemText primary="オーダー入力" />
                </ListItem>
                <ListItem button component={Link} to='/main/receipt-manage' onClick={() => handleClickListItem('/main/receipt-manage')} selected={path === '/main/receipt-manage' ? true : false}>
                  <ListItemIcon><SvgIcon size={20} icon={fileText}/></ListItemIcon>
                  <ListItemText primary="領収書/明細発行" />
                </ListItem>
                <ListItem button component={Link} to='/main/checkout-manage' onClick={() => handleClickListItem('/main/checkout-manage')} selected={path === '/main/checkout-manage' ? true : false}>
                  <ListItemIcon><SvgIcon size={20} icon={checkCircle}/></ListItemIcon>
                  <ListItemText primary="チェックアウト管理" />
                </ListItem>
              </List>
              <Divider />
              <List>
                <ListItem button component={Link} to='/config/tenant-config' onClick={() => handleClickListItem('/config/tenant-config')} selected={path === '/config/tenant-config' ? true : false}>
                  <ListItemIcon><SvgIcon size={20} icon={gear}/></ListItemIcon>
                  <ListItemText primary="テナント設定" />
                </ListItem>
                <ListItem button component={Link} to='/config/user-config' onClick={() => handleClickListItem('/config/user-config')} selected={path === '/config/user-config' ? true : false}>
                  <ListItemIcon><SvgIcon size={20} icon={user}/></ListItemIcon>
                  <ListItemText primary="ユーザ設定" />
                </ListItem>
              </List>
              <Divider />
                <form onSubmit={() => handleSineOut()} style={{textAlign: 'center', marginTop: 20}}>
                  <List>
                    <ListItem button type="submit" component="button">
                      <ListItemIcon><ExitToApp/></ListItemIcon>
                      <ListItemText primary="Logout" />
                    </ListItem>
                  </List>
                </form>
            </Drawer>

            {/* メインコンテンツコンテンツ */}
            <main className={classes.content}>
              <div className={classes.toolbar} />
              <Switch>
                <PrivateRoute exact path="/main/calendar" component={Calendar} handleSetNickname={handleSetNickname} handleChangePath={() => handleChangePath()}/>
                <PrivateRoute exact path="/main/reservation" component={Reservation} handleSetNickname={handleSetNickname} handleChangePath={() => handleChangePath()}/>
                <PrivateRoute exact path="/main/pre-reservation" component={PreReservation} handleSetNickname={handleSetNickname} handleChangePath={() => handleChangePath()}/>
                <PrivateRoute exact path="/main/reservation-manage" component={ReservationManage} handleSetNickname={handleSetNickname} handleChangePath={() => handleChangePath()}/>
                <PrivateRoute exact path="/main/room-allocation" component={RoomAllocation} handleSetNickname={handleSetNickname} handleChangePath={() => handleChangePath()}/>
                <PrivateRoute exact path="/main/checkin-manage" component={CheckinManage} handleSetNickname={handleSetNickname} handleChangePath={() => handleChangePath()}/>
                <PrivateRoute exact path="/main/order-manage" component={OrderManage} handleSetNickname={handleSetNickname} handleChangePath={() => handleChangePath()}/>
                <PrivateRoute exact path="/main/receipt-manage" component={ReceiptManage} handleSetNickname={handleSetNickname} handleChangePath={() => handleChangePath()}/>
                <PrivateRoute exact path="/main/checkout-manage" component={CheckoutManage} handleSetNickname={handleSetNickname} handleChangePath={() => handleChangePath()}/>
                <PrivateRoute exact path="/config/tenant-config" component={TenantConfig} handleSetNickname={handleSetNickname} handleChangePath={() => handleChangePath()}/>
                <PrivateRoute exact path="/config/user-config" component={UserConfig} handleSetNickname={handleSetNickname} handleChangePath={() => handleChangePath()}/>
                <Redirect to="/main/reservation" />
              </Switch>
            </main>
          </div>
        </Fragment>

        <Redirect to="/main/calendar" />

      </Switch>
    </BrowserRouter>
  );
}
