import React, { useState, useEffect, Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
// import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
// import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
// import DialogTitle from '@material-ui/core/DialogTitle';

import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import { useSnackbar } from 'notistack';
import { API } from 'aws-amplify';

import { style } from '../services/Settings'

const useStyles = style

export default function TenantConfig(props) {

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [inputJalanId, setInputJalanId] = useState('');
  const [inputNewPassword, setInputNewPassword] = useState('');
  const [inputRetypeNewPassword, setInputRetypeNewPassword] = useState('');
  const [inputFormType, setInputFormType] = useState('receipt');



  useEffect(() => {

    let apiName = 'MyAPIGatewayAPI'
    let path = '/tenant'

    API.get(apiName, path).then(response => {
      console.log(response);

      if(response.tenant.jalanId){
        setInputJalanId(response.tenant.jalanId)
      }

      if(response.tenant.defaultFormType){
        setInputFormType(response.tenant.defaultFormType)
      }

    }).catch(error => {
      console.log(error);
      enqueueSnackbar('エラーが発生しました。', {variant : 'error'})
    })

  },[enqueueSnackbar])


  const changeJalanLoginInfo= () => {

    console.log('changeJalanLoginInfo')

    let apiName = 'MyAPIGatewayAPI'
    let path = '/tenants'

    let myInit = {
      body: {
        jalanId: inputJalanId,
        jalanPw: inputNewPassword,
      }
    }

    API.put(apiName, path, myInit).then(response => {
      console.log(response);

      enqueueSnackbar(response.message, {variant : 'success'});

      setInputNewPassword('')
      setInputRetypeNewPassword('')

    }).catch(error => {
      console.log(error);
      enqueueSnackbar('エラーが発生しました。', {variant : 'error'})
    })

  }


  const changeDefaultFormType= () => {

    console.log('changeDefaultFormType')

    let apiName = 'MyAPIGatewayAPI'
    let path = '/tenants'

    let myInit = {
      body: {
        defaultFormType: inputFormType,
      }
    }

    API.put(apiName, path, myInit).then(response => {
      console.log(response);

      enqueueSnackbar(response.message, {variant : 'success'});

    }).catch(error => {
      console.log(error);
      enqueueSnackbar('エラーが発生しました。', {variant : 'error'})
    })

  }


  ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
      if (value !== inputNewPassword) {
          return false;
      }
      return true;
  })

  return (
    <Fragment>
      <Grid container spacing={2} className={classes.container}>

        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom>
            テナント設定
          </Typography>
        </Grid>


        <Grid item xs={12}>
          <Paper className={classes.paper} elevation={1}>
            <Grid container spacing={4} className={classes.container}>

              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  じゃらんログイン情報設定
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <ValidatorForm
                  onSubmit={() => changeJalanLoginInfo()}
                  onError={
                    errors => {
                      console.log(errors);
                      enqueueSnackbar('入力エラーがあります。確認してください。', {variant : 'warning'});
                    }
                  }
                  className={classes.validatorForm}
                >
                  <Grid container spacing={4} className={classes.container}>

                    <Grid item xs={6}>
                      <TextValidator
                        className={classes.textField}
                        name="nickname"
                        label="ユーザー名"
                        value={inputJalanId}
                        margin="normal"
                        onChange={(e) => setInputJalanId(e.target.value)}
                        required={true}
                        validators={['required']}
                        errorMessages={['必須項目を入力してください']}
                        type="text"
                      />
                    </Grid>
                    <Grid item xs={6}/>

                    <Grid item xs={12}/>

                    <Grid item xs={12} style={{height:18, margin: 0, padding: 0}}/>
                    <Grid item xs={6}>
                      <TextValidator
                        className={classes.textField}
                        name="newPassword"
                        label="新しいパスワード"
                        value={inputNewPassword}
                        margin="normal"
                        onChange={(e) => setInputNewPassword(e.target.value)}
                        required={true}
                        validators={['required']}
                        errorMessages={['必須項目を入力してください']}
                        type="password"
                      />
                    </Grid>
                    <Grid item xs={6}/>

                    <Grid item xs={6}>
                      <TextValidator
                        className={classes.textField}
                        name="retypeNewPassword"
                        label="新しいパスワード(確認)"
                        value={inputRetypeNewPassword}
                        margin="normal"
                        onChange={(e) => setInputRetypeNewPassword(e.target.value)}
                        required={true}
                        validators={['required', 'isPasswordMatch']}
                        type="password"
                        errorMessages={['必須項目を入力してください', '新しいパスワードが一致しません']}
                      />
                    </Grid>
                    <Grid item xs={6}/>

                    <Grid item xs={6}/>
                    <Grid item xs={2}>
                      <Button type="submit" variant="contained" color="primary" className={classes.button}>
                        変更
                      </Button>
                    </Grid>

                  </Grid>
                </ValidatorForm>
              </Grid>

            </Grid>
          </Paper>
        </Grid>


        <Grid item xs={12}>
          <Paper className={classes.paper} elevation={1}>
            <Grid container spacing={4} className={classes.container}>

              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  領収書/明細 デフォルト設定
                </Typography>
              </Grid>

              <Grid item xs={12}>
              <FormControl required={true}>
                <FormLabel>種別選択</FormLabel>
                <RadioGroup
                  aria-label="position"
                  name="position"
                  defaultValue="direct"
                  value={inputFormType}
                  onChange={(e,v) => setInputFormType(v)}
                >
                  <FormControlLabel
                    value="receipt"
                    control={<Radio color="primary" size='small' />}
                    label="領収書 兼 ご利用明細書"
                  />
                  <FormControlLabel
                    value="detailedStatement"
                    control={<Radio color="primary" size='small' />}
                    label="ご利用明細書"
                  />
                </RadioGroup>
              </FormControl>
              </Grid>

              <Grid item xs={6}/>
              <Grid item xs={2}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => changeDefaultFormType()}
                >
                  変更
                </Button>
              </Grid>

            </Grid>
          </Paper>
        </Grid>


      </Grid>
    </Fragment>
  );
}
