import React, { Fragment } from 'react';
import moment from 'moment';

const GUEST_TYPE = {
  adult: "大人",
  child: "子供",
  toddler: "幼児",
}

const BED = {
  true: 'ベッド有',
  false: 'ベッド無'
}

const DINNER = {
  true: '夕食有',
  false: '夕食無'
}

const BREAKFAST = {
  true: '朝食有',
  false: '朝食無'
}

export default class ReservationDataSheet extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      targetReservation: null
    };
  }

  componentDidUpdate(prevProps){

    if(this.props.targetReservation !== prevProps.targetReservation){
      this.setState({
        targetReservation: this.props.targetReservation
      },() => {
        // console.log(this.state.targetReservation)
      })
    }
  }


  nl2plusJsx = (str) => {
    let lines = [];
    let array = str.split("\n");
    for(let i in array){
      lines.push(<Fragment key={"lines" + i}>{array[i]}+++</Fragment>);
    }
    return lines;
  }


  omittedContent = (string) => {
    // 定数で宣言
    const MAX_LENGTH = 15

    // もしstringの文字数がMAX_LENGTH（今回は10）より大きかったら末尾に...を付け足して返す。
    if (string.length > MAX_LENGTH) {

      // substr(何文字目からスタートするか, 最大値);
      return string.substr(0, MAX_LENGTH) + '...';
    }
    //　文字数がオーバーしていなければそのまま返す
    return string;
  }



  getDateRange = (reservation) => {

    let dates = reservation.overnights.map(overnight => {
      return overnight.date
    })

    dates.sort((a, b) => moment(a).diff(moment(b)));

    return {
      start: moment(dates[0]).format('YYYY-MM-DD'),
      end: moment(dates[dates.length -1]).add(1, 'days').format('YYYY-MM-DD')
    }
  }


  getDateNum = (reservation) => {
    let dates = reservation.overnights.map(overnight => {
      return overnight.date
    })

    dates = Array.from(new Set(dates));

    return dates.length
  }


  getGuestCount = (reservation) => {

    let adultCount = 0
    let childCount = 0
    let toddlerCount = 0

    reservation.roomTypePlans.map(roomTypePlan => {
      roomTypePlan.storedPlans.map(plan => {

        if(plan.guestType === 'adult'){
          adultCount += plan.amount
        }

        if(plan.guestType === 'child'){
          childCount += plan.amount
        }

        if(plan.guestType === 'toddler'){
          toddlerCount += plan.amount
        }

        return null
      })
      return null
    })

    return {
      adult: adultCount,
      child: childCount,
      toddler: toddlerCount,
      total: adultCount + childCount + toddlerCount
    }
  }



  getIsChargesExist = (reservation) => {

    if(
      reservation.charges &&
      reservation.charges.additionalCharges &&
      reservation.charges.additionalCharges.additionalCharge &&
      reservation.charges.additionalCharges.additionalCharge.price !== 0
    ){
      return true
    }

    if(
      reservation.charges &&
      reservation.charges.discounts &&
      reservation.charges.discounts.discount &&
      reservation.charges.discounts.discount.price !== 0
    ){
      return true
    }

    if(
      reservation.charges &&
      reservation.charges.discounts &&
      reservation.charges.discounts.jalanPoint &&
      reservation.charges.discounts.jalanPoint.price !== 0
    ){
      return true
    }

    if(
      reservation.charges &&
      reservation.charges.discounts &&
      reservation.charges.discounts.jalanCoupon &&
      reservation.charges.discounts.jalanCoupon.price !== 0
    ){
      return true
    }

    if(
      reservation.charges &&
      reservation.charges.discounts &&
      reservation.charges.discounts.jalanSubsidy &&
      reservation.charges.discounts.jalanSubsidy.price !== 0
    ){
      return true
    }

    return false
  }


  getReservationTotalWithoutCharge = (reservation) => {
    let plansTotal = 0

    this.state.targetReservation.roomTypePlans.map(roomTypePlan => {
      roomTypePlan.storedPlans.map(storedPlan => {
        plansTotal += storedPlan.amount * storedPlan.unitPrice
        return null
      })
      plansTotal = plansTotal * Number(roomTypePlan.dateNum)
      return null
    })

    return plansTotal
  }


  getReservationTotal = (reservation) => {
    let plansTotal = 0

    if(this.state.targetReservation.methodOfPaymentPlans === 'jalan'){
      return plansTotal
    }

    this.state.targetReservation.roomTypePlans.map(roomTypePlan => {
      roomTypePlan.storedPlans.map(storedPlan => {
        plansTotal += storedPlan.amount * storedPlan.unitPrice
        return null
      })
      plansTotal = plansTotal * Number(roomTypePlan.dateNum)
      return null
    })


    if(
      this.state.targetReservation.charges &&
      reservation.charges.additionalCharges &&
      reservation.charges.additionalCharges.additionalCharge &&
      reservation.charges.additionalCharges.additionalCharge.price !== 0
    ){
      plansTotal += reservation.charges.additionalCharges.additionalCharge.price
    }

    if(
      reservation.charges &&
      reservation.charges.discounts &&
      reservation.charges.discounts.discount &&
      reservation.charges.discounts.discount.price !== 0
    ){
      plansTotal -= reservation.charges.discounts.discount.price
    }

    if(
      reservation.charges &&
      reservation.charges.discounts &&
      reservation.charges.discounts.jalanPoint &&
      reservation.charges.discounts.jalanPoint.price !== 0
    ){
      plansTotal -= reservation.charges.discounts.jalanPoint.price
    }

    if(
      reservation.charges &&
      reservation.charges.discounts &&
      reservation.charges.discounts.jalanCoupon &&
      reservation.charges.discounts.jalanCoupon.price !== 0
    ){
      plansTotal -= reservation.charges.discounts.jalanPoint.price
    }

    if(
      reservation.charges &&
      reservation.charges.discounts &&
      reservation.charges.discounts.jalanSubsidy &&
      reservation.charges.discounts.jalanSubsidy.price !== 0
    ){
      plansTotal -= reservation.charges.discounts.jalanSubsidy.price
    }

    return plansTotal
  }


  getDiscountTotal = (reservation) => {
    let discountTotal = 0

    if(
      reservation.charges &&
      reservation.charges.discounts &&
      reservation.charges.discounts.discount &&
      reservation.charges.discounts.discount.price !== 0
    ){
      discountTotal += reservation.charges.discounts.discount.price
    }

    if(
      reservation.charges &&
      reservation.charges.discounts &&
      reservation.charges.discounts.jalanPoint &&
      reservation.charges.discounts.jalanPoint.price !== 0
    ){
      discountTotal += reservation.charges.discounts.jalanPoint.price
    }

    if(
      reservation.charges &&
      reservation.charges.discounts &&
      reservation.charges.discounts.jalanCoupon &&
      reservation.charges.discounts.jalanCoupon.price !== 0
    ){
      discountTotal += reservation.charges.discounts.jalanPoint.price
    }

    if(
      reservation.charges &&
      reservation.charges.discounts &&
      reservation.charges.discounts.jalanSubsidy &&
      reservation.charges.discounts.jalanSubsidy.price !== 0
    ){
      discountTotal += reservation.charges.discounts.jalanSubsidy.price
    }

    return discountTotal
  }


  getReservationTotalJalanPaid = (reservation) => {

    let reservationAfter = JSON.parse(JSON.stringify(reservation))

    reservationAfter.methodOfPaymentPlans = ''

    let plansTotalJalanPaid = this.getReservationTotal(reservationAfter)

    return plansTotalJalanPaid
  }


  getReservationTotalInnerTax = (reservation) => {
    if(reservation.methodOfPaymentPlans === 'jalan'){
      return 0
    }

    let taxRate = reservation.charges.taxRate

    return Math.floor(this.getReservationTotal(reservation) * taxRate / (1 + taxRate))
  }


  getPlansTotal = (plans) => {
    let total = 0

    plans.map(plan => {
    total += plan.unitPrice * plan.amount
    return null
    })

    return total
  }



  render() {
    if(this.state.targetReservation){
      return (
        <div className="letter">
        <link rel="stylesheet" href="../css/Letter.css" />
          <div className="letterBody" key={this.state.targetReservation.reservationId}>
            <div className="clearfix" key={this.state.targetReservation.reservationId + "2"}>

              <div id="project">
                {this.state.targetReservation.postNum && <Fragment>〒{this.state.targetReservation.postNum.substr(0,3)}-{this.state.targetReservation.postNum.substr(3,4)}<br/></Fragment>}
                {this.state.targetReservation.addressPrefectures ? this.state.targetReservation.addressPrefectures : ""} {this.state.targetReservation.addressCity ? this.state.targetReservation.addressCity : ""}<br/>
                {this.state.targetReservation.addressOther ? this.state.targetReservation.addressOther : ""}<br/><br/>
                <p id="name">
                {this.state.targetReservation.lastName} {this.state.targetReservation.firstName} 様
                </p>
              </div>

              <div id="company">
                <div id="logo">
                  <img src="../images/funwarikon.png" alt="logo" />
                </div>
                <div id="logoAddress">〒410-1231 静岡県 裾野市 須山 2255-3810</div>
              </div>

              <h1 style={{ background: 'url("/images/leaves.png")'}}>ご案内</h1>
              <div id="main">
                　{this.state.targetReservation.lastName}様、この度はペンションふんわりこんにご予約いただきまして、誠にありがとうございます。
                下記の通りご予約を承りましたので、ご確認ください。
                ふんわりこんは、家族で経営している小さなペンションです。
                真心込めておもてなしさせていただきますので、よろしくお願いいたします。
                お会いできる日をスタッフ一同こころよりお待ち申し上げます。
              </div>

              <div id="dateAndRoom">
                <div>
                    <span className="col-1">ご宿泊日程　 :</span>
                    <span className="col-2">
                      {moment(this.getDateRange(this.state.targetReservation).start).format("YYYY年M月D日（ddd）")} から {this.getDateNum(this.state.targetReservation)}泊
                    </span>
                </div>
              </div>

              <main>
                 <table>
                  <thead>
                    <tr>
                      <th className="desc">ご予約内容</th>
                      <th>単価</th>
                      <th>数量</th>
                      <th>小計</th>
                    </tr>
                  </thead>

                  <tbody key={this.state.targetReservation.reservationId}>

                    {this.state.targetReservation.roomTypePlans.map(roomTypePlan => {
                        return (
                          <Fragment key={roomTypePlan.storedRoomTypePlanId}>
                            {roomTypePlan.storedPlans.map(storedPlan => {
                                return (
                                  <tr key={storedPlan.storedPlanId}>
                                    <td className="desc" style={{whiteSpace: 'normal', wordWrap: 'break-word'}}>
                                      {!storedPlan.planNameForGuest && <div>{roomTypePlan.roomTypeName} { GUEST_TYPE[storedPlan.guestType]} {BED[storedPlan.bed ? 'true' : 'false']} {DINNER[storedPlan.dinner ? 'true' : 'false']} {BREAKFAST[storedPlan.breakfast ? 'true' : 'false']} ご宿泊料</div>}
                                      {storedPlan.planNameForGuest && storedPlan.planNameForGuest + ' ご宿泊料'}
                                    </td>
                                    <td className="unit">¥ {storedPlan.unitPrice.toLocaleString()}</td>
                                    <td className="qty">{storedPlan.amount}</td>
                                    <td className="total">{(storedPlan.unitPrice * storedPlan.amount).toLocaleString()}</td>
                                  </tr>
                                )
                            })}
                          </Fragment>
                        )
                    })}



                    {
                      this.getIsChargesExist(this.state.targetReservation) &&
                      (
                        <tr>
                          <td align="center" colSpan="5"></td>
                        </tr>
                      )
                    }


                    {
                      this.state.targetReservation.charges &&
                      this.state.targetReservation.charges.additionalCharges &&
                      this.state.targetReservation.charges.additionalCharges.additionalCharge &&
                      this.state.targetReservation.charges.additionalCharges.additionalCharge.price !== 0 &&
                      (
                        <tr>
                          <td className="desc" style={{whiteSpace: 'normal', wordWrap: 'break-word'}}>{this.state.targetReservation.charges.additionalCharges.additionalCharge.name}</td>
                          <td className="unit"></td>
                          <td className="qty"></td>
                          <td className="total">¥ {this.state.targetReservation.charges.additionalCharges.additionalCharge.price.toLocaleString()}</td>
                        </tr>
                      )
                    }


                    {
                      this.state.targetReservation.charges &&
                      this.state.targetReservation.charges.discounts &&
                      this.state.targetReservation.charges.discounts.discount &&
                      this.state.targetReservation.charges.discounts.discount.price !== 0 &&
                      (
                        <tr>
                          <td className="desc" style={{whiteSpace: 'normal', wordWrap: 'break-word'}}>{this.state.targetReservation.charges.discounts.discount.name}</td>
                          <td className="unit"></td>
                          <td className="qty"></td>
                          <td className="total">- ¥ {this.state.targetReservation.charges.discounts.discount.price.toLocaleString()}</td>
                        </tr>
                      )
                    }


                    {
                      this.state.targetReservation.charges &&
                      this.state.targetReservation.charges.discounts &&
                      this.state.targetReservation.charges.discounts.jalanPoint &&
                      this.state.targetReservation.charges.discounts.jalanPoint.price !== 0 &&
                      (
                        <tr>
                          <td className="desc" style={{whiteSpace: 'normal', wordWrap: 'break-word'}}>{this.state.targetReservation.charges.discounts.jalanPoint.name}</td>
                          <td className="unit"></td>
                          <td className="qty"></td>
                          <td className="total">- ¥ {this.state.targetReservation.charges.discounts.jalanPoint.price.toLocaleString()}</td>
                        </tr>
                      )
                    }

                    {
                      this.state.targetReservation.charges &&
                      this.state.targetReservation.charges.discounts &&
                      this.state.targetReservation.charges.discounts.jalanCoupon &&
                      this.state.targetReservation.charges.discounts.jalanCoupon.price !== 0 &&
                      (
                        <tr>
                          <td className="desc" style={{whiteSpace: 'normal', wordWrap: 'break-word'}}>{this.state.targetReservation.charges.discounts.jalanCoupon.name}</td>
                          <td className="unit"></td>
                          <td className="qty"></td>
                          <td className="total">- ¥ {this.state.targetReservation.charges.discounts.jalanCoupon.price.toLocaleString()}</td>
                        </tr>
                      )
                    }

                    {
                      this.state.targetReservation.charges &&
                      this.state.targetReservation.charges.discounts &&
                      this.state.targetReservation.charges.discounts.jalanSubsidy &&
                      this.state.targetReservation.charges.discounts.jalanSubsidy.price !== 0 &&
                      (
                        <tr>
                          <td className="desc" style={{whiteSpace: 'normal', wordWrap: 'break-word'}}>{this.state.targetReservation.charges.discounts.jalanSubsidy.name}</td>
                          <td className="unit"></td>
                          <td className="qty"></td>
                          <td className="total">- ¥ {this.state.targetReservation.charges.discounts.jalanSubsidy.price.toLocaleString()}</td>
                        </tr>
                      )
                    }


                    {
                      this.state.targetReservation.methodOfPaymentPlans === 'jalan' &&
                      (
                        <Fragment>
                          <tr>
                            <td align="center" colSpan="5"></td>
                          </tr>

                          <tr>
                            <td className="desc" style={{whiteSpace: 'normal', wordWrap: 'break-word'}}>じゃらんお支払済み金額</td>
                            <td className="unit"></td>
                            <td className="qty"></td>
                            <td className="total">- ¥ {this.getReservationTotalJalanPaid(this.state.targetReservation).toLocaleString()}</td>
                          </tr>
                        </Fragment>
                      )
                    }


                    <tr>
                      <td align="center" colSpan="4"></td>
                    </tr>


                    <tr>
                      <td className="desc"></td>
                      <td className="unit"></td>
                      <td className="qty" style={{whiteSpace: 'normal', wordWrap: 'break-word'}}>宿泊料金合計</td>
                      <td className="total">¥ {this.getReservationTotal(this.state.targetReservation).toLocaleString()}</td>
                    </tr>
                    <tr>
                      <td className="desc"></td>
                      <td className="unit"></td>
                      <td className="qty" style={{whiteSpace: 'normal', wordWrap: 'break-word'}}>( 内消費税等</td>
                      <td className="total">¥ {this.getReservationTotalInnerTax(this.state.targetReservation).toLocaleString()} )</td>
                    </tr>

                  </tbody>
                </table>

                <div id="after">
                  背面に記載の宿泊についてのご案内もご確認いただきますようお願い申し上げます。<br/>
                  その他ご不明点等ございましたら、お気軽にお問い合わせくださいませ。<br/>
                </div>

                <div id="caution">

                  <h2>チェックイン</h2>
                  夕食付きのプランをご予約の方: 15:00 〜 17:30<br/>
                  夕食無しのプランをご予約の方: 15:00 〜 22:00<br/><br/>
                  ※チェックインに間に合わない場合は、必ずご連絡をお願いいたします。

                  <h2>チェックアウト</h2>
                  10:00までにチェックアウトをお願いいたします。

                  <h2>お食事の時間について</h2>
                  ご夕食: 18:00にご用意いたします。<br/>
                  ご朝食:  8:00にご用意いたします。<br/><br/>
                  ※8:30までにチェックアウトされる方には、7:30にご朝食のご用意が可能です。ご希望の場合は、事前にご連絡ください。

                  <h2>アレルギー対応等について</h2>
                  少人数のスタッフで運営している小さなペンションということもあり、万一のことを考えると安易に対応をすることは危険と考えておりますため、アレルギー除去食のご要望についてはお受けできません。申し訳ございませんが、ご理解の程よろしくお願いいたします。

                  <h2>お風呂について</h2>
                  １部屋のご予約ごとに、40分間ご利用いただける貸し切りの岩風呂のご用意がございます。岩風呂のご予約については、チェックインの際に、先着順でお受けしております。先にチェックインされたお客様のご予約状況により、ご希望のお時間にご予約いただけない場合がありますが、ご了承くださいますようお願いいたします。<br/><br/>
                  ※シャワールーム、バスルームが備え付けられているお部屋にご宿泊のお客様も岩風呂をご利用いただけます。

                  <h2>キャンセルについて</h2>
                  お客様のご都合によるキャンセルについては、下記の通り、規定のキャンセル料金が発生いたします。<br/><br/>
                  チェックイン当日のキャンセル : ご宿泊料金の100%<br/>
                  前日および前々日のキャンセル : ご宿泊料金の 60%<br/>
                  3日前から8日前のキャンセル : ご宿泊料金の 30%<br/>
                  9日以前のキャンセル : 無料

                  <h2>飲食物等のお持ち込みについて</h2>
                  飲食物のお持込は、離乳食、アレルギー除去食を除いてお断りいたします。特にお持込をご希望されるお客様は、事前にご相談ください。また、盲導犬・聴導犬・介助犬を除く一般のペットの館内への同伴や、危険物のお持込は固くお断りいたします。
                </div>

              </main>

              <div className="footer">
                <div id="footerLogo">
                  <img src="../images/funwarikon.png" alt="footerLogo" />
                  <div>〒410-1231 静岡県 裾野市 須山 2255-3810</div>
                  <div>☎ 055-998-1433</div>
                  <div>https://funwarikon.com</div>
                  <img src="../images/qr.png" alt="qr" id="qr"/>
                </div>

              </div>

            </div>
          </div>
        </div>
      );
    }else{
      return (
        <div>
          印刷する予約が選択されていません
        </div>
      )
    }
  }

}
