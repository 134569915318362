import React, { useState, useEffect, Fragment } from 'react';
import { useHistory } from "react-router";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import ErrorIcon from '@material-ui/icons/Error';
import DialogContentText from '@material-ui/core/DialogContentText';
import CircularProgress from '@material-ui/core/CircularProgress';

import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import { useSnackbar } from 'notistack';
import { API } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

import { style, taxRate } from '../services/Settings'

const useStyles = style

export default function Reservation(props) {

  const classes = useStyles();
  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();

  const [roomPlanMasters, setRoomPlanMasters] = useState([]);

  // set関数を使わないものは定数にしてしまうことにする。receiveReservationを変えたくないので。
  const inputReservationType = 'direct';
  const inputJalanReservationNumber = '';
  const inputIsJalanPayed = false;
  const [inputRoomType, setInputRoomType] = useState(null);
  const [inputStartDate, setInputStartDate] = useState(moment().format('YYYY-MM-DD'));
  const [inputDateNum, setInputDateNum] = useState('1');
  const inputDiscount = '0';
  const inputJalanPoint = '0';
  const inputJalanCoupon = '0';
  const inputJalanSubsidy = '0';
  const inputAdditionalCharge = '0';
  const inputReservationNote = '';

  const inputLastNameKana = '';
  const inputFirstNameKana = '';
  const inputLastName = '';
  const inputFirstName = '';
  const inputPostNum = '';
  const inputAddressPrefectures = '';
  const inputAddressCity = '';
  const inputAddressOther = '';
  const inputTel1 = '';
  const inputTel2 = '';
  const inputEmail = '';
  const inputCustomerNote = '';

  const [inputIsNotUseSiteController, setInputIsNotUseSiteController] = useState(false);

  const [storedRoomTypePlans, setStoredRoomTypePlans] = useState([]);
  const [storedRepeatCustomerId, setStoredRepeatCustomerId] = useState('');

  const [jalanId, setJalanId] = useState('');
  const [isWaitingSiteController, setIsWaitingSiteController] = useState(false);


  const DISCOUNTS = {
    discount: "割引",
  }

  const ADDTIONAL_CHARGES = {
    additionalCharge: "追加料金"
  }


  // テナント情報の取得
  useEffect(() => {

    // ページのトップにフォーカス
    window.scrollTo(0, 0)

    props.handleChangePath()

    // 部屋タイプとjalanIdの取得
    let apiName = 'MyAPIGatewayAPI'; // replace this with your api name.
    let path = '/tenant'; //replace this with the path you have configured on your API

    API.get(apiName, path).then(response => {
      console.log(response);
      setRoomPlanMasters(response.tenant.roomPlanMasters)
      setJalanId(response.tenant.jalanId)
    }).catch(error => {
      console.log(error);
      enqueueSnackbar('エラーが発生しました。', {variant : 'error'})
    })

  },[props, enqueueSnackbar])



  // jalanIdが無い場合は、inputIsNotUseSiteControllerを常にTrueにする
  useEffect(() => {

    let unmounted = false;

    //アンマウントされていなければステートを更新
    if(!unmounted) {

      if(jalanId !== '' && !jalanId){
        setInputIsNotUseSiteController(true)
      }

    };

    //クリーンアップ関数を返す
    return ()=>{ unmounted = true; };

  },[jalanId])


  const addStoredRoomTypePlans = () => {

    let dates = []

    for(let i = 0; i < inputDateNum; i++){
      dates.push(moment(inputStartDate).add(i,'days'))
    }

    let isSelectInvalidRoomType = false

    dates.map(date => {
      if(!(date.isSameOrAfter(moment(inputRoomType.startDate)) && (inputRoomType.endDate === null || date.isSameOrBefore(moment(inputRoomType.endDate))))){
        isSelectInvalidRoomType = true
      }
      return null
    })

    if(isSelectInvalidRoomType){
      enqueueSnackbar('選択された日付でこのお部屋タイプは利用できません', {variant : 'warning'})
      return
    }

    let storedRoomTypePlansAfter = storedRoomTypePlans.concat()

    // storedRoomTypePlansの初期値を設定
    storedRoomTypePlansAfter.push({
      storedRoomTypePlanId : 'srtp-' + uuidv4(),
      roomTypeId : inputRoomType.roomTypeId,
      roomTypeName : inputRoomType.roomTypeName,
      jalanRoomTypeId : inputRoomType.jalanRoomTypeId,
      startDate : inputStartDate,
      dateNum : inputDateNum,
      inputGuestType: 'adult',
      inputBed: 'true',
      inputDinner: 'true',
      inputBreakfast: 'true',
      inputUnitPrice: '0',
      inputAmount: '2',
      storedPlans: []
    })

    setStoredRoomTypePlans(storedRoomTypePlansAfter)
    setInputRoomType(null)
  }


  const deleteStoredRoomType = (storedRoomTypePlanId) => {
    let storedRoomTypePlansAfter1 = storedRoomTypePlans.concat()

    const storedRoomTypePlansAfter2 = storedRoomTypePlansAfter1.filter((roomType) => {
      return roomType.storedRoomTypePlanId !== storedRoomTypePlanId
    })

    setStoredRoomTypePlans(storedRoomTypePlansAfter2)
  }


  const receiveReservation = () => {

    // プランの最低限の情報の入力があることのチェック
    if(storedRoomTypePlans.length === 0){
      enqueueSnackbar('プランを設定してください', {variant : 'warning'})
      return
    }

    let reservationType = 'direct'
    if(inputReservationType === 'jalan'){
      reservationType = 'jalan-' + inputJalanReservationNumber
    }

    let discountsForBody = {}

    if(inputReservationType === 'direct'){
      discountsForBody.discount = {
        name: DISCOUNTS.discount,
        price: Number(inputDiscount),
      }
    }

    if(inputReservationType === 'jalan'){
      discountsForBody.jalanPoint = {
        name: DISCOUNTS.jalanPoint,
        price: Number(inputJalanPoint),
      }

      discountsForBody.jalanCoupon = {
        name: DISCOUNTS.jalanCoupon,
        price: Number(inputJalanCoupon),
      }

      discountsForBody.jalanSubsidy = {
        name: DISCOUNTS.jalanSubsidy,
        price: Number(inputJalanSubsidy),
      }
    }

    let additionalChargesForBody = {}

    if(inputReservationType === 'direct'){
      additionalChargesForBody.additionalCharge = {
        name: ADDTIONAL_CHARGES.additionalCharge,
        price: Number(inputAdditionalCharge),
      }
    }

    let taxRateForBody = taxRate

    let charges = {
      discounts: discountsForBody,
      additionalCharges: additionalChargesForBody,
      taxRate: taxRateForBody
    }

    let methodOfPaymentPlans = ''

    if(inputIsJalanPayed){
      methodOfPaymentPlans = 'jalan'
    }


    let overnights = []

    storedRoomTypePlans.map(roomTypePlan => {

      let plansForOvernight = []

      roomTypePlan.storedPlans.map(plan => {

        plansForOvernight.push(
          {
            guestType : plan.guestType,
            bed : plan.bed,
            dinner : plan.dinner,
            breakfast: plan.breakfast,
            price : Number(plan.unitPrice),
            amount: Number(plan.amount),
            taxRate: Number(taxRate),
            planId: plan.planId,
            planNameForAdmin: plan.planNameForAdmin,
            planNameForGuest: plan.planNameForGuest,
          }
        )
        return null
      })

      for(let i = 0 ; i < roomTypePlan.dateNum ; i++){

        overnights.push(
          {
            roomTypeId: roomTypePlan.roomTypeId,
            roomTypeName: roomTypePlan.roomTypeName,
            jalanRoomTypeId : roomTypePlan.jalanRoomTypeId,
            date: moment(roomTypePlan.startDate).add(i, 'days').format('YYYY-MM-DD'),
            plans: plansForOvernight
          }
        )
      }
      return null
    })

    let roomTypePlans = storedRoomTypePlans.map( roomTypePlan => {

      let storedPlans = roomTypePlan.storedPlans.map(plan => {
        return {
          storedPlanId: plan.storedPlanId,
          amount: Number(plan.amount),
          bed: plan.bed,
          breakfast: plan.breakfast,
          dinner: plan.dinner,
          guestType: plan.guestType,
          unitPrice:Number(plan.unitPrice),
          taxRate: Number(taxRate),
          planId: plan.planId,
          planNameForAdmin: plan.planNameForAdmin,
          planNameForGuest: plan.planNameForGuest,
        }
      })

      return {
        storedRoomTypePlanId: roomTypePlan.storedRoomTypePlanId,
        dateNum: roomTypePlan.dateNum,
        roomTypeId: roomTypePlan.roomTypeId,
        roomTypeName: roomTypePlan.roomTypeName,
        jalanRoomTypeId: roomTypePlan.jalanRoomTypeId,
        startDate: roomTypePlan.startDate,
        storedPlans: storedPlans
      }
    })

    // 7ヶ月以上にまたがる場合の確認
    // 予約に含まれる日付を含む月が6ヶ月以上となる宿泊は受付不可とする(トランザクションの上限を見据えて余裕を持って)
    let dates = []

    overnights.map(overnight => {
      dates.push(overnight.date)
      return null
    })

    dates = Array.from(new Set(dates))

    console.log(dates)

    let yearMonths = dates.map(date => {
      let yearMonth = date.substr(0, 7)
      return yearMonth
    })

    yearMonths = Array.from(new Set(yearMonths))

    if(yearMonths.length > 6){
      enqueueSnackbar('予約に含まれる日付を含む月が6ヶ月以内となるようにしてください', {variant : 'warning'})
      return
    }

    let reservationNote = inputReservationNote
    let lastName = inputLastName
    let firstName = inputFirstName
    let lastNameKana = inputLastNameKana
    let firstNameKana = inputFirstNameKana
    let tel1 = inputTel1
    let tel2 = inputTel2
    let email = inputEmail
    let postNum = inputPostNum
    let addressPrefectures = inputAddressPrefectures
    let addressCity = inputAddressCity
    let addressOther = inputAddressOther
    let customerNote = inputCustomerNote
    let repeatCustomerId = storedRepeatCustomerId


    let body = {
      reservationType: reservationType,
      charges: charges,
      methodOfPaymentPlans: methodOfPaymentPlans,
      overnights: overnights,
      roomTypePlans: roomTypePlans,
      reservationNote: reservationNote,
      lastName: lastName,
      firstName: firstName,
      lastNameKana: lastNameKana,
      firstNameKana: firstNameKana,
      postNum: postNum,
      addressPrefectures: addressPrefectures,
      addressCity: addressCity,
      addressOther: addressOther,
      tel1: tel1,
      tel2: tel2,
      email: email,
      customerNote: customerNote,
      repeatCustomerId: repeatCustomerId,
    }

    console.log('body: ', body)

    let createReservation = () => {
      let apiName = 'MyAPIGatewayAPI'; // replace this with your api name.
      let path = '/reservation-receiver'; //replace this with the path you have configured on your API
      let myInit = {
        body: body
      }

      API.post(apiName, path, myInit).then(response => {
        console.log(response)
        enqueueSnackbar(response.message, {variant : 'success'});
        setStoredRepeatCustomerId('')
        history.push('/main/reservation-manage?reservationId=' + response.reservationId);
      }).catch(error => {
        console.log(error)
        if(!(error.response === undefined)){
          if(!(error.response.status === undefined)){
            if(error.response.status === 409){
              enqueueSnackbar(error.response.data.message, {variant : 'error'})
              return
            }
          }
        }

        if(error.message){
          enqueueSnackbar(error.message, {variant : 'error'})
          return
        }

        enqueueSnackbar('エラーが発生しました。', {variant : 'error'})
      });
    }

    // サイトコントローラーの動作を考慮
    if(inputReservationType === "direct" && !inputIsNotUseSiteController){
      setIsWaitingSiteController(true)

      let apiName = 'MyAPIGatewayAPI'
      let path = '/jalan/decrease'

      let body = {
        overnights : overnights
      }

      let myInit = {
        body: body
      }

      API.post(apiName, path, myInit).then(async (response) => {

        let executionArn = response.executionArn

        console.log(response)

        let path = '/jalan/decrease/status'

        let body = {
          executionArn : executionArn
        }

        let myInit = {
          body: body
        }

        //結果をポーリング

        function sleep(waitSec) {
          return new Promise(function (resolve) {
            setTimeout(function() { resolve() }, waitSec);
          });
        }

        let res

        let count = 0
        while (count < 40) {

          try{
            res = await API.post(apiName, path, myInit)
            console.log(res)

            if(res.status === "RUNNING"){
              count++
              await sleep(3000)
            }else{
              break
            }

          }catch(error){

            console.log(error)
            if(error.message){
              enqueueSnackbar(error.message, {variant : 'error'})
              setIsWaitingSiteController(false)
            }else{
              enqueueSnackbar('エラーが発生しました。', {variant : 'error'})
              setIsWaitingSiteController(false)
            }
            break
          }
        }

        if(res.status === "SUCCEEDED"){

          if(res.output.message !== "success"){
            enqueueSnackbar(res.output.message, {variant : 'error', autoHideDuration : 20000})
            setIsWaitingSiteController(false)
          }else{
            if(res.output.beforeSale.length > 0){
              res.output.beforeSale.map(item => {
                let message = moment(item.date).format('YYYY年M月D日') + ' の ' + item.roomTypeName + ' はじゃらんで販売開始前のため、じゃらんの在庫は変更されませんでした。'
                enqueueSnackbar(message, {variant : 'warning', autoHideDuration : 20000})
                return null
              })
            }

            if(res.output.stopSale.length > 0){
              res.output.stopSale.map(item => {
                let message = moment(item.date).format('YYYY年M月D日') + ' の ' + item.roomTypeName + ' はじゃらんで販売停止中のため、じゃらんの在庫は変更されませんでした。'
                enqueueSnackbar(message, {variant : 'warning', autoHideDuration : 20000})
                return null
              })

            }

            createReservation()
            setIsWaitingSiteController(false)
          }

        }else{
          enqueueSnackbar('処理中にエラーが発生しました。念の為、じゃらんの在庫状況を確認してください。', {variant : 'error'})
          setIsWaitingSiteController(false)
        }

      }).catch(error => {

        setIsWaitingSiteController(false)

        console.log(error)
        if(error.message){
          enqueueSnackbar(error.message, {variant : 'error'})
        }else{
          enqueueSnackbar('エラーが発生しました。', {variant : 'error'})
        }

      });

    }else{
      createReservation()
    }
  };


  return (
    <Fragment>

      <Typography variant="h6" gutterBottom style={{display: inputIsNotUseSiteController ? '' : 'none'}}>
        じゃらんログイン情報が設定されていないため、簡易予約をご利用いただけません
      </Typography>

      <Grid container spacing={2} className={classes.container} style={{display: inputIsNotUseSiteController ? 'none' : ''}}>

        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom>
            簡易予約受付
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Paper className={classes.paper} elevation={1} style={{maxWidth: 300}}>
            <Grid container spacing={2} className={classes.container}>

              <Grid item xs={12}>
                <Typography variant="h5" gutterBottom>
                  宿泊情報
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <ValidatorForm
                  onSubmit={() => addStoredRoomTypePlans()}
                  onError={
                    errors => {
                      console.log(errors);
                      enqueueSnackbar('入力エラーがあります。確認してください。', {variant : 'warning'});
                    }
                  }
                  className={classes.validatorForm}
                >
                  <Grid container spacing={2} className={classes.container}>

                    <Grid item xs={12}>
                      <Autocomplete
                        id="combo-box"
                        options={roomPlanMasters}
                        getOptionLabel={(option) => option.roomTypeName}
                        value={inputRoomType}
                        onChange={(event, newValue) => {
                          setInputRoomType(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} required={true} label="お部屋タイプ"/>}
                        className={classes.autocomplete}
                        getOptionSelected={(option, value) => option.roomTypeId === value.roomTypeId}
                      />
                    </Grid>


                    <Grid item xs={12}>
                      <TextValidator
                        className={classes.textField}
                        name="date"
                        label="宿泊開始日"
                        value={inputStartDate}
                        margin="normal"
                        onChange={(e) => setInputStartDate(e.target.value)}
                        type="date"
                        required={true}
                        validators={['required', 'matchRegexp:^\\d{4}-\\d{1,2}-\\d{1,2}$']}
                        errorMessages={['必須項目を入力してください', '日付が間違っています']}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextValidator
                        className={classes.textField}
                        name="dateNum"
                        label="宿泊日数"
                        value={inputDateNum}
                        margin="normal"
                        onChange={(e) => setInputDateNum(e.target.value)}
                        type="number"
                        inputProps={{
                          step: 1,
                        }}
                        required={true}
                        validators={['required', 'isNumber', 'minNumber:1']}
                        errorMessages={['必須項目を入力してください', '整数を入力してください', '1以上の数を入力してください']}
                      />
                    </Grid>


                    <Grid item xs={8}>
                      <Button type="submit" variant="contained" color="default" className={classes.button}>
                        部屋追加
                      </Button>
                    </Grid>

                  </Grid>
                </ValidatorForm>
              </Grid>

              <Grid item xs={12} className={classes.warningMessage} style={{display: storedRoomTypePlans.length > 0 ? 'none' : ''}}>
               <Grid container direction="row" alignItems="center">
                 <Grid item>
                   <ErrorIcon className={classes.errorIcon}  />
                 </Grid>
                 <Grid item>
                   お部屋を入力してください
                 </Grid>
               </Grid>
              </Grid>


              <Grid item xs={12}>
              {storedRoomTypePlans.map(roomTypePlan => (
                <Paper className={classes.innerPaperPreReservation} elevation={2} key={roomTypePlan.storedRoomTypePlanId}>
                 <Grid container spacing={4} className={classes.container}>

                   <Grid item xs={10}>
                     <Typography variant="button" gutterBottom>
                      {roomTypePlan.roomTypeName}<br/>
                      {moment(roomTypePlan.startDate).format('YYYY年M月D日')}<br/>
                      から {roomTypePlan.dateNum}泊
                     </Typography>
                   </Grid>

                   <Grid item xs={2}>
                    <Grid container spacing={0} className={classes.deleteInPaper} justify='flex-end'>
                       <IconButton size='small' aria-label="Delete" onClick={() => deleteStoredRoomType(roomTypePlan.storedRoomTypePlanId)}>
                         <DeleteIcon/>
                       </IconButton>
                     </Grid>
                   </Grid>
                 </Grid>
               </Paper>
              ))}
              </Grid>

              <Grid item xs={8}>
                <Button type="submit" variant="contained" color="primary" className={classes.button} onClick={() => receiveReservation()}>
                  予約を作成
                </Button>
              </Grid>

            </Grid>
          </Paper>
        </Grid>

      </Grid>


      <Dialog
        open={isWaitingSiteController}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          じゃらん連携中
        </DialogTitle>
        <DialogContent style={{textAlign: "center",marginTop: 20}}>
          <CircularProgress color="inherit"/>
          <DialogContentText id="alert-dialog-description" style={{textAlign: "left", marginTop: 30}}>
            ページを閉じないでください<br/><br/>
            じゃらんの在庫の確認と変更を行っています<br/>
            この処理には数分かかる場合があります<br/><br/>
            しばらくおまちください...
          </DialogContentText>
        </DialogContent>
      </Dialog>


    </Fragment>
  );
}
